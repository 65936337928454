import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    secondaryButton: {
        backgroundColor: "black",
        borderColor: "black",
    },
    secondaryButtonText: {
        color: "white",
    },
    icon: {
        color: "white",
        fontWeight: "500",
        fontSize: 14,
    },
});