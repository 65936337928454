import React, { useEffect, useState } from 'react';
import { TouchableOpacity, TextInput, Text, View } from 'react-native';
import SearchIcon from '../icons/searchIcon';

import styles from '../../styles/textboxes/searchInput';
import Utils from '../../utilities/utilities';

const SearchInput = (props) => {

    if (!Utils.isValidObj(props))
        return <></>;

    const keyword = props.keyword;
    const setKeyword = props.setKeyword;
    const hints = props.hints;
    const onHintSelected = props.onHintSelected;
    const onSearchBtn = props.onSearchBtn;

    const [showHints, setShowHints] = useState(false);

    useEffect(() => {

        if (hints !== null && typeof hints !== "undefined" && Array.isArray(hints) && hints.length > 0)
            setShowHints(true);
        else
            setShowHints(false);
    }, [ hints ])


    const renderIcon = () => {
        return (
            <SearchIcon
                color={"white"}
                style={styles.searchFieldBtnIcon}
            />
        );
    };


    const onSelectedHint = (idx) => {

        if (hints !== null && typeof hints !== "undefined" && Array.isArray(hints) && idx < hints.length) {
            onHintSelected(hints[idx]);
        }
    };


    const renderHint = (item, idx) => {
        return (
            <TouchableOpacity
                key={idx}
                onPress={() => { onSelectedHint(idx); }}
            >
                <Text
                >
                    {item}
                </Text>
            </TouchableOpacity>
        );
    };
    


    // IMPORTANT:  Set the default in case the keyword is undefined.  This will remove the warning below:
    //      Warning: A component is changing a controlled input to be uncontrolled. This is likely caused by
    //      the value changing from a defined to undefined, which should not happen. Decide between using a controlled 
    //      or uncontrolled input element for the lifetime of the component.
    return (
        <View style={styles.searchComponent}>
            <View style={styles.searchInputContainer}>
                <TextInput
                    style={styles.searchInput}
                    placeholder='Search (min of 3 chars)...'
                    returnKeyType='search'
                    autoFocus={true}
                    //defaultValue={""}
                    value={keyword || ""}                                       // <== see IMPORTANT note above
                    onChangeText={setKeyword}
                    onSubmitEditing={onSearchBtn}
                >
                </TextInput>
                
                <TouchableOpacity
                    style={styles.searchFieldBtn}
                    onPress={onSearchBtn}
                >
                    {renderIcon()}
                </TouchableOpacity>
            </View>
            {showHints &&
                <View style={styles.searchHintContainer}>
                    {hints.map((item, index) => {
                            return renderHint(item, index);
                        })
                    }
                </View>
            }
        </View>
    );
}

export default SearchInput;