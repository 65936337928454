class BaseLocalStorageError extends Error {
    constructor(message) {
        super(message);
        this.name = 'BaseLocalStorageError';
    }
}

class InvalidCacheForAccessTokenError extends BaseLocalStorageError {
    constructor(message) {
        super(message);
        this.name = 'InvalidCacheForAccessTokenError';
    }
}

class InvalidCacheForUserCredsError extends BaseLocalStorageError {
    constructor(message) {
        super(message);
        this.name = 'InvalidCacheForUserCredsError';
    }
}

class SecureStorageNotAvailableOnDeviceError extends BaseLocalStorageError {
    constructor(message) {
        super(message);
        this.name = 'SecureStorageNotAvailableOnDeviceError';
    }
}

export { 
    InvalidCacheForAccessTokenError, 
    InvalidCacheForUserCredsError, 
    SecureStorageNotAvailableOnDeviceError,
};