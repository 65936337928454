import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    badge: {
        borderRadius: 5,
        borderWidth: 1,
        marginBottom: 10,
        padding: 5,
    },
    primary: {
        backgroundColor: "#007bff", 
        borderColor: "#0059b9",
        color: "white",
    },
    success: {
        backgroundColor: "#28a745", 
        borderColor: "#1e8035",
        color: "white",
    },
    warning: {
        backgroundColor: "#ffc107", 
        borderColor: "#b38600",
        color: "black",
    },
    error: {
        backgroundColor: "#dc3545", 
        borderColor: "#ab2834",
        color: "white",
    },
    info: {
        backgroundColor: "#17a2b8", 
        borderColor: "#148699",
        color: "white",
    },
});