import Logger from './logger';
import { AccessTokenIsNullOrEmptyError, AccessTokenHasExpiredError } from '../exceptions/authenticationExceptions';
//import { Buffer } from 'buffer';   // DO NOT USE - This is for node.js on the server side!

const Utilities = {
    isValidObj: function(obj) {
        return (obj !== null && typeof obj !== "undefined");
    },
    sleepBlocking: function(milliseconds) {
        Logger.log("  (b) sleep blocking -->");

        const date = Date.now();
        let currentDate = null;
        do {
          currentDate = Date.now();
        } while (currentDate - date < milliseconds);

        Logger.log("  (b) sleep blocking <--");
    },
    sleepNonBlocking: function(milliseconds) {
        Logger.log("  (b) sleep non-blocking -->");

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                Logger.startFunc("  (b) sleep non-blocking");

                resolve(true);

                Logger.endFunc("  (b) sleep non-blocking");
            }, milliseconds);
        });
    },
    getUniqueKey: function(prefix) {
        return Utilities.isValidObj(prefix)
            ? `${prefix}${crypto.randomUUID()}`
            : crypto.randomUUID();
    },
    findItemById: function(id, array) {
        var foundItem = null;

        if (array !== null && typeof array !== "undefined" && array.length > 0) {
            for (var idx = 0; idx < array.length; idx++ && foundItem === null) {
                if (array[idx].Id === parseInt(id))
                    foundItem = array[idx];
            }
        }

        return foundItem;
    },
    findItemByValue: function(value, array) {
        var foundItem = null;

        if (array !== null && typeof array !== "undefined" && array.length > 0) {
            for (var idx = 0; idx < array.length; idx++ && foundItem === null) {
                if (array[idx] === value)
                    foundItem = array[idx];
            }
        }

        return foundItem;
    },
    formatDate: function(date) {
        var dt = new Date(date);
        return dt.getDate()  + "/" + (dt.getMonth() + 1) + "/" + dt.getFullYear()
            //+ " " + dt.getHours() + ":" + dt.getMinutes()
            ;
    },
    formatNumber: function(number) {
        if (!this.isValidObj(number))
            return "NaN";

        return number.toLocaleString();
    },
    readFileAsync: function(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
      
            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsDataURL(file);
        });
    },
    convertDataUriToBase64Blob: function(dataURI) {

        // separate out the file data
        var fileData = dataURI.split(',')[1];
    
        // separate out the mime type
        // e.g., "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAA...."
        var mimeString = dataURI
            .split(',')[0]      // data:image/jpeg;base64
            .split(':')[1]      // image/jpeg;base64
            .split(';')[0];     // image/jpeg

        return new Blob([fileData], {type:mimeString});
    },
    convertDataUriToByteArrayBlob: function(dataURI) {

        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = window.atob(dataURI.split(',')[1]);
        else
            byteString = window.unescape(dataURI.split(',')[1]);
    
        // separate out the mime component
        // e.g., "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAA...."
        var mimeString = dataURI
            .split(',')[0]      // data:image/jpeg;base64
            .split(':')[1]      // image/jpeg;base64
            .split(';')[0];     // image/jpeg

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
    
        return new Blob([ia], {type:mimeString});
    },
    zIndexForDepth: function(depth, maxDepth) {
        
        var idx = maxDepth;
		var zIndex = 0;

        ///   This needs to return the highest number for the lowest depth (e.g., 0 = 3000, 1 = 2000, 2 = 1000, ...)
		while (idx >= depth)
		{
			zIndex += 1000;

			idx--;
		}

		return zIndex;
    },
    zIndexInverseForDepth: function(depth) {

        var idx = 0;
		var zIndex = 0;

        ///   This needs to return the highest number for the highest depth (e.g., 0 = 1000, 1 = 2000, 2 = 3000, ...)
		while (idx <= depth)
		{
			zIndex += 1000;

			idx++;
		}

		return zIndex;
    },
    encodeToBase64: function(value) {
        //return Buffer.from(value, "utf8").toString("base64");  // DO NOT USE - This is for node.js on the server side!

        return window.btoa(value);  // client-side
    },
    decodeFromBase64: function(value) {
        //return Buffer.from(value, "base64").toString("utf8");  // DO NOT USE - This is for node.js on the server side!

        return window.atob(value);  // client-side
    },
    convertSecondsToMillis: function(seconds) {
        return (seconds * 1000);
    },
    pullOutValues: function(arr, key) {
        if (!Utilities.isValidObj(arr))
            return arr;

        var vals = [];

        for (let idx = 0; idx < arr.length; idx++) {
            vals.push(arr[idx][key]);
        }

        return vals;
    },
    convertArrayToInts: function(arr) {

        if (!Utilities.isValidObj(arr))
            return arr;

        var retArray = [];
        for (var i = 0; i < arr.length; i++) {

            try {
                retArray.push(parseInt(arr[i]));
            }
            catch(err) {
                continue;
            }
        }

        return retArray;
    },
    calcNumOfPages: function (totalNumOfItems, numOfItemsPerPage) {
        return Math.floor((totalNumOfItems / numOfItemsPerPage) + 1);  // Why not ceil?  This way we are guaranteed it will be 1-based.
    },
    onAddValue: function (value, array) {

        if (typeof array === "undefined" || array === null)
            array = [];

        let tempVal = parseInt(value);
        if (this.findItemByValue(tempVal, array) === null) {
            Logger.log("Add value to the array: " + tempVal);

            var copyOf = JSON.parse(JSON.stringify(array));  // make a deep copy of this array; otherwise the callee won't pick up the change when we set it
            copyOf.push(tempVal);  // NOTE: "push" will affect the target array so no need to assign it back!

            Logger.log("Adjusted array:");
            Logger.logObj(copyOf);

            return copyOf;
        }

        return array;
    },
    onRemoveValue: function (value, array) {

        if (typeof array === "undefined" || array === null)
            array = [];

        let tempVal = parseInt(value);
        if (this.findItemByValue(tempVal, array) !== null) {

            var idx = array.indexOf(tempVal);
            if (idx >= 0) {
                Logger.log("Remove value from the array: " + tempVal);

                var copyOf = JSON.parse(JSON.stringify(array));  // make a deep copy of this array; otherwise the callee won't pick up the change when we set it
                copyOf.splice(idx, 1);  // NOTE: "splice" will affect the target array so no need to assign it back!

                Logger.log("Adjusted array:");
                Logger.logObj(copyOf);

                return copyOf;
            }
        }

        return array;
    },
    convertKeysToIntArray: function (obj, byValue) {

        let converted = [];
        for (let nextKey in obj) {

            let value = nextKey;

            if (byValue && obj[nextKey] !== null && typeof obj[nextKey] !== "undefined")
                value = parseInt(obj[nextKey].value);

            converted.push(value);
        }

        return converted;
    },
    naturalSort: function(str1, str2) {
        if (str1 == str2) return 0;
        if (!str1) return -1;
        if (!str2) return 1;

        var cmpRegex = /(\.\d+)|(\d+)|(\D+)/g,
            tokens1 = String(str1).toLowerCase().match(cmpRegex),
            tokens2 = String(str2).toLowerCase().match(cmpRegex),
            count = Math.min(tokens1.length, tokens2.length);

        for (var i = 0; i < count; i++) {
            var a = tokens1[i], b = tokens2[i];
            if (a !== b){
                var num1 = parseInt(a, 10);
                if (!isNaN(num1)){
                    var num2 = parseInt(b, 10);
                    if (!isNaN(num2) && num1 - num2)
                        return num1 - num2;
                }
                return a < b ? -1 : 1;
            }
        }

        if (tokens1.length === tokens2.length)
            return tokens1.length - tokens2.length;

        return str1 < str2 ? -1 : 1;
    },
    naturalSortFilter: function(obj1, obj2) {

        return Utilities.naturalSort(obj1.name, obj2.name)
    },
    naturalSortRNPickerItem: function(obj1, obj2) {

        return Utilities.naturalSort(obj1.label, obj2.label)
    },
    setSelectedItem: function(fnSetSelectedItem, item) {

        if (typeof fnSetSelectedItem !== "function")
            return;

        if (Utilities.isValidObj(item)) {

            let value = parseInt(item);
            fnSetSelectedItem(value);
        }
        else
            fnSetSelectedItem(-1);
    },
    addListener: function(listeners, setListeners, fnCallback) {

        let temp = listeners;
        temp.push(fnCallback);
        setListeners(temp);
    },
    removeListener: function(listeners, setListeners, fnCallback) {
        let temp = listeners;
        let idx = temp.indexOf(fnCallback);
        if (idx > -1)
            temp.splice(idx, 1);

        setListeners(temp);
    },
    removeAllListeners: function(setListeners) {
        setListeners([]);
    },
    notifyAllListeners: function(listeners, data) {
        for (let idx = 0; idx < listeners.length; idx++) {
            let nextCallback = listeners[idx];
            if (typeof nextCallback === "function") {
                nextCallback(data);
            }
        }
    },
    parseJwt: function (token) {

        if (!Utilities.isValidObj(token) || token.length < 1)
            throw new AccessTokenIsNullOrEmptyError("Failed to parse JWT token (1)");

        try {
            let base64Url = token.split('.')[1];
            let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            let buffer = Utilities.decodeFromBase64(base64);
            return JSON.parse(buffer);
        }
        catch (err) {
            throw new JsonConvertDeserializationError("Failed to parse JWT token (2)");
        }
    },
    checkJwtToken: function(token) {

        if (!Utilities.isValidObj(token))
            throw new AccessTokenIsNullOrEmptyError("Invalid argument (1)");

        let jwtToken = (typeof token === "object") ? token.accessToken : token;
        if (!Utilities.isValidObj(jwtToken) || jwtToken.length < 1)
            throw new AccessTokenIsNullOrEmptyError("Invalid argument (2)");

        var jwt = Utilities.parseJwt(jwtToken);
        var millis = Utilities.convertSecondsToMillis(jwt.exp);
        if (new Date() >= new Date(millis))
            throw new AccessTokenHasExpiredError("Access token has expired.");
    },
    isValidJwtToken: function(token) {

        try {
            Utilities.checkJwtToken(token);
            return true;
        }
        catch (err) {
            return false;
        }
    }
    /* #dlb - TODO:  I would love to clean up the mess of async/await.  It's just confusing when several awaits are within the same try block.
    safeAsync: function(promise, customError) {

        const setErrorMessage = (err, customErr) => {
            customErr.message = err.message;
            return customErr;
        };

        promise
            .then(data => {
                if (data instanceof Error)
                    throw setErrorMessage(data, customError);

                return data;
            })
            .catch(err => {
                throw setErrorMessage(err, customError);
            });
    },//*/
};

export default Utilities;