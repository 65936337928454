import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    fieldGroupContainer: {
        borderColor: "#bfbfbf",
        borderBottomWidth: 1,
        marginBottom: 5,
        paddingBottom: 5,
    },
    fieldGroupHeader: {
        fontSize: "18px",
        fontWeight: "bolder",
        lineHeight: 30,
        marginVertical: 5,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    fieldGroupLabel: {
        display: "inline-block",
        width: 'auto',
    },
    pagingBtnIcon: {
        display: "inline-block",
        marginTop: 2,
    },
    fieldGroupRows: {
        //alignContext: "flex-start",  // default
        flex: 1,
        //flexDirection: "column",  // default
        flexWrap: "nowrap",
        //justifyContent: "flex-start",  // default
        width: '100%',
    },
    fieldGroupRow: {
        width: 'auto',
        height: 'auto',
    }
});