import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import PageFirstIcon from '../icons/pageFirstIcon';
import PagePreviousIcon from '../icons/pagePreviousIcon';
import PageNextIcon from '../icons/pageNextIcon';
import PageLastIcon from '../icons/pageLastIcon';

import styles from '../../styles/pagination/paginationControls';

const FirstButton = (props) => {

    const onPress = () => {
        props.onPress(1);
    }

    const renderIcon = () => {
        return (
            <PageFirstIcon 
                style={[styles.pagingBtnIcon]}
            />
        );
    };

    return (
        <TouchableOpacity
            style={styles.pagingBtn}
            onPress={onPress}
        >
            <Text
                style={styles.label}
            >
                {renderIcon()}
            </Text>
        </TouchableOpacity>
    );
}

const PreviousButton = (props) => {

    const currentPage = props.currentPage;

    const onPress = () => {
        let page = currentPage - 1;
        if (page < 1)
            page = 1;
        props.onPress(page);
    }

    const renderIcon = () => {
        return (
            <PagePreviousIcon 
                style={[styles.pagingBtnIcon]}
            />
        );
    };

    return (
        <TouchableOpacity
            style={styles.pagingBtn}
            onPress={onPress}
        >
            <Text
                style={styles.label}
            >
                {renderIcon()}
            </Text>
        </TouchableOpacity>
    );
}

const NextButton = (props) => {

    const currentPage = props.currentPage;
    const numOfPages = props.numOfPages;

    const onPress = () => {
        let page = currentPage + 1;
        if (page > numOfPages)
            page = numOfPages;

        props.onPress(page);
    }


    const renderIcon = () => {
        return (
            <PageNextIcon 
                style={[styles.pagingBtnIcon]}
            />
        );
    };

    return (
        <TouchableOpacity
            style={styles.pagingBtn}
            onPress={onPress}
        >
            <Text
                style={styles.label}
            >
                {renderIcon()}
            </Text>
        </TouchableOpacity>
    );
}

const LastButton = (props) => {

    const numOfPages = props.numOfPages;

    const onPress = () => {

        props.onPress(numOfPages);
    }

    const renderIcon = () => {
        return (
            <PageLastIcon 
                style={[styles.pagingBtnIcon]}
            />
        );
    };

    return (
        <TouchableOpacity
            style={styles.pagingBtn}
            onPress={onPress}
        >
            <Text
                style={styles.label}
            >
                {renderIcon()}
            </Text>
        </TouchableOpacity>
    );
}


const CurrentPageButton = (props) => {

    const currentPage = props.currentPage;

    return (
        <Text
            style={[styles.currentPageButton, styles.label]}
        >
            {currentPage}
        </Text>
    );
}


const PaginationControls = (props) => {

    const currentPage = props.currentPage;
    const numOfPages = props.numOfPages;
    const onChangePage = props.onChangePage;

	return (
        <>
            { numOfPages > 1 && (
                <View style={styles.paginationControlWrapper}>
                    <FirstButton
                        onPress={onChangePage}
                    />
                    <PreviousButton 
                        currentPage={currentPage}
                        onPress={onChangePage}
                    />
                    <CurrentPageButton
                        currentPage={currentPage}
                    />
                    <NextButton
                        currentPage={currentPage}
                        numOfPages={numOfPages}
                        onPress={onChangePage}
                    />
                    <LastButton
                        currentPage={currentPage}
                        numOfPages={numOfPages}
                        onPress={onChangePage}
                    />
                </View>
            )}
		</>
	);
};

export default PaginationControls;