import React, { createContext, useEffect, useState, useContext } from 'react';
import Logger from '../utilities/logger';
import Utilities from '../utilities/utilities';
import ApiService from '../services/apiService';
import Filter from '../dtos/filter';

import { useLoadingConsumer } from './loadingContext';
import { useAuth } from './authContext';
import { useBadgeConsumer } from './badgeContext';
import { useProductSelectorPaginationConsumer } from './productSelectorPaginationContext';
import { useProductSelectorRequiredFiltersConsumer } from './productSelectorRequiredFiltersContext';
import { useProductSelectorOptionalFiltersConsumer } from './productSelectorOptionalFiltersContext';

import { FetchAbortedError, FetchInProgressError } from '../exceptions/fetchExceptions';

import SearchDto from '../dtos/searchDto';

const ProductSelectorSearchResultsContext = createContext();

const useProductSelectorSearchResultsConsumer = function() {
    const context = useContext(ProductSelectorSearchResultsContext);
    if (!context)
        throw new Error('useProductSelectorSearchResultsConsumer must be used within a ProductSelectorSearchResultsProvider');

    return context;
}
 
const ProductSelectorSearchResultsProvider = ({children}) => {

    const {
        isLoading,
        setIsLoading
    } = useLoadingConsumer(); 

    const {
        isInitialized,
        accessToken
    } = useAuth();

    const {
        setPrimaryMessage,
        setSuccessMessage,
        setInfoMessage,
        setWarningMessage,
        setErrorMessage,
        clearMessage
    } = useBadgeConsumer();

    const {
        resetPaging,
        numOfPages,
        currentPage,
        totalNumOfItems,
        numOfItemsPerPage,
        setCurrentPage,
        setTotalNumOfItems,
        setNumOfItemsPerPage,
    } = useProductSelectorPaginationConsumer();

    const {
        resetRequiredFilters,
        allFluids,
        allFlowRates,
        allAccessoryPackages,
        allRegistrations,
        setAllFluids,
        setAllFlowRates,
        setAllAccessoryPackages,
        setAllRegistrations,
        availableFluids,
        availableFlowRates,
        availableAccessoryPackages,
        availableRegistrations,
        setAvailableFluids,
        setAvailableFlowRates,
        setAvailableAccessoryPackages,
        setAvailableRegistrations,
        selectedFluid,
        selectedFlowRate,
        selectedAccessoryPackage,
        selectedRegistration,
        setSelectedFluid,
        setSelectedFlowRate,
        setSelectedAccessoryPackage,
        setSelectedRegistration,
        onFluidChanged,
        onFlowRateChanged,
        onAccessoryPackageChanged,
        onRegistrationChanged,
        onSearchTrigger,
        setOnSearchTrigger,
        keyword,
        setKeyword,
        hints,
        setHints,
        onHintSelected,
    } = useProductSelectorRequiredFiltersConsumer();

    const {
        resetOptionalFilters,
        allOptionalAccessories,
        allFlowDirections,
        allUnitOfMeasures,
        allWeightsAndMeasures,
        allElastomers,
        allAirVaporEliminators,
        allStrainers,
        setAllOptionalAccessories,
        setAllFlowDirections,
        setAllUnitOfMeasures,
        setAllWeightsAndMeasures,
        setAllElastomers,
        setAllAirVaporEliminators,
        setAllStrainers,
        availableOptionalAccessories,
        availableFlowDirections,
        availableUnitOfMeasures,
        availableWeightsAndMeasures,
        availableElastomers,
        availableAirVaporEliminators,
        availableStrainers,
        setAvailableOptionalAccessories,
        setAvailableFlowDirections,
        setAvailableUnitOfMeasures,
        setAvailableWeightsAndMeasures,
        setAvailableElastomers,
        setAvailableAirVaporEliminators,
        setAvailableStrainers,
        selectedOptionalAccessories,
        selectedFlowDirection,
        selectedUnitOfMeasures,
        selectedWeightsAndMeasures,
        selectedElastomers,
        selectedAirVaporEliminators,
        selectedStrainers,
        setSelectedOptionalAccessories,
        setSelectedFlowDirection,
        setSelectedUnitOfMeasures,
        setSelectedWeightsAndMeasures,
        setSelectedElastomers,
        setSelectedAirVaporEliminators,
        setSelectedStrainers,
        onAddOptionalAccessory,
        onRemoveOptionalAccessory,
        onFlowDirectionChanged,
        onAddUnitOfMeasure,
        onRemoveUnitOfMeasure,
        onAddWeightsAndMeasure,
        onRemoveWeightsAndMeasure,
        onAddElastomer,
        onRemoveElastomer,
        onAddAirVaporEliminator,
        onRemoveAirVaporEliminator,
        onAddStrainer,
        onRemoveStrainer,
    } = useProductSelectorOptionalFiltersConsumer();


    var abortControllerForFilters = null;
    var abortControllerForData = null;
    var isFetchingFilters = false;
    var isFetchingData = false;
    
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState();

    const updateIsLoading = () => {
        setIsLoading(isFetchingFilters || isFetchingData);
    };

    const setupAbortControllerForFilters = () => {
        if (abortControllerForFilters === null || typeof abortControllerForFilters === "undefined" || abortControllerForFilters.signal.aborted) {
            abortControllerForFilters = new AbortController();
        }
    };

    const setupAbortControllerForData = () => {
        if (abortControllerForData === null || typeof abortControllerForData === "undefined" || abortControllerForData.signal.aborted) {
            abortControllerForData = new AbortController();
        }
    };

    const [resetDoneEventListeners, setResetDoneEventListeners] = useState([]);
    const addResetDoneEventListener = (fnCallback) => {

        let temp = resetDoneEventListeners;
        temp.push(fnCallback);
        setResetDoneEventListeners(temp);
    }

    const removeResetDoneEventListener = (fnCallback) => {
        let temp = resetDoneEventListeners;
        let idx = temp.indexOf(fnCallback);
        if (idx > -1)
            temp.splice(idx, 1);

        setResetDoneEventListeners(temp);
    }

    const removeAllResetDoneEventListeners = () => {
        setResetDoneEventListeners([]);
    }

    const dispatchResetDoneEvent = () => {
        for (let idx = 0; idx < resetDoneEventListeners.length; idx++) {
            let nextCallback = resetDoneEventListeners[idx];
            if (typeof nextCallback === "function") {
                nextCallback();
            }
        }
    }

    const resetFilters = () => {

        resetPaging();
        resetRequiredFilters();
        resetOptionalFilters();

        setProducts([]);

        dispatchResetDoneEvent();
    };


    const updatePaging = (data) => {

        //console.log("Updating paging on PRODUCTS", data);
        if (!Utilities.isValidObj(data)) {
            //Logger.log("Invalid data passed into updatePaging");
            return;
        }

        setCurrentPage(data.currentPage);
        setTotalNumOfItems(data.totalNumOfItems);
    };


    const convertKeyValuePairsToFilterArray = (obj, noSorting) => {

        let converted = [];
        for (let nextKey in obj) {
            converted.push(new Filter(nextKey, obj[nextKey]));
        }

        if (noSorting !== true)
            converted.sort(Utilities.naturalSortFilter);

        return converted;
    };


    const updateAllRequiredFilters = (data) => {

        if (!Utilities.isValidObj(data)) {
            //Logger.log("Invalid data passed into updateAllRequiredFilters");
            return;
        }

        setAllFluids(convertKeyValuePairsToFilterArray(data.allFluids));
        setAvailableFluids(Utilities.convertKeysToIntArray(data.allFluids));

        setAllFlowRates(convertKeyValuePairsToFilterArray(data.allFlowRates));
        setAvailableFlowRates(Utilities.convertKeysToIntArray(data.allFlowRates));

        setAllAccessoryPackages(convertKeyValuePairsToFilterArray(data.allAccessoryPackages));
        setAvailableAccessoryPackages(Utilities.convertKeysToIntArray(data.allAccessoryPackages));

        setAllRegistrations(convertKeyValuePairsToFilterArray(data.allRegistrations));
        setAvailableRegistrations(Utilities.convertKeysToIntArray(data.allRegistrations));
    };


    const updateAllOptionalFilters = (data) => {

        if (!Utilities.isValidObj(data)) {
            //Logger.log("Invalid data passed into updateAllOptionalFilters");
            return;
        }

        setAllOptionalAccessories(convertKeyValuePairsToFilterArray(data.allOptionalAccessories));
        setAvailableOptionalAccessories(Utilities.convertKeysToIntArray(data.allOptionalAccessories));

        setAllFlowDirections(convertKeyValuePairsToFilterArray(data.allFlowDirections));
        setAvailableFlowDirections(Utilities.convertKeysToIntArray(data.allFlowDirections));

        setAllUnitOfMeasures(convertKeyValuePairsToFilterArray(data.allUnitOfMeasures));
        setAvailableUnitOfMeasures(Utilities.convertKeysToIntArray(data.allUnitOfMeasures));

        setAllWeightsAndMeasures(convertKeyValuePairsToFilterArray(data.allWeightsAndMeasures));
        setAvailableWeightsAndMeasures(Utilities.convertKeysToIntArray(data.allWeightsAndMeasures));

        setAllElastomers(convertKeyValuePairsToFilterArray(data.allElastomers));
        setAvailableElastomers(Utilities.convertKeysToIntArray(data.allElastomers));

        setAllAirVaporEliminators(convertKeyValuePairsToFilterArray(data.allAirVaporEliminators));
        setAvailableAirVaporEliminators(Utilities.convertKeysToIntArray(data.allAirVaporEliminators));

        setAllStrainers(convertKeyValuePairsToFilterArray(data.allStrainers));
        setAvailableStrainers(Utilities.convertKeysToIntArray(data.allStrainers));
    };

    const updateAvailableRequiredFilters = (availableFilters, selectedFilters) => {

        if (Utilities.isValidObj(availableFilters)) {
            setAvailableFluids(availableFilters.availableFluids);
            setAvailableFlowRates(availableFilters.availableFlowRates);
            setAvailableAccessoryPackages(availableFilters.availableAccessoryPackages);
            setAvailableRegistrations(availableFilters.availableRegistrations);
        } else {
            setAvailableFluids(Utilities.convertKeysToIntArray(allFluids, true));
            setAvailableFlowRates(Utilities.convertKeysToIntArray(allFlowRates, true));
            setAvailableAccessoryPackages(Utilities.convertKeysToIntArray(allAccessoryPackages, true));
            setAvailableRegistrations(Utilities.convertKeysToIntArray(allRegistrations, true));
        }

        if (Utilities.isValidObj(selectedFilters)) {
            setSelectedFluid(selectedFilters.selectedFluid);
            setSelectedFlowRate(selectedFilters.selectedFlowRate);
            setSelectedAccessoryPackage(selectedFilters.selectedAccessoryPackage);
            setSelectedRegistration(selectedFilters.selectedRegistration);
        }
    };


    const updateAvailableOptionalFilters = (availableFilters, selectedFilters) => {

        if (Utilities.isValidObj(availableFilters)) {
            setAvailableOptionalAccessories(availableFilters.availableOptionalAccessories);
            setAvailableFlowDirections(availableFilters.availableFlowDirections);
            setAvailableUnitOfMeasures(availableFilters.availableUnitOfMeasures);
            setAvailableWeightsAndMeasures(availableFilters.availableWeightsAndMeasures);
            setAvailableElastomers(availableFilters.availableElastomers);
            setAvailableAirVaporEliminators(availableFilters.availableAirVaporEliminators);
            setAvailableStrainers(availableFilters.availableStrainers);
        }
        else {
            setAvailableOptionalAccessories(Utilities.convertKeysToIntArray(allOptionalAccessories, true));
            setAvailableFlowDirections(Utilities.convertKeysToIntArray(allFlowDirections, true));
            setAvailableUnitOfMeasures(Utilities.convertKeysToIntArray(allUnitOfMeasures, true));
            setAvailableWeightsAndMeasures(Utilities.convertKeysToIntArray(allWeightsAndMeasures, true));
            setAvailableElastomers(Utilities.convertKeysToIntArray(allElastomers, true));
            setAvailableAirVaporEliminators(Utilities.convertKeysToIntArray(allAirVaporEliminators, true));
            setAvailableStrainers(Utilities.convertKeysToIntArray(allStrainers, true));
        }

        if (Utilities.isValidObj(selectedFilters)) {
            setSelectedOptionalAccessories(selectedFilters.selectedOptionalAccessories);
            setSelectedFlowDirection(selectedFilters.selectedFlowDirection);
            setSelectedUnitOfMeasures(selectedFilters.selectedUnitOfMeasures);
            setSelectedWeightsAndMeasures(selectedFilters.selectedWeightsAndMeasures);
            setSelectedElastomers(selectedFilters.selectedElastomers);
            setSelectedAirVaporEliminators(selectedFilters.selectedAirVaporEliminators);
            setSelectedStrainers(selectedFilters.selectedStrainers);
        }
    };


    const updateProducts = (data) => {

        if (!Utilities.isValidObj(data)) {
            //Logger.log("Invalid data passed into updateProducts");
            return;
        }

        setProducts(data);
    };


    const updateHints = (data) => {

        if (!Utilities.isValidObj(data)) {
            //Logger.log("Invalid data passed into updateHints");
            return;
        }

        if (Array.isArray(data) && data.length > 1)
            setHints(data);
        else
            setHints([]);
    };


    const fetchData = async () => {

        if (isFetchingData)
            throw new FetchInProgressError();

        Utilities.checkJwtToken(accessToken);

        //Logger.startFunc("  productSelectorSearchResultsContext.fetchData");

        isFetchingData = true;
        setIsLoading(true);

        try {

            var requestDto = new SearchDto();

            requestDto.paging.currentPage = currentPage;
            requestDto.paging.pageSize = numOfItemsPerPage;

            requestDto.keyword = keyword;

            requestDto.selectedFilters.selectedFluid = selectedFluid;
            requestDto.selectedFilters.selectedFlowRate = selectedFlowRate;
            requestDto.selectedFilters.selectedAccessoryPackage = selectedAccessoryPackage;
            requestDto.selectedFilters.selectedRegistration = selectedRegistration;

            requestDto.selectedFilters.selectedOptionalAccessories = selectedOptionalAccessories;
            requestDto.selectedFilters.selectedFlowDirection = selectedFlowDirection;
            requestDto.selectedFilters.selectedUnitOfMeasures = selectedUnitOfMeasures;
            requestDto.selectedFilters.selectedWeightsAndMeasures = selectedWeightsAndMeasures;
            requestDto.selectedFilters.selectedElastomers = selectedElastomers;
            requestDto.selectedFilters.selectedAirVaporEliminators = selectedAirVaporEliminators;
            requestDto.selectedFilters.selectedStrainers = selectedStrainers;

            setupAbortControllerForData();

            let responseDto = await ApiService.search(accessToken, requestDto, abortControllerForData);
            //Logger.logObj(responseDto);

            if (abortControllerForData.signal.aborted)
                throw new FetchAbortedError();

            if (responseDto.status !== 0)
                setErrorMessage(responseDto.message);
            else {

                setKeyword(responseDto.keyword);
                updateHints(responseDto.hints);

                updatePaging(responseDto.paging);

                updateAvailableRequiredFilters(responseDto.availableFilters, responseDto.selectedFilters);
                updateAvailableOptionalFilters(responseDto.availableFilters, responseDto.selectedFilters);

                updateProducts(responseDto.products);
            }
            
            //Logger.log("  productSelectorSearchResultsContext.fetchData.resolved");
        }
        catch (err) {

            //if (err instanceof FetchAbortedError || err.name === "AbortError")
                //Logger.log("    productSelectorSearchResultsContext.fetchData.rejected - ABORTED");
            //else
                //Logger.log("  productSelectorSearchResultsContext.fetchData.rejected");

            throw err;
        }
        finally {

            setOnSearchTrigger(false);

            isFetchingData = false;
            updateIsLoading();

            //Logger.endFunc("  productSelectorSearchResultsContext.fetchData.finally");
        }
    };


    const loadAllFilters = async () => {

        if (isFetchingFilters)
            throw new FetchInProgressError();

        Utilities.checkJwtToken(accessToken);

        //Logger.startFunc("  productSelectorSearchResultsContext.loadAllFilters");

        isFetchingFilters = true;
        setIsLoading(true);

        try {

            setupAbortControllerForFilters();

            let responseDto = await ApiService.loadOptions(accessToken, abortControllerForFilters);
            //Logger.logObj(responseDto);

            if (responseDto.status !== 0)
                setErrorMessage(responseDto.message);
            else if (!abortControllerForFilters.signal.aborted) {

                updateAllRequiredFilters(responseDto.allFilters);
                updateAllOptionalFilters(responseDto.allFilters);
            }
            
            //Logger.log("  ProductSelectorSearchResultsContext.loadAllFilters.resolved");
        }
        catch (err) {

            //if (err instanceof FetchAbortedError || err.name === "AbortError")
                //Logger.log("  productSelectorSearchResultsContext.loadAllFiltersSync.rejected - ABORTED");
            //else
                //Logger.log("  productSelectorSearchResultsContext.fetchData.rejected");

            throw err;
        }
        finally {
            isFetchingFilters = false;
            updateIsLoading();

            //Logger.endFunc("productSelectorSearchResultsContext.loadAllFilters.finally");
        }
    };

    const abortFetchForFilters = () => {
        if (isFetchingFilters && abortControllerForFilters !== null && typeof abortControllerForFilters !== "undefined" && !abortControllerForFilters.signal.aborted) {
            //Logger.log("  *** aborting the fetching of: FILTERS ***");
            abortControllerForFilters.abort();
        }
        isFetchingFilters = false;
    };


    const abortFetchForData = () => {
        if (isFetchingData && abortControllerForData !== null && typeof abortControllerForData !== "undefined" && !abortControllerForData.signal.aborted) {
            //Logger.log("  *** aborting the fetching of: DATA ***");
            abortControllerForData.abort();
        }
        isFetchingData = false;
    };


    const unmountedCleanup = () => {
        //Logger.startFunc("productSelectorSearchResultsContext.unmountedCleanup");
        
        abortFetchForFilters();

        abortFetchForData();

        removeAllResetDoneEventListeners();

        //Logger.endFunc("productSelectorSearchResultsContext.unmountedCleanup");
    };


    // IMPORTANT:  The ordering of useEffect is the order it will be called!
    useEffect(() => {

        //Logger.startFunc("productSelectorSearchResultsContext.useEffect.default (sync)");
        //Logger.endFunc("productSelectorSearchResultsContext.useEffect.default (sync)");

        return () => {
            unmountedCleanup();
        }
    }, []);

/*
    useEffect(() => {

        //Logger.startFunc("productSelectorSearchResultsContext.useEffect.accessToken");

        if (isInitialized && !isLoading && !isFetchingFilters) {

            (async () => {
                //Logger.startFunc("  productSelectorSearchResultsContext.useEffect.default (async)");

                try {
                    await loadAllFilters();
                }
                catch (err) {
                    if (err instanceof FetchAbortedError || err.name === "AbortError")
                        Logger.log("  productSelectorSearchResultsContext.useEffect.default.rejected - ABORTED");
                    else
                        Logger.majorException(err);
                }
                finally {
                    //Logger.endFunc("  productSelectorSearchResultsContext.useEffect.default (async)");
                }
            })();
        }

        //Logger.endFunc("productSelectorSearchResultsContext.useEffect.accessToken");

        return () => {
            abortFetchForFilters();
        };
    }, [accessToken]);
    //*/


    useEffect(() => {

        //Logger.startFunc("productSelectorSearchResultsContext.useEffect.filters (sync)");

        if (isInitialized && !isLoading && !isFetchingData) {

            (async () => {
                //Logger.startFunc("  productSelectorSearchResultsContext.useEffect.filters (async)");
    
                try {
                    await fetchData();
                } catch (err) {
                    // ignore this, we've already handled it
                    Logger.handledException(err);
                } finally {
                    //Logger.endFunc("  productSelectorSearchResultsContext.useEffect.filters (async)");
                }
            })();
        }

        //Logger.endFunc("productSelectorSearchResultsContext.useEffect.filters (sync)");

        return () => {
            abortFetchForData();
        };
    }, [
        currentPage,
        numOfItemsPerPage,
        onSearchTrigger,             // SPECIAL CASE
        selectedFluid,
        selectedFlowRate,
        selectedAccessoryPackage,
        selectedRegistration,
		selectedOptionalAccessories,
        selectedFlowDirection,
        selectedUnitOfMeasures,
        selectedWeightsAndMeasures,
        selectedElastomers,
        selectedAirVaporEliminators,
        selectedStrainers
    ]);


    return (
        <ProductSelectorSearchResultsContext.Provider 
            value={
                {   // corresponds to our own properties and functions within this object
                    addResetDoneEventListener,
                    removeResetDoneEventListener,
                    resetFilters,
                    loadAllFilters,
                    products,
                    setProducts,
                    selectedProduct,
                    setSelectedProduct,
                    //useLoadingConsumer
                    isLoading,
                    setIsLoading,
                    //useBadgeConsumer
                    setPrimaryMessage,
                    setSuccessMessage,
                    setInfoMessage,
                    setWarningMessage,
                    setErrorMessage,
                    clearMessage,
                    //usePaginationConsumer
                    //resetPaging,
                    numOfPages,
                    currentPage,
                    totalNumOfItems,
                    numOfItemsPerPage,
                    setCurrentPage,
                    setTotalNumOfItems,
                    setNumOfItemsPerPage,
                    //useRequiredFiltersConsumer
                    //resetRequiredFilters,
                    allFluids,
                    allFlowRates,
                    allAccessoryPackages,
                    allRegistrations,
                    setAllFluids,
                    setAllFlowRates,
                    setAllAccessoryPackages,
                    setAllRegistrations,
                    availableFluids,
                    availableFlowRates,
                    availableAccessoryPackages,
                    availableRegistrations,
                    setAvailableFluids,
                    setAvailableFlowRates,
                    setAvailableAccessoryPackages,
                    setAvailableRegistrations,
                    selectedFluid,
                    selectedFlowRate,
                    selectedAccessoryPackage,
                    selectedRegistration,
                    setSelectedFluid,
                    setSelectedFlowRate,
                    setSelectedAccessoryPackage,
                    setSelectedRegistration,
                    onFluidChanged,
                    onFlowRateChanged,
                    onAccessoryPackageChanged,
                    onRegistrationChanged,
                    onSearchTrigger,
                    setOnSearchTrigger,
                    keyword,
                    setKeyword,
                    hints,
                    onHintSelected,
                    //useOptionalFiltersConsumer
                    //resetOptionalFilters,
                    allOptionalAccessories,
                    allFlowDirections,
                    allUnitOfMeasures,
                    allWeightsAndMeasures,
                    allElastomers,
                    allAirVaporEliminators,
                    allStrainers,
                    setAllOptionalAccessories,
                    setAllFlowDirections,
                    setAllUnitOfMeasures,
                    setAllWeightsAndMeasures,
                    setAllElastomers,
                    setAllAirVaporEliminators,
                    setAllStrainers,
                    availableOptionalAccessories,
                    availableFlowDirections,
                    availableUnitOfMeasures,
                    availableWeightsAndMeasures,
                    availableElastomers,
                    availableAirVaporEliminators,
                    availableStrainers,
                    setAvailableOptionalAccessories,
                    setAvailableFlowDirections,
                    setAvailableUnitOfMeasures,
                    setAvailableWeightsAndMeasures,
                    setAvailableElastomers,
                    setAvailableAirVaporEliminators,
                    setAvailableStrainers,
                    selectedOptionalAccessories,
                    selectedFlowDirection,
                    selectedUnitOfMeasures,
                    selectedWeightsAndMeasures,
                    selectedElastomers,
                    selectedAirVaporEliminators,
                    selectedStrainers,
                    setSelectedOptionalAccessories,
                    setSelectedFlowDirection,
                    setSelectedUnitOfMeasures,
                    setSelectedWeightsAndMeasures,
                    setSelectedElastomers,
                    setSelectedAirVaporEliminators,
                    setSelectedStrainers,
                    onAddOptionalAccessory,
                    onRemoveOptionalAccessory,
                    onFlowDirectionChanged,
                    onAddUnitOfMeasure,
                    onRemoveUnitOfMeasure,
                    onAddWeightsAndMeasure,
                    onRemoveWeightsAndMeasure,
                    onAddElastomer,
                    onRemoveElastomer,
                    onAddAirVaporEliminator,
                    onRemoveAirVaporEliminator,
                    onAddStrainer,
                    onRemoveStrainer,
                }
            }
        >
            {children}
        </ProductSelectorSearchResultsContext.Provider>
    );
};

export { ProductSelectorSearchResultsContext, ProductSelectorSearchResultsProvider, useProductSelectorSearchResultsConsumer };
