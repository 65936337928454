import EnumKey from './enumKey';

const ScreenEnum = {
    Login: new EnumKey(0, "Login"),
    Registration: new EnumKey(1, "Registration"),
    ForgotPassword: new EnumKey(2, "ForgotPassword"),
    PostLoginLanding: new EnumKey(3, "PostLoginLanding"),
    Drawer: new EnumKey(4, "Drawer"),
    ProductSelectorLanding: new EnumKey(5, "ProductSelectorLanding"),
    ProductSelectorSearch: new EnumKey(6, "ProductSelectorSearch"),
    ProductSelectorDetails: new EnumKey(7, "ProductSelectorDetails"),
    PartNumberLanding: new EnumKey(8, "PartNumberLanding"),
    PartNumberSearch: new EnumKey(9, "PartNumberSearch"),
    PartNumberDetails: new EnumKey(10, "PartNumberDetails"),
    ProductPages: new EnumKey(11, "ProductPages"),
    PartPages: new EnumKey(12, "PartPages"),
};

export default ScreenEnum;