import Utilities from '../utilities/utilities';
import LocalStorageEnum from '../enums/localStorageEnum';
import LocalStorage from '../utilities/localStorage';
//import SecureStorage from '../utilities/secureStorage';
import LoginDto from '../dtos/loginDto';
import LoginResult from '../dtos/loginResult';

const PrivateLocalStorageService = {
    clear: async function(key) {

        return await LocalStorage.clear(key);

        /*
        // TODO:  iOS/android deployments will need this tested - NOTE:  I had to remove this to support PWAs too so test that too!
        let result = null;

        if (await DeviceInfo.isNotPhysicalDevice())
            result = await LocalStorage.clear(key);
        else
            result = await SecureStorage.clear(key);

        return result;
        //*/
    },
    getJson: async function (key) {

        return await LocalStorage.getJson(key);

        /*
        // TODO:  iOS/android deployments will need this tested - NOTE:  I had to remove this to support PWAs too so test that too!

        let jsonObj = null;

        let isNotPhysicalDevice = await DeviceInfo.isNotPhysicalDevice();
        if (isNotPhysicalDevice)
            jsonObj = await LocalStorage.getJson(key);
        else
            jsonObj = await SecureStorage.getJson(key);

        return jsonObj;
        //*/
    },
    setJson: async function (key, jsonData) {

        return await LocalStorage.setJson(key, jsonData);

        /*
        // TODO:  iOS/android deployments will need this tested - NOTE:  I had to remove this to support PWAs too so test that too!

        let result = null;

        if (await DeviceInfo.isNotPhysicalDevice())
            result = await LocalStorage.setJson(key, jsonData);
        else
            result = await SecureStorage.setJson(key, jsonData);

        return result;
        //*/
    }
};


const LocalStorageService = {
    ///<throws>
    ///  SecureStorageNotAvailableOnDeviceError
    ///  InvalidArgumentError
    ///</throws>
    ///<returns>
    ///  Promise<void>
    ///</returns>
    clearLoginResult: async function() {
        return await PrivateLocalStorageService.clear(LocalStorageEnum.LoginResult.key);
    },
    ///<throws>
    ///  SecureStorageNotAvailableOnDeviceError
    ///  InvalidArgumentError
    ///</throws>
    ///<returns>
    ///  Promise<void>
    ///</returns>
    setLoginResult: async function(data) {
        return await PrivateLocalStorageService.setJson(LocalStorageEnum.LoginResult.key, data);
    },
    ///<throws>
    ///  SecureStorageNotAvailableOnDeviceError
    ///  InvalidArgumentError
    ///  JsonConvertDeserializationError
    ///  AccessTokenIsNullOrEmptyError
    ///  AccessTokenHasExpiredError
    ///</throws>
    ///<returns>
    ///  Promise<LoginResult>
    ///</returns>
    getLoginResult: async function() {
        let jsonObj = await PrivateLocalStorageService.getJson(LocalStorageEnum.LoginResult.key);

        Utilities.checkJwtToken(jsonObj);

        return new LoginResult(jsonObj.accessToken, jsonObj.displayName);
    },
    ///<throws>
    ///  SecureStorageNotAvailableOnDeviceError
    ///  InvalidArgumentError
    ///</throws>
    ///<returns>
    ///  Promise<void>
    ///</returns>
    clearLoginCreds: async function() {
        return await PrivateLocalStorageService.clear(LocalStorageEnum.LoginCreds.key);
    },
    ///<throws>
    ///  SecureStorageNotAvailableOnDeviceError
    ///  InvalidArgumentError
    ///</throws>
    ///<returns>
    ///  Promise<void>
    ///</returns>
    setLoginCreds: async function(data) {
        return await PrivateLocalStorageService.setJson(LocalStorageEnum.LoginCreds.key, data);
    },
    ///<throws>
    ///  SecureStorageNotAvailableOnDeviceError
    ///  InvalidArgumentError
    ///  JsonConvertDeserializationError
    ///</throws>
    ///<returns>
    ///  Promise<LoginDto>
    ///</returns>
    getLoginCreds: async function() {
        let jsonObj = await PrivateLocalStorageService.getJson(LocalStorageEnum.LoginCreds.key);
        return new LoginDto(jsonObj.username, jsonObj.password, jsonObj.rememberMe);
    },
    ///<returns>
    ///     boolean
    ///</returns>
    setPwaInstallPromptWasShown: async function(flag) {
        let obj = { "pwaInstallPromptWasShown" : flag };
        return await PrivateLocalStorageService.setJson(LocalStorageEnum.PwaInstallPromptWasShown.key, obj);
    },
    getPwaInstallPromptWasShown: async function() {

        let wasShown = false;

        try {
            let json = await PrivateLocalStorageService.getJson(LocalStorageEnum.PwaInstallPromptWasShown.key);
            wasShown = json.pwaInstallPromptWasShown;
        } catch (e) {
            
        }

        return wasShown;
    },
};

export default LocalStorageService;