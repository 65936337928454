class RegisterDto {

    firstName = "";
    lastName = "";
    username = "";
    password = "";
    email = "";
    phoneNumber = "";
    company = "";

    constructor(
        firstName,
        lastName,
        username,
        password,
        email,
        phoneNumber,
        company
    )
    {
        this.firstName = firstName;
        this.lastName = lastName;
        this.username = username;
        this.password = password;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.company = company;
    }
};

export default RegisterDto;