import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Image, TouchableOpacity, View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useAuth } from '../contexts/authContext';
import { useLoadingConsumer } from '../contexts/loadingContext';
import Logger from '../utilities/logger';
import IconEnum from '../enums/iconEnum';
import ScreenEnum from '../enums/screenEnum';
import Utilities from '../utilities/utilities';
import HeaderButton from '../controls/buttons/headerButton';
import HomeButton from '../controls/buttons/homeButton';

import logoStyles from '../styles/logo';
import bannerStyles from '../styles/banner';
import headerStyles from '../styles/header';

const Header = (props) => {

    const { isLoading } = useLoadingConsumer();

    const { accessToken, signOut } = useAuth();

    const route = useRoute();

    const navigation = useNavigation();

    const [ hasValidSession, setHasValidSession ] = useState(false);


    const sendToLandingPage = () => {
        if (Utilities.isValidObj(route) && route.name !== ScreenEnum.PostLoginLanding.key)
            navigation.navigate(ScreenEnum.PostLoginLanding.key, {name: ScreenEnum.PostLoginLanding.key});
    };


    const sendToLoginPage = () => {
        if (Utilities.isValidObj(route) && route.name !== ScreenEnum.Login.key)
            navigation.navigate(ScreenEnum.Login.key, {name: ScreenEnum.Login.key});
    };

    const onHomeClick = () => {
        try {
            Utilities.checkJwtToken(accessToken);

            sendToLandingPage();
        } catch (err) {
            sendToLoginPage();
        }
      };


    const onLogoClick = () => {
        //Logger.startFunc("header.onLogoClick");

        try {
            Utilities.checkJwtToken(accessToken);

            sendToLandingPage();
        } catch (err) {
            sendToLoginPage();
        }

        //Logger.endFunc("header.onLogoClick");
    };


    const onSignout = () => {

        (async () => {
            try {
                await signOut();

                sendToLoginPage();
            } catch (err) {
                Logger.handledException(err);
            }
        })();
    };


    useEffect(() => {
        //Logger.startFunc("header.effect.default");

        try {
            Utilities.checkJwtToken(accessToken);

            setHasValidSession(true);
        } catch (err) {
            Logger.handledException(err);
        }

        //Logger.endFunc("header.effect.default");
    }, []);

    
    return (
        <>
        <View style={headerStyles.container}>
            <HomeButton
                style={headerStyles.leftSection}
                onPress={onHomeClick}
                title={"Home"}
                icon={{ 
                    name: IconEnum.Home.name, 
                    color: "#fff",
                    position: "left",
                }} 
            />
            <TouchableOpacity
                style={headerStyles.leftSection}
                onPress={onLogoClick}
            >
                <Image 
                    source={require("../assets/appLogo.png")}
                    style={logoStyles.logo}
                />
            </TouchableOpacity>
            {/* <Image
                source={require('../assets/banner.png')}
                style={[
                    headerStyles.midSection,
                    bannerStyles.banner,
                ]}
            /> */}
            {(hasValidSession) && 
            <HeaderButton
                style={headerStyles.rightSection}
                onPress={onSignout}
                title={"Signout"}
                icon={{ 
                    name: IconEnum.Signout.name, 
                    color: "#fff",
                    position: "left",
                }} 
            />}
        </View>
        {(isLoading) && 
            <ActivityIndicator size='large' style={headerStyles.indicator} />
        }
        </>
    );
}

export default Header;