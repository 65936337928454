import styles from '../styles/badges/badge';

class BadgeTypeObj {
    id = -1;
    name = "";
    style = null;

    constructor(id, name, style) {
        this.id = id;
        this.name = name;
        this.style = style;
    }
};

const BadgeType = {
    Primary: new BadgeTypeObj(0, "Primary", styles.primary),
    Success: new BadgeTypeObj(1, "Success", styles.success),
    Info: new BadgeTypeObj(2, "Info", styles.info),
    Error: new BadgeTypeObj(3, "Error", styles.error),
    Warning: new BadgeTypeObj(4, "Warning", styles.warning),
};

export default BadgeType;