import React, { useState } from 'react';
import { Text } from 'react-native';
import { TouchableOpacity } from 'react-native';
import CheckboxIcon from '../icons/checkboxIcon';
import CheckboxSelectedIcon from '../icons/checkboxSelectedIcon';
import Logger from '../../utilities/logger';

import styles from '../../styles/checkboxes/checkField';

const CheckField = (props) => {

    const isDisabled = props.isDisabled;
    const isSelected = props.isSelected;
    const label = props.label;
    const value = props.value;
    const onAddValue = props.onAddValue;
    const onRemoveValue = props.onRemoveValue;

    const myStyle = isDisabled ? styles.disabled : styles.enabled;

    const [checked, setChecked] = useState(isSelected);

    const onChanged = () => {
        //Logger.startFunc("checkField.onChanged");

        if (!isDisabled) {
            var toggled = !checked;
            setChecked(toggled);

            if (toggled)
                onAddValue(value);
            else
                onRemoveValue(value);
        }

        //Logger.endFunc("checkField.onChanged | isDisabled: " + isDisabled);
    };

    const renderDeselected = () => {
        return (
            <CheckboxIcon style={[styles.icon]} />
        );
    };

    const renderSelected = () => {
        return (
            <CheckboxSelectedIcon style={[styles.icon]} />
        );
    };

    return (
        <TouchableOpacity
            style={[myStyle, styles.field]}
            onPress={onChanged}
        >
            {checked && (renderSelected())}
            {!checked && (renderDeselected())}
            <Text
                style={styles.label}
            >{label}
            </Text>
        </TouchableOpacity>
    );
}

export default CheckField;