import React, { createContext, useState, useContext } from 'react';
import BadgeType from '../enums/badgeTypeEnum';

const BadgeContext = createContext();

const useBadgeConsumer = function() {
    const context = useContext(BadgeContext);
    if (!context)
        throw new Error('useBadgeConsumer must be used within a BadgeProvider');

    return context;
}

const BadgeProvider = ({children}) => {

    const [message, setMessage] = useState("");
    const [badgeType, setBadgeType] = useState(BadgeType.Primary);

    const setBadgeTypeAndMessage = (type, msg) => {
        setBadgeType(type);
        setMessage(msg);
    };

    const setPrimaryMessage = (msg) => {
        setBadgeTypeAndMessage(BadgeType.Primary, msg);
    };

    const setSuccessMessage = (msg) => {
        setBadgeTypeAndMessage(BadgeType.Success, msg);
    };

    const setInfoMessage = (msg) => {
        setBadgeTypeAndMessage(BadgeType.Info, msg);
    };

    const setWarningMessage = (msg) => {
        setBadgeTypeAndMessage(BadgeType.Warning, msg);
    };

    const setErrorMessage = (msg) => {
        setBadgeTypeAndMessage(BadgeType.Error, msg);
    };

    const clearMessage = (msg) => {
        setBadgeTypeAndMessage(BadgeType.Primary, "");
    };

    return (
        <BadgeContext.Provider 
            value={
                {   // corresponds to our own properties and functions within this object
                    message,
                    badgeType,
                    setPrimaryMessage,
                    setSuccessMessage,
                    setInfoMessage,
                    setWarningMessage,
                    setErrorMessage,
                    clearMessage
                }
            }
        >
            {children}
        </BadgeContext.Provider>
    );
};

export { BadgeContext, BadgeProvider, useBadgeConsumer };
