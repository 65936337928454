import React, { useEffect } from 'react';
import { Text, View } from 'react-native';
import { SafeAreaView, ScrollView } from 'react-native';
import Logger from '../utilities/logger';
import Header from '../controls/header';
import Badge from '../controls/badges/badge';

import { useBadgeConsumer } from '../contexts/badgeContext';
import { useProductSelectorSearchResultsConsumer } from '../contexts/productSelectorSearchResultsContext';

import RequiredFilters from './components/requiredFilters';

import styles from '../styles/screens/landing';

 
const ProductSelectorLanding = ({ navigation, route }) => {

    const {
        setErrorMessage,
        clearMessage
    } = useBadgeConsumer();


    const {
        loadAllFilters
    } = useProductSelectorSearchResultsConsumer();


    const unmountCleanup = () => {

    };


    useEffect(() => {
        //Logger.startFunc("productSelectorLanding.useEffect.default");

        (async () => {
            try {
                await loadAllFilters()
            } catch (err) {
                Logger.majorException(err);
                setErrorMessage(err);
            }
        })();

        //Logger.endFunc("productSelectorLanding.useEffect.default");

        return () => {
            unmountCleanup();
        }
    }, []);


    return (
        <SafeAreaView style={styles.safeArea}>
            <Header />
            <Badge />
            <ScrollView style={styles.scrollContainer}>
                <View style={styles.landingContainer}>
                    <Text style={styles.landingHeader}>
                        {"LIQUID CONTROLS PRODUCT SELECTOR"}
                    </Text>
                    <View style={styles.textRow}>
                        <Text style={styles.textLeft}>
                            {"Select Fluid, Flow Rate, Accessory Package, and Registration"}
                        </Text>
                        <Text style={styles.textRight}>
                            {"The Liquid Controls Product Selector allows you to easily select the proper meter system for your product and application then export a specification sheet along with list price."}
                        </Text>
                    </View>
                    <Text style={styles.requiredFiltersChooseText}>
                        {"Choose at least 1 option below"}
                    </Text>
                    <RequiredFilters navigation={navigation} />
                    <View style={styles.landingBottom}>
                        <Text style={styles.landingBottom1}>
                            {"For more information on products and applications Visit: LCmeter.com"}
                        </Text>
                        <Text style={styles.landingBottom}>
                            {"The use of this tool is for authorized customers, distributors, and channel partners of Liquid Controls only. The content produced herein is confidential, and for authorized use only."}
                        </Text>
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>
    );
}

export default ProductSelectorLanding;