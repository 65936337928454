import React, { useState, useEffect } from 'react';
import { Text, View, Dimensions } from 'react-native';
import Utilities from '../../utilities/utilities';
import SearchInput from '../../controls/textboxes/searchInput';
import FieldGroup from '../../controls/collapsibles/fieldGroup';

import { useProductSelectorSearchResultsConsumer } from '../../contexts/productSelectorSearchResultsContext';

import styles from '../../styles/screens/components/facets';

const Facets = () => {
    const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);

    const isMobileScreen = (screenWidth <= 575);

  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(Dimensions.get('window').width);
    };

    Dimensions.addEventListener('change', updateScreenWidth);

    return () => {
      Dimensions.removeEventListener('change', updateScreenWidth);
    };
  }, []);

    const {
        addResetDoneEventListener,
        removeResetDoneEventListener,
        allFluids,
        allFlowRates,
        allAccessoryPackages,
        allRegistrations,
        availableFluids,
        availableFlowRates,
        availableAccessoryPackages,
        availableRegistrations,
        selectedFluid,
        selectedFlowRate,
        selectedAccessoryPackage,
        selectedRegistration,
        onFluidChanged,
        onFlowRateChanged,
        onAccessoryPackageChanged,
        onRegistrationChanged,
        setOnSearchTrigger,
        keyword,
        setKeyword,
        onKeywordChange,
        hints,
        onHintSelected,
        allOptionalAccessories,
        allFlowDirections,
        allUnitOfMeasures,
        allWeightsAndMeasures,
        allElastomers,
        allAirVaporEliminators,
        allStrainers,
        availableOptionalAccessories,
        availableFlowDirections,
        availableUnitOfMeasures,
        availableWeightsAndMeasures,
        availableElastomers,
        availableAirVaporEliminators,
        availableStrainers,
        selectedOptionalAccessories,
        selectedFlowDirection,
        selectedUnitOfMeasures,
        selectedWeightsAndMeasures,
        selectedElastomers,
        selectedAirVaporEliminators,
        selectedStrainers,
        onAddOptionalAccessory,
        onRemoveOptionalAccessory,
        onFlowDirectionChanged,
        onAddUnitOfMeasure,
        onRemoveUnitOfMeasure,
        onAddWeightsAndMeasure,
        onRemoveWeightsAndMeasure,
        onAddElastomer,
        onRemoveElastomer,
        onAddAirVaporEliminator,
        onRemoveAirVaporEliminator,
        onAddStrainer,
        onRemoveStrainer,
    } = useProductSelectorSearchResultsConsumer();

    return (
        <View style={isMobileScreen ? styles.facetsSidebarMobile : styles.facetsSidebar}>
            <View style={styles.facetsSearchInputWrap}>
                <SearchInput
                    keyword={keyword}
                    setKeyword={setKeyword}
                    onKeywordChange={onKeywordChange}
                    hints={hints}
                    onHintSelected={onHintSelected}
                    onSearchBtn={() => { setOnSearchTrigger(true); }}
                />
            </View>
            <Text>
                {"Choose at least 1 option below"}
            </Text> 
            { Utilities.isValidObj(allFluids) && 
                <FieldGroup
                    title={"Fluid"}
                    isRadio={true}
                    allItems={allFluids}
                    availableItems={availableFluids}
                    selectedItems={selectedFluid}
                    onSetValue={onFluidChanged}
                    addResetDoneEventListener={addResetDoneEventListener}
                    removeResetDoneEventListener={removeResetDoneEventListener}
                />
            }
            { Utilities.isValidObj(allFlowRates) && 
                <FieldGroup
                    title={"Flow Rate"}
                    isRadio={true}
                    allItems={allFlowRates}
                    availableItems={availableFlowRates}
                    selectedItems={selectedFlowRate}
                    onSetValue={onFlowRateChanged}
                    addResetDoneEventListener={addResetDoneEventListener}
                    removeResetDoneEventListener={removeResetDoneEventListener}
                />
            }
            { Utilities.isValidObj(allAccessoryPackages) && 
                <FieldGroup
                    title={"Accessory Package"}
                    isRadio={true}
                    allItems={allAccessoryPackages}
                    availableItems={availableAccessoryPackages}
                    selectedItems={selectedAccessoryPackage}
                    onSetValue={onAccessoryPackageChanged}
                    addResetDoneEventListener={addResetDoneEventListener}
                    removeResetDoneEventListener={removeResetDoneEventListener}
                />
            }
            { Utilities.isValidObj(allRegistrations) && 
                <FieldGroup
                    title={"Registration"}
                    isRadio={true}
                    allItems={allRegistrations}
                    availableItems={availableRegistrations}
                    selectedItems={selectedRegistration}
                    onSetValue={onRegistrationChanged}
                    addResetDoneEventListener={addResetDoneEventListener}
                    removeResetDoneEventListener={removeResetDoneEventListener}
                />
            }
            <Text>
                {"Choose any additional filters below"}
            </Text>
            { Utilities.isValidObj(allOptionalAccessories) && 
                <FieldGroup
                    title={"Optional Accessories"}
                    isRadio={false}
                    allItems={allOptionalAccessories}
                    availableItems={availableOptionalAccessories}
                    selectedItems={selectedOptionalAccessories}
                    onAddValue={onAddOptionalAccessory}
                    onRemoveValue={onRemoveOptionalAccessory}
                    addResetDoneEventListener={addResetDoneEventListener}
                    removeResetDoneEventListener={removeResetDoneEventListener}
                />
            }
            { Utilities.isValidObj(allFlowDirections) && 
                <FieldGroup
                    title={"Flow Direction"}
                    isRadio={true}
                    allItems={allFlowDirections}
                    availableItems={availableFlowDirections}
                    selectedItems={selectedFlowDirection}
                    onSetValue={onFlowDirectionChanged}
                    addResetDoneEventListener={addResetDoneEventListener}
                    removeResetDoneEventListener={removeResetDoneEventListener}
                />
            }
            { Utilities.isValidObj(allUnitOfMeasures) && 
                <FieldGroup
                    title={"Unit of Measure"}
                    isRadio={false}
                    allItems={allUnitOfMeasures}
                    availableItems={availableUnitOfMeasures}
                    selectedItems={selectedUnitOfMeasures}
                    onAddValue={onAddUnitOfMeasure}
                    onRemoveValue={onRemoveUnitOfMeasure}
                    addResetDoneEventListener={addResetDoneEventListener}
                    removeResetDoneEventListener={removeResetDoneEventListener}
                />
            }
            { Utilities.isValidObj(allWeightsAndMeasures) && 
                <FieldGroup
                    title={"Weight and Measures"}
                    isRadio={false}
                    allItems={allWeightsAndMeasures}
                    availableItems={availableWeightsAndMeasures}
                    selectedItems={selectedWeightsAndMeasures}
                    onAddValue={onAddWeightsAndMeasure}
                    onRemoveValue={onRemoveWeightsAndMeasure}
                    addResetDoneEventListener={addResetDoneEventListener}
                    removeResetDoneEventListener={removeResetDoneEventListener}
                />
            }
            { Utilities.isValidObj(allElastomers) && 
                <FieldGroup
                    title={"Elastomer"}
                    isRadio={false}
                    allItems={allElastomers}
                    availableItems={availableElastomers}
                    selectedItems={selectedElastomers}
                    onAddValue={onAddElastomer}
                    onRemoveValue={onRemoveElastomer}
                    addResetDoneEventListener={addResetDoneEventListener}
                    removeResetDoneEventListener={removeResetDoneEventListener}
                />
            }
            { Utilities.isValidObj(allAirVaporEliminators) && 
                <FieldGroup
                    title={"Air/Vapor Eliminator"}
                    isRadio={false}
                    allItems={allAirVaporEliminators}
                    availableItems={availableAirVaporEliminators}
                    selectedItems={selectedAirVaporEliminators}
                    onAddValue={onAddAirVaporEliminator}
                    onRemoveValue={onRemoveAirVaporEliminator}
                    addResetDoneEventListener={addResetDoneEventListener}
                    removeResetDoneEventListener={removeResetDoneEventListener}
                />
            }
            { Utilities.isValidObj(allStrainers) && 
                <FieldGroup
                    title={"Strainer"}
                    isRadio={false}
                    allItems={allStrainers}
                    availableItems={availableStrainers}
                    selectedItems={selectedStrainers}
                    onAddValue={onAddStrainer}
                    onRemoveValue={onRemoveStrainer}
                    addResetDoneEventListener={addResetDoneEventListener}
                    removeResetDoneEventListener={removeResetDoneEventListener}
                />
            }
        </View>
    );
}

export default Facets;