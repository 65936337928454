class Filter {

    value = 0;
    name = "";

    constructor(
        value,
        name 
    ) {
        this.value = value;
        this.name = name;
    }
};

export default Filter;