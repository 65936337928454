import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    headerButton: {
        backgroundColor: "#000",
        borderColor: "#000",
        borderRadius: 0,
        borderWidth: 0,
        color: "#fff",
        margin: 3,
    },
    headerButtonText: {
        color: "#fff",
    },
});