import UrlService from './urlService';

const PrivateApiService = {
    sendRequest: function(requestInfo, absoluteUrl, abortController) {  // TODO:  how can I make this function private so only this obj can access it?  I ran into an issue trying to call it directly.

        if (abortController !== null && typeof abortControllerabortController !== "undefined")
            requestInfo.signal = abortController.signal;

        let promise = fetch(absoluteUrl, requestInfo);
        return promise;
    },
    sendJsonRequest: function(httpVerb, url, body, abortController) {

        var requestInfo = {
            "method": httpVerb,
            "headers": {
                "Content-Type": "application/json"
            }
        };

        if (httpVerb !== 'GET' && body !== null && typeof body === 'object')
            requestInfo["body"] = JSON.stringify(body);

        return this.sendRequest(requestInfo, url, abortController);
    },
    sendMultipartFormDataRequest: function(url, body, abortController) {

        var requestInfo = {
            "method": "POST",
            "headers": {
                //"Content-Type": "multipart/form-data"     // IMPORTANT:  Do not include this b/c the system will insert it automatically w/ its boundary.
            },
            "body": body
        };

        return this.sendRequest(requestInfo, url, abortController);
    },
    sendAuthenicatedJsonRequest: async function(httpVerb, token, url, body) {

        var requestInfo = {
            "method": httpVerb,
            "headers": {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            }
        };

        if (httpVerb !== 'GET' && body !== null && typeof body === 'object')
            requestInfo["body"] = JSON.stringify(body);

        let result = await this.sendRequest(requestInfo, url)
        return result;
    },
    sendAuthenicatedMultipartFormDataRequest: async function(token, url, body) {

        var requestInfo = {
            "method": "POST",
            "headers": {
                "Authorization": `Bearer ${token}`,
                //"Content-Type": "multipart/form-data"     // IMPORTANT:  Do not include this b/c the system will insert it automatically w/ its boundary.
            },
            "body": body
        };

        let result = await this.sendRequest(requestInfo, url);
        return result;
    },
};

const ApiService = {
    signIn: async function(dto) {

        var requestInfo = {
            "method": "POST",
            "headers": {
                "Content-Type": "application/json"
            },
            "body": JSON.stringify(dto)
        };

        var response = await PrivateApiService.sendRequest(requestInfo, UrlService.loginUrl());
        if (!response.ok) {
            var errMsg = await response.text();
            if (errMsg === null || typeof errMsg === "undefined" || errMsg.length < 1)
                errMsg = "Sign in failed.  Please try again.";

            throw new Error(errMsg);
        }

        var result = await response.json();

        return result.token;
    },
    signOut: async function(token, abortController) {

        var response = await PrivateApiService.sendAuthenicatedJsonRequest("GET", token, UrlService.logoutUrl(), null, abortController);
        if (!response.ok)
            throw new Error("Sign out failed.  Please try again.");

        return Promise.resolve(true);
    },
    refreshToken: async function(tokenData) {

        return Promise.reject(false);

        /* #dlb:  Do we need a refresh token mechanism?
        if (typeof tokenData.accessToken === "undefined" || tokenData.accessToken === null || tokenData.accessToken === "")
            return Promise.reject(false);

       var renewalTokenDto = new RenewalTokenDto(tokenData.renewalToken);

        var requestInfo = {
            "method": "POST",
            "headers": {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenData.accessToken}`
            },
            "body": JSON.stringify(renewalTokenDto)
        };

        var response = await PrivateApiService.sendRequest(requestInfo, UrlService.refreshTokenUrl());

        var result = response.json();
        return result;
        */
    },
    register: async function(dto) {

        var requestInfo = {
            "method": "POST",
            "headers": {
                "Content-Type": "application/json"
            },
            "body": JSON.stringify(dto)
        };

        var response = await PrivateApiService.sendRequest(requestInfo, UrlService.registerUrl());

        // NOTE: we should be getting a 201 Created response if it worked (which is also considered "response.ok")
        if (!response.ok) {
            let result = await response.json();
            let errors = "\r\n\t* ";
            for (let idx = 0; idx < result.errors.length; idx++) {
                if (idx > 0)
                    errors += "\r\n\t* "

                errors += result.errors[idx].description
            }

            throw new Error("Registration failed.  Please try again. Error messages are below: " + errors);
        }

        return Promise.resolve();
    },
    resetPassword: async function(dto, abortController) {
        var requestInfo = {
            "method": "POST",
            "headers": {
                "Content-Type": "application/json"
            },
            "body": JSON.stringify(dto)
        };

        var response = await PrivateApiService.sendRequest(requestInfo, UrlService.resetPasswordUrl(), abortController);

        if (!response.ok) {
            let result = await response.json();
            let errors = "\r\n\t* ";
            for (let idx = 0; idx < result.errors.length; idx++) {
                if (idx > 0)
                    errors += "\r\n\t* "

                errors += result.errors[idx].description
            }

            throw new Error("Reset password failed.  Please try again. Error messages are below: " + errors);
        }

        return Promise.resolve();
    },
    emailConfirmed: async function(confirmEmailDto) {

        var requestInfo = {
            "method": "PUT",
            "headers": {
                "Content-Type": "application/json"
            },
            "body": JSON.stringify(confirmEmailDto)
        };

        var response = await PrivateApiService.sendRequest(requestInfo, UrlService.emailConfirmedUrl());
        if (!response.ok)
            throw new Error("Email confirmation failed. Please try again.");

        return Promise.resolve(true);
    },
    loadOptions: async function(token, abortController) {

        var response = await PrivateApiService.sendAuthenicatedJsonRequest("GET", token, UrlService.searchUrl(), null, abortController);
        if (!response.ok)
            throw new Error("Failed to load options.  Please reach out to customer support for help.");

        var result = await response.json();
        return result;
    },
    search: async function(token, searchDto, abortController) {

        var response = await PrivateApiService.sendAuthenicatedJsonRequest("POST", token, UrlService.searchUrl(), searchDto, abortController);
        if (!response.ok)
            throw new Error("Failed to load options.  Please reach out to customer support for help.");

        var result = await response.json();
        return result;
    },
    loadPartOptions: async function(token, abortController) {

        var response = await PrivateApiService.sendAuthenicatedJsonRequest("GET", token, UrlService.searchPartUrl(), null, abortController);
        if (!response.ok)
            throw new Error("Failed to load part options.  Please reach out to customer support for help.");

        var result = await response.json();
        return result;
    },
    partSearch: async function(token, searchDto, abortController) {

        var url = UrlService.searchPartUrl() + "?keyword=" + searchDto.keyword + "&pageIndex=" + searchDto.paging.currentPage.toString() + "&pageSize=" + searchDto.paging.pageSize.toString();
        var response = await PrivateApiService.sendAuthenicatedJsonRequest("POST", token, url, searchDto, abortController);
        if (!response.ok)
            throw new Error("Failed to load parts.  Please reach out to customer support for help.");

        var result = await response.json();
        return result;
    }
};

export default ApiService;