import React, { createContext, useContext, useEffect, useState } from 'react';

import Globals from '../utilities/globals';
import Utilities from '../utilities/utilities';


const ProductSelectorPaginationContext = createContext();

const useProductSelectorPaginationConsumer = function() {
    const context = useContext(ProductSelectorPaginationContext);
    if (!context)
        throw new Error('useProductSelectorPaginationConsumer must be used within a ProductSelectorPaginationProvider');

    return context;
}

const ProductSelectorPaginationProvider = ({children}) => {

    const [numOfPages, setNumOfPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalNumOfItems, setTotalNumOfItems] = useState(0);

    //const defaultPageSize = DeviceInfo.isPhone() ? Globals.default.small.pageSize : Globals.default.large.pageSize;
    const defaultPageSize = Globals.default.large.pageSize;
    const [numOfItemsPerPage, setNumOfItemsPerPage] = useState(defaultPageSize);
    const [numOfItemsReturned, setNumOfItemsReturned] = useState(defaultPageSize);


    const resetPaging = () => {
        setCurrentPage(1);
        setTotalNumOfItems(0);
        setNumOfItemsReturned(0);
    };


    const calcNumOfPages = () =>
    {
        return Utilities.calcNumOfPages(totalNumOfItems, numOfItemsPerPage);
    };


    useEffect(() => {

        setNumOfPages(calcNumOfPages());
    }, [
        numOfItemsPerPage,
        totalNumOfItems
    ]);


    return (
        <ProductSelectorPaginationContext.Provider 
            value={
                {   // corresponds to our own properties and functions within this object
                    resetPaging,
                    numOfPages,
                    currentPage,
                    totalNumOfItems,
                    numOfItemsPerPage,
                    setCurrentPage,
                    setTotalNumOfItems,
                    setNumOfItemsPerPage
                }
            }
        >
            {children}
        </ProductSelectorPaginationContext.Provider>
    );
};

export { ProductSelectorPaginationContext, ProductSelectorPaginationProvider, useProductSelectorPaginationConsumer };
