import { Platform } from 'react-native';
import * as Device from 'expo-device';
import Utiltiies from './utilities';
import Logger from './logger';

const DeviceInfo = {
    getType: () => {

        if (!Utiltiies.isValidObj(Device))
            throw new Error("Device is not defined.");

        return Device.getDeviceTypeAsync();
    },
    logEnvVars: async () => {

        //Logger.startFunc("  (a) DeviceInfo.logEnvVars");

        //Logger.log("  (a) Device type is: " + (typeof Device));
        //Logger.log("  (a) isDevice : " + Device.isDevice);  // always true for web

        let type = await Device.getDeviceTypeAsync();
        //let isPhone = (type === Device.DeviceType.PHONE);
        //let isTablet = (type === Device.DeviceType.TABLET);
        //let isDesktop = (type === Device.DeviceType.DESKTOP);
        //Logger.log("  (a) DeviceType: " + type);
        //Logger.log("  (a) Is desktop: " + isDesktop);
        //Logger.log("  (a) Is phone: " + isPhone);
        //Logger.log("  (a) Is tablet: " + isTablet);

        //Logger.endFunc("  (a) DeviceInfo.logEnvVars");
        return type;
    },
    isPhone: async (key) => {

        //Logger.startFunc("DeviceInfo.isPhone");

        let type = await DeviceInfo.getType();

        //Logger.endFunc("DeviceInfo.isPhone");

        return (type === Device.DeviceType.PHONE);
    },
    isTablet: async (key) => {

        //Logger.startFunc("DeviceInfo.isTablet");

        let type = await DeviceInfo.getType();

        //Logger.endFunc("DeviceInfo.isTablet");

        return (type === Device.DeviceType.TABLET);
    },
    isDesktop: async (key, value) => {

        //Logger.startFunc("DeviceInfo.isDesktop");

        let type = await DeviceInfo.getType();

        //Logger.endFunc("DeviceInfo.isDesktop");

        return (type === Device.DeviceType.DESKTOP);
    },
    isNotPhysicalDevice: async () => {
        //Logger.startFunc("  (a) DeviceInfo.isNotPhysicalDevice");

        let isPhone = false;
        let isTablet = false;

        let type = await DeviceInfo.getType();
        isPhone = (type === Device.DeviceType.PHONE);
        isTablet = (type === Device.DeviceType.TABLET);

        //Logger.endFunc("  (a) DeviceInfo.isNotPhysicalDevice");

        return (!isPhone && !isTablet);
    },
    isAppleDevice: (checkPlatform) => {
        Logger.startFunc("  (a) DeviceInfo.isAppleDevice");

        let isAppleDevice = false;

        try {

            if (checkPlatform) {
                if (typeof Platform !== "undefined" && typeof Platform.OS !== "undefined") {
                    // NOTE:  This should work for iPad too; but only when its installed as a native app from the app store!
                    isAppleDevice = (Platform.OS === 'ios');
                } else
                    checkPlatform = false;
            }
        } catch (err)
        {
            checkPlatform = false;
        }

        try {
            if (!checkPlatform) {
                // fallback to checking the user-agent of the browser
                const userAgent = window.navigator.userAgent.toLowerCase();
                isAppleDevice = /iphone|ipad|ipod/.test(userAgent);
            }
        }
        catch (err) {}

        Logger.log("    isAppleDevice : " + isAppleDevice);

        Logger.endFunc("  (a) DeviceInfo.isAppleDevice");

        return isAppleDevice;
    },
    isIPhone: async () => {
        Logger.startFunc("  DeviceInfo.isIPhone");

        let type = await DeviceInfo.getType();
        let isIPhone = (DeviceInfo.isAppleDevice() && type.osName === "iOS");
        Logger.log("    isIPhone : " + isIPhone);

        Logger.endFunc("  DeviceInfo.isIPhone");

        return (isIPhone);
    },
    isIPad: async () => {
        Logger.startFunc("  DeviceInfo.isIPad");

        let type = await DeviceInfo.getType();
        let isIPad = (DeviceInfo.isAppleDevice() && type.osName === "iPadOS");
        Logger.log("    isIPad : " + isIPad);

        Logger.endFunc("  DeviceInfo.isIPad");

        return (isIPad);
    },
 };

export default DeviceInfo;