import React from 'react';
import { TouchableOpacity, Text } from 'react-native';
import GenericIcon from '../icons/genericIcon';
import Utilities from '../../utilities/utilities';

import styles1 from '../../styles/buttons/genericButton';
import styles2 from '../../styles/buttons/headerButton';

const HeaderButton = (props) => {

    const renderIcon = () => {
        return (
            <GenericIcon style={[styles1.icon, styles2.icon]} iconName={props.icon.name} color={"white"}/>
        );
    };

    return (
        <TouchableOpacity
            style={[styles1.genericButton, styles2.headerButton]}
            onPress={() => props.onPress(props.payload)}
        >
            <Text
                style={[styles1.genericButtonText, styles2.headerButtonText]}
            >
                {Utilities.isValidObj(props.icon) && props.icon.position == "left" && renderIcon()}

                {props.title}

                {Utilities.isValidObj(props.icon) && props.icon.position == "right" && renderIcon()}
            </Text>
        </TouchableOpacity>
    );
}

export default HeaderButton;