import React, { useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useAuth } from '../contexts/authContext';
import { BadgeProvider } from '../contexts/badgeContext';
import Utilities from '../utilities/utilities';
 
import { PartNumberPaginationProvider } from '../contexts/partNumberPaginationContext';
import { PartNumberRequiredFiltersProvider } from '../contexts/partNumberRequiredFiltersContext';
import { PartNumberSearchResultsProvider } from '../contexts/partNumberSearchResultsContext';

import { ProductSelectorPaginationProvider } from '../contexts/productSelectorPaginationContext';
import { ProductSelectorRequiredFiltersProvider } from '../contexts/productSelectorRequiredFiltersContext';
import { ProductSelectorOptionalFiltersProvider } from '../contexts/productSelectorOptionalFiltersContext';
import { ProductSelectorSearchResultsProvider } from '../contexts/productSelectorSearchResultsContext';

import ScreenEnum from '../enums/screenEnum';

import SplashScreen from '../controls/splashScreen';
import DrawerScreen from '../screens/drawer';
import LoginScreen from '../screens/login';
import RegistrationScreen from '../screens/registration';
import ForgotPasswordScreen from '../screens/forgotPassword';
import PostLoginLandingScreen from '../screens/postLoginLanding';
import ProductSelectorLanding from '../screens/productSelectorLanding';
import ProductSelectorSearch from '../screens/productSelectorSearch';
import ProductSelectorDetails from '../screens/productSelectorDetails';
import PartNumberLanding from '../screens/partNumberLanding';
import PartNumberSearch from '../screens/partNumberSearch';
import PartNumberDetails from '../screens/partNumberDetails';

import styles from '../styles/pages';

const Router = () => {

    const { accessToken } = useAuth();

    const [isReady, setIsReady] = useState(false);

    const Stack = createNativeStackNavigator(); 

    const useDrawer = () => { return false; }  // TODO: set this based on the target os (e.g., true if mobile)
    const Drawer = createDrawerNavigator();
    function DrawerView() {
        return (
            <Drawer.Navigator
                id="drawerRoot"
                screenOptions={{ headerShown: true }}
                drawerContent={(props) => <DrawerScreen {...props} />}
            >
                <Drawer.Screen name={ScreenEnum.ProductSelectorSearch.key} component={ProductSelectorSearch} />
            </Drawer.Navigator>
        );
    }


    const renderPartPages = () => {
        return (
        <>
            <Stack.Screen name={ScreenEnum.PartNumberLanding.key} component={PartNumberLanding} options={{ title: ScreenEnum.PartNumberLanding.key }} />
            <Stack.Screen name={ScreenEnum.PartNumberSearch.key} component={PartNumberSearch} options={{ title: ScreenEnum.PartNumberSearch.key }} />
            <Stack.Screen name={ScreenEnum.PartNumberDetails.key} component={PartNumberDetails} options={{ title: ScreenEnum.PartNumberDetails.key }} />
        </>
        );
    }


    const renderProductPages = () => {
        return (
        <>
            <Stack.Screen name={ScreenEnum.ProductSelectorLanding.key} component={ProductSelectorLanding} options={{ title: ScreenEnum.ProductSelectorLanding.key }} />
            {(useDrawer()) && 
                <Stack.Screen name={ScreenEnum.Drawer.key} component={DrawerView} options={{ title: ScreenEnum.Drawer.key }} />
            }
            {(!useDrawer()) &&
                <Stack.Screen name={ScreenEnum.ProductSelectorSearch.key} component={ProductSelectorSearch} options={{ title: ScreenEnum.ProductSelectorSearch.key }} />
            }
            <Stack.Screen name={ScreenEnum.ProductSelectorDetails.key} component={ProductSelectorDetails} options={{ title: ScreenEnum.ProductSelectorDetails.key }} />
        </>
        );
    }

    const renderNav = () =>
    {
        return (
            <>
                <NavigationContainer>
                    <Stack.Navigator 
                        initialRouteName={ScreenEnum.Login.key}
                        screenOptions={{ headerShown: false }}
                    >
                        <Stack.Screen name={ScreenEnum.Login.key} component={LoginScreen} options={{ title: ScreenEnum.Login.key }} />
                        <Stack.Screen name={ScreenEnum.Registration.key} component={RegistrationScreen} options={{ title: ScreenEnum.Registration.key }} />
                        <Stack.Screen name={ScreenEnum.ForgotPassword.key} component={ForgotPasswordScreen} options={{ title: ScreenEnum.ForgotPassword.key }} />

                        <Stack.Screen name={ScreenEnum.PostLoginLanding.key} component={PostLoginLandingScreen} options={{ title: ScreenEnum.PostLoginLanding.key }} />

                        {renderProductPages()}

                        {renderPartPages()}
                    </Stack.Navigator>
                </NavigationContainer>
            </>
        );
    };


    return (
        <>
            {(!isReady) && 
                <SplashScreen 
                    setIsReady={setIsReady} 
                />
            }
            {(isReady) && 
                <BadgeProvider>

                <ProductSelectorPaginationProvider>
                <ProductSelectorRequiredFiltersProvider>
                <ProductSelectorOptionalFiltersProvider>
                <ProductSelectorSearchResultsProvider>

                <PartNumberPaginationProvider>
                <PartNumberRequiredFiltersProvider>
                <PartNumberSearchResultsProvider>

                    {renderNav()}

                </PartNumberSearchResultsProvider>
                </PartNumberRequiredFiltersProvider>
                </PartNumberPaginationProvider>

                </ProductSelectorSearchResultsProvider>
                </ProductSelectorOptionalFiltersProvider>
                </ProductSelectorRequiredFiltersProvider>
                </ProductSelectorPaginationProvider>

                </BadgeProvider>
            }
        </>
    );
};

export default Router;