import React from 'react';
import GenericIcon from './genericIcon';
import IconEnum from '../../enums/iconEnum';

const HamburgerIcon = (props) => {

    const additionalStyles = {
        fontSize: '32px',
        textAlign: 'center',
      };

      const combinedStyle = {
        ...props.style,
        ...additionalStyles,
      };

    return (
        <GenericIcon color={props.color} style={combinedStyle} iconName={IconEnum.Hamburger.name} />
    );
}

export default HamburgerIcon;