import React, { useState } from 'react';
import { Picker, Text, View } from 'react-native';
import Globals from '../../utilities/globals';

import styles from '../../styles/pagination/itemsPerPagePicker';

const ItemsPerPagePicker = (props) => {

	const [availablePageSizes, setAvailablePageSizes] = useState([Globals.default.small.pageSize, Globals.default.large.pageSize]);

    const renderNextPageSizeItem = (nextPageSize) => {
        return (
            <Picker.Item 
                key={nextPageSize}
                label={nextPageSize} 
                value={nextPageSize}
            />
        );
    };

	return (
        <View style={styles.itemsPerPageContainer}>
            <Text style={styles.label} >Items per page:</Text>
            <Picker
                style={styles.itemsPerPagePicker}
                selectedValue={props.numOfItemsPerPage}
                onValueChange={(item, index) => props.setNumOfItemsPerPage(item)}
            >
                {(availablePageSizes.length > 0) && 
                    availablePageSizes.map((item, index) =>  {
                        return renderNextPageSizeItem(item);
                })}
            </Picker>
        </View>
    );
};

export default ItemsPerPagePicker;