class LoginResult {
    accessToken = "";
    displayName = "";

    constructor(accessToken, displayName) {
        this.accessToken = accessToken;
        this.displayName = displayName;
    }
};

export default LoginResult;