import React, { useEffect, useState } from 'react';
import { Text } from 'react-native';
import Utils from '../../utilities/utilities';

import styles from '../../styles/pagination/statusInfo';

const StatusInfo = (props) => {

    const currentPage = props.currentPage;
    const numOfItemsPerPage = props.numOfItemsPerPage;
    const totalNumOfItems = props.totalNumOfItems;

    const start = Math.min((numOfItemsPerPage * (currentPage-1)) + 1, totalNumOfItems);
    const end = Math.min(numOfItemsPerPage * currentPage, totalNumOfItems);
	const total = totalNumOfItems;

    const fmtStart = Utils.formatNumber(start);
    const fmtEnd = Utils.formatNumber(end);
	const fmtTotal = Utils.formatNumber(total);

	return (
		<Text style={styles.itemsPerPageStatusInfo}>
			Showing {fmtStart}-{fmtEnd} of {fmtTotal}
		</Text>
	);
};

export default StatusInfo;