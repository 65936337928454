import React, { useState, useEffect } from 'react';
import { View, Dimensions } from 'react-native';
import SearchInput from '../../controls/textboxes/searchInput';
import Logger from '../../utilities/logger';
import { usePartNumberSearchResultsConsumer } from '../../contexts/partNumberSearchResultsContext';

import styles from '../../styles/screens/components/facets';

const Facets = () => {
    const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);

    const isMobileScreen = (screenWidth <= 575);

  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(Dimensions.get('window').width);
    };

    Dimensions.addEventListener('change', updateScreenWidth);

    return () => {
      Dimensions.removeEventListener('change', updateScreenWidth);
    };
  }, []);

    const {
        setOnSearchTrigger,
        keyword,
        setKeyword,
        onKeywordChange,
        hints,
        onHintSelected
    } = usePartNumberSearchResultsConsumer();

    const onSearchHandler = () => {
        //Logger.startFunc('  partFacets.onSearchHandler');

        setOnSearchTrigger(true);

        //Logger.endFunc('  partFacets.onSearchKeyword');
    };

    return (
    <View style={isMobileScreen ? '' : styles.facetsSidebar}>
            <View style={styles.facetsSearchInputWrap}>
                <SearchInput
                    keyword={keyword}
                    setKeyword={setKeyword}
                    onKeywordChange={onKeywordChange}
                    hints={hints}
                    onHintSelected={onHintSelected}
                    onSearchBtn={onSearchHandler}
                />
            </View>
        </View>
    );
}

export default Facets;