import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    wrapper: {
        flex: 1,
        flexDirection: "row",
        marginLeft: 4,
        marginTop: 4,
    },
    logo: {
        flex: 1,
        resizeMode: "contain",
    },
    //largeImage: {
        //height: 400,
        //maxWidth: '100%',
        //width: '100%',
        //paddingTop: '60%',
        //height: '0',
        //borderWidth: 1,
        //borderColor: '#bfbfbf',
    //}
});