import React, { createContext, useEffect, useState, useContext } from 'react';
import Logger from '../utilities/logger';
import Utils from '../utilities/utilities';

const ProductSelectorRequiredFiltersContext = createContext();

const useProductSelectorRequiredFiltersConsumer = function() {
    const context = useContext(ProductSelectorRequiredFiltersContext);
    if (!context)
        throw new Error('useProductSelectorRequiredFiltersConsumer must be used within a ProductSelectorRequiredFiltersProvider');

    return context;
}

const ProductSelectorRequiredFiltersProvider = ({children}) => {

    const [keywordTimeoutId, setKeywordTimeoutId] = useState(null);

    const [allFluids, setAllFluids] = useState([]);
    const [allFlowRates, setAllFlowRates] = useState([]);
    const [allAccessoryPackages, setAllAccessoryPackages] = useState([]);
    const [allRegistrations, setAllRegistrations] = useState([]);

    const [availableFluids, setAvailableFluids] = useState([]);
    const [availableFlowRates, setAvailableFlowRates] = useState([]);
    const [availableAccessoryPackages, setAvailableAccessoryPackages] = useState([]);
    const [availableRegistrations, setAvailableRegistrations] = useState([]);

    const [selectedFluid, setSelectedFluid] = useState(-1);
    const [selectedFlowRate, setSelectedFlowRate] = useState(-1);
    const [selectedAccessoryPackage, setSelectedAccessoryPackage] = useState(-1);
    const [selectedRegistration, setSelectedRegistration] = useState(-1);

    const [onSearchTrigger, setOnSearchTrigger] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [hints, setHints] = useState([]);
    
    const resetRequiredFilters = () => {

        setAvailableFluids(Utils.convertKeysToIntArray(allFluids, true));
        setAvailableFlowRates(Utils.convertKeysToIntArray(allFlowRates, true));
        setAvailableAccessoryPackages(Utils.convertKeysToIntArray(allAccessoryPackages, true));
        setAvailableRegistrations(Utils.convertKeysToIntArray(allRegistrations, true));

        setSelectedFluid(-1);
        setSelectedFlowRate(-1);
        setSelectedAccessoryPackage(-1);
        setSelectedRegistration(-1);

        setHints([]);
        setOnSearchTrigger(false);
        setKeyword("");
    };


    var onSearchKeyword = () => {
        setOnSearchTrigger(true);
    };


    const stopKeywordTimer = () => {
        //Logger.startFunc('  stopKeywordTimer');

        //Logger.log('      <<< keywordTimeoutId: ' + keywordTimeoutId);
        if (keywordTimeoutId !== null)
            clearTimeout(keywordTimeoutId);
        setKeywordTimeoutId(null);

        //Logger.endFunc('  stopKeywordTimer');
    };


    const startKeywordTimer = (forceIt) => {

        //Logger.startFunc('  startKeywordTimer');

        stopKeywordTimer();

        if (forceIt === true || (Utils.isValidObj(keyword) && keyword.length > 2)) {

            var timeoutId = setTimeout(onSearchKeyword, 3000);
            setKeywordTimeoutId(timeoutId);
            //Logger.log('      >>> keywordTimeoutId: ' + timeoutId);

            //Logger.endFunc('  startKeywordTimer (started)');
        }
        else {
            //Logger.endFunc('  startKeywordTimer (nothing to do)');
        }
    }

    const onFluidChanged = (item) => {
        Utils.setSelectedItem(setSelectedFluid, item);
    };

    const onFlowRateChanged = (item) => {
        Utils.setSelectedItem(setSelectedFlowRate, item);
    };

    const onAccessoryPackageChanged = (item) => {
        Utils.setSelectedItem(setSelectedAccessoryPackage, item);
    };

    const onRegistrationChanged = (item) => {
        Utils.setSelectedItem(setSelectedRegistration, item);
    };

    const onHintSelected = (selectedKeyword) => {

        setKeyword(selectedKeyword);
        setHints([]);
    };


    const unmountedCleanup = () => {
        //Logger.startFunc("productSelectorRequiredFiltersContext.unmountedCleanup");
        //Logger.log("  *** abort any async calls ***");
        
        //Logger.log("  *** stopping keyword timer ***");
        stopKeywordTimer();

        //Logger.endFunc("productSelectorRequiredFiltersContext.unmountedCleanup");
    };

        
    useEffect(() => {

        return () => {
            unmountedCleanup();
        }
    }, []);


    useEffect(() => {

        //Logger.log("useEffect.requiredFilters.keyword was hit!");

        startKeywordTimer();
    }, [
        keyword,
    ]);


    useEffect(() => {

        //Logger.startFunc("partNumberRequiredFiltersContext.onSearchTrigger");

        stopKeywordTimer();

        //Logger.endFunc("partNumberRequiredFiltersContext.onSearchTrigger");
    }, [onSearchTrigger]);


    return (
        <ProductSelectorRequiredFiltersContext.Provider 
            value={
                {   // corresponds to our own properties and functions within this object
                    resetRequiredFilters,
                    allFluids,
                    allFlowRates,
                    allAccessoryPackages,
                    allRegistrations,
                    setAllFluids,
                    setAllFlowRates,
                    setAllAccessoryPackages,
                    setAllRegistrations,
                    availableFluids,
                    availableFlowRates,
                    availableAccessoryPackages,
                    availableRegistrations,
                    setAvailableFluids,
                    setAvailableFlowRates,
                    setAvailableAccessoryPackages,
                    setAvailableRegistrations,
                    selectedFluid,
                    selectedFlowRate,
                    selectedAccessoryPackage,
                    selectedRegistration,
                    setSelectedFluid,
                    setSelectedFlowRate,
                    setSelectedAccessoryPackage,
                    setSelectedRegistration,
                    onFluidChanged,
                    onFlowRateChanged,
                    onAccessoryPackageChanged,
                    onRegistrationChanged,
                    onSearchTrigger,
                    setOnSearchTrigger,
                    keyword,
                    setKeyword,
                    hints,
                    setHints,
                    onHintSelected,
                }
            }
        >
            {children}
        </ProductSelectorRequiredFiltersContext.Provider>
    );
};

export { ProductSelectorRequiredFiltersContext, ProductSelectorRequiredFiltersProvider, useProductSelectorRequiredFiltersConsumer };
