class BaseJsonConvertError extends Error {
    constructor(message) {
        super(message);
        this.name = 'BaseJsonConvertError';
    }
}

class JsonConvertDeserializationError extends BaseJsonConvertError {
    constructor(message) {
        super(message);
        this.name = 'JsonConvertDeserializationError';
    }
}

class JsonConvertSerializationError extends BaseJsonConvertError {
    constructor(message) {
        super(message);
        this.name = 'JsonConvertSerializationError';
    }
}


export { JsonConvertDeserializationError, JsonConvertSerializationError };