import Utilities from '../utilities/utilities';
import ApiService from './apiService';
import LocalStorageService from './localStorageService';
import LoginDto from '../dtos/loginDto';
import LoginResult from '../dtos/loginResult';
import { AccessTokenHasExpiredError } from '../exceptions/authenticationExceptions';
import { InvalidCacheForAccessTokenError, InvalidCacheForUserCredsError } from '../exceptions/localStorageExceptions';

const PrivateAuthService = {

    ///<throws>
    ///  SecureStorageNotAvailableOnDeviceError
    ///  InvalidArgumentError
    ///  JsonConvertDeserializationError
    ///</throws>
    ///<returns>
    ///  Promise<LoginDto>
    ///</returns>
    getCachedLoginCreds: async function() {

        let cachedObj = null;

        try {
            cachedObj = await LocalStorageService.getLoginCreds()
        } catch (err) {
            throw new InvalidCacheForUserCredsError("User creds are not cached yet.");
        }

        return cachedObj;
    },
    ///<throws>
    ///  SecureStorageNotAvailableOnDeviceError
    ///  InvalidArgumentError
    ///  JsonConvertDeserializationError
    ///  AccessTokenIsNullOrEmptyError
    ///  AccessTokenHasExpiredError
    ///</throws>
    ///<returns>
    ///  Promise<LoginResult>
    ///</returns>
    getCachedLoginResult: async function() {

        let cachedObj = null;

        try {
            cachedObj = await LocalStorageService.getLoginResult()
        } catch (err) {
            throw new InvalidCacheForAccessTokenError("AccessToken is not cached yet.");
        }

        return cachedObj;
    },
    ///<throws>
    ///  SecureStorageNotAvailableOnDeviceError
    ///  InvalidArgumentError
    ///</throws>
    ///<returns>
    ///  LoginResult
    ///</returns>
    signIn: async function(loginDto) {

        await LocalStorageService.setLoginCreds(loginDto);

        let accessTokenHasExpired = false;
        let cachedObj = null;
        try {
            cachedObj = await PrivateAuthService.getCachedLoginResult();
        }
        catch (err) {
            // continue - we may not have it cached yet
            accessTokenHasExpired = (err instanceof AccessTokenHasExpiredError);
        }

        if (!Utilities.isValidObj(cachedObj)) {
            let accessToken = null;

            if (accessTokenHasExpired) {
                try {
                    accessToken = await ApiService.refreshToken(cachedObj);
                }
                catch (err) {
                    // continue - refresh tokens might not be implemented yet
                }
            }

            if (!Utilities.isValidObj(accessToken)) {

                accessToken = await ApiService.signIn(loginDto);

                cachedObj = new LoginResult(accessToken);
                await LocalStorageService.setLoginResult(cachedObj);  // TODO:  can we pull out their display name too?
            }
        }

        return cachedObj;
    },
    signOut: async function (abortController) {
        // returns:  boolean (true if session/cookie/tokens have been reset)

        let cachedObj = await PrivateAuthService.getCachedLoginResult();

        Utilities.checkJwtToken(cachedObj);

        await LocalStorageService.clearLoginResult();

        return await ApiService.signOut(cachedObj.accessToken, abortController);
    },
    register: async function(registerDto) {

        await LocalStorageService.clearLoginResult();

        await LocalStorageService.setLoginCreds(new LoginDto(registerDto.username, registerDto.password, true));

        return await ApiService.register(registerDto);
    },
    emailConfirmed: async function(confirmEmailDto) {

        return await ApiService.emailConfirmed(confirmEmailDto);
    },
    resetPassword: async function(resetPasswordDto, abortController) {

        await LocalStorageService.clearLoginResult();

        return await ApiService.resetPassword(resetPasswordDto, abortController);
    },
};

const AuthService = {
    ///<throws>
    ///  InvalidCacheForUserCredsError
    ///</throws>
    ///<returns>
    ///  Promise<LoginDto>
    ///</returns>
    getCachedLoginCreds: async () => {
        return await PrivateAuthService.getCachedLoginCreds();
    },
    ///<throws>
    ///  InvalidCacheForLoginResultError
    ///</throws>
    ///<returns>
    ///  Promise<LoginDto>
    ///</returns>
    getCachedLoginResult: async () => {
        return await PrivateAuthService.getCachedLoginResult();
    },
    ///<throws>
    ///  InvalidArgumentError
    ///</throws>
    ///<returns>
    ///  Promise<LoginDto>
    ///</returns>
    signIn: async (loginDto) => {
        return await PrivateAuthService.signIn(loginDto);
    },
    signOut: async (abortController) => {
        return await PrivateAuthService.signOut(abortController);
    },
    register: async (registerDto) => {
        return await PrivateAuthService.register(registerDto);
    },
    emailConfirmed: async (confirmEmailDto) => {
        return await PrivateAuthService.emailConfirmed(confirmEmailDto);
    },
    resetPassword: async (resetPasswordDto, abortController) => {
        return await PrivateAuthService.resetPassword(resetPasswordDto, abortController);
    },
};

export default AuthService;