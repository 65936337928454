import React, { useState, useEffect } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import DeviceInfo from '../../utilities/deviceInfo';
import LocalStorageService from '../../services/localStorageService';
import Logger from '../../utilities/logger';
import Utilities from '../../utilities/utilities';

const styles = StyleSheet.create({
    pwaInstallDismissal: {
    },
    shareIcon: {
        width: 20,
        height: 20,
    },
    pwaInstallPromptContainer: {
        //height: 30,
    },
    pwaInstallPromptInstructions: {
        backgroundColor: '#d9dfff',
        color: '#000000',
        fontSize: 16,
        padding: 6,
        textAlign: 'center',
    },
    // pwaInstallPromptContainerForIPad: {
    //     padding: 20,
    //     height: 60,
    // },
    // pwaInstallPromptInstructionsForIPad: {
    //     fontSize: 22,
    // },
});

const PwaInstallPrompt = () => {

  const [showPrompt, setShowPrompt] = useState(false);
  //const isIpad = DeviceInfo.isIPad();

  useEffect(() => {

    if (!DeviceInfo.isAppleDevice())
        return;

    (async () => {
        let wasShownPrompt = await LocalStorageService.getPwaInstallPromptWasShown();
        setShowPrompt(!wasShownPrompt);
    })();
  }, []);

  const closePopup = async () => {

    await LocalStorageService.setPwaInstallPromptWasShown(true);
    setShowPrompt(false);
  };

  return (
    <> 
        {showPrompt &&
            <TouchableOpacity
                style={styles.pwaInstallDismissal}
                onPress={closePopup}
            >
                <View 
                    style={styles.pwaInstallPromptContainer}
                    //style={isIpad ? [styles.pwaInstallPromptContainer, styles.pwaInstallPromptContainerForIPad] : styles.pwaInstallPromptContainerForIPad}
                >  
                    <Text
                        style={styles.pwaInstallPromptInstructions}
                        //style={isIpad ? [styles.pwaInstallPromptInstructions, styles.pwaInstallPromptInstructionsForIPad] : styles.pwaInstallPromptInstructions}
                    >
                        { "To install this app on your home screen, tap the " }
                        <Image 
                            source={require("../../assets/share-icon-ios_20x20.png")}
                            style={styles.shareIcon}
                        />
                        { " share icon and select \"Add to Home Screen.\"  Click to dismiss this message." }
                    </Text>
                </View>
            </TouchableOpacity>
        }
    </>
  );
}

export default PwaInstallPrompt;
