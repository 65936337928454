class RNPickerSelectItem {

    value = "";
    label = "";

    constructor(
        value,
        label 
    ) {
        this.value = value;
        this.label = label;
    }
};

export default RNPickerSelectItem;