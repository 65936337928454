class IconObj {
    id = -1;
    name = "";

    constructor(id, name) {
        this.id = id;
        this.name = name;
    }
};

const IconEnum = {
    Search: new IconObj(0, "fa-solid fa-magnifying-glass"),
    Checkbox: new IconObj(1, "fa-regular fa-square"),
    CheckboxSelected: new IconObj(2, "fa-regular fa-square-check"),
    Radio: new IconObj(3, "fa-regular fa-circle"),
    RadioSelected: new IconObj(4, "fa-regular fa-circle-check"),
    CollapsableOpened: new IconObj(5, "fa-solid fa-caret-down"),
    CollapsableClosed: new IconObj(6, "fa-solid fa-caret-right"),
    PageFirst: new IconObj(7, "fa-solid fa-angles-left"),
    PagePrevious: new IconObj(8, "fa-solid fa-angle-left"),
    PageNext: new IconObj(9, "fa-solid fa-angle-right"),
    PageLast: new IconObj(10, "fa-solid fa-angles-right"),
    RemoveFilters: new IconObj(11, "fa-solid fa-filter-circle-xmark"),
    Signin: new IconObj(12, "fa-solid fa-sign-in-alt"),
    Signout: new IconObj(13, "fa-solid fa-sign-out-alt"),
    Home: new IconObj(14, "fa-solid fa-home"),
    Hamburger: new IconObj(15, "fa-solid fa-bars"),
};

export default IconEnum;