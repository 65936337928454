import React, { useEffect, useState } from 'react';
import { Text } from 'react-native';
import { TouchableOpacity } from 'react-native';
import RadioIcon from '../icons/radioIcon';
import RadioSelectedIcon from '../icons/radioSelectedIcon';
import Logger from '../../utilities/logger';

import styles from '../../styles/checkboxes/radioField';

const RadioField = (props) => {
    
    const isDisabled = props.isDisabled;
    const isSelected = props.isSelected;
    const label = props.label;
    const value = props.value;
    const onSetValue = props.onSetValue;

    const myStyle = isDisabled ? styles.disabled : styles.enabled;

    const [checked, setChecked] = useState(isSelected);

    const onChanged = () => {
        //Logger.startFunc("radioField.onChanged");

        if (!isDisabled) {
            var toggled = !checked;
            setChecked(toggled);

            onSetValue(toggled ? value : -1);
        }

        //Logger.endFunc("radioField.onChanged | isDisabled: " + isDisabled);
    };

    const renderDeselected = () => {
        return (
            <RadioIcon style={[styles.icon]} />
        );
    };

    const renderSelected = () => {
        return (
            <RadioSelectedIcon style={[styles.icon]} />
        );
    };

    return (
        <TouchableOpacity 
            style={[myStyle, styles.field]}
            onPress={onChanged}
        >
            {checked && (renderSelected())}
            {!checked && (renderDeselected())}
            <Text
                style={styles.label}
            >{label}
            </Text>
        </TouchableOpacity>
    );
}

export default RadioField;