import React from 'react';
import { TouchableOpacity, Text } from 'react-native';
import GenericIcon from '../icons/genericIcon';
import Utilities from '../../utilities/utilities';

import styles1 from '../../styles/buttons/genericButton';
import styles2 from '../../styles/buttons/primaryButton';

const PrimaryButton = (props) => {

    const renderIcon = () => {
        return (
            <GenericIcon style={[styles1.icon, styles2.icon]} iconName={props.icon.name} />
        );
    };

    return (
        <TouchableOpacity
            style={[styles1.genericButton, styles2.primaryButton]}
            onPress={() => props.onPress(props.payload)}
        >
            <Text
                style={[styles1.genericButtonText, styles2.primaryButtonText]}
            >
                {Utilities.isValidObj(props.icon) && props.icon.position == "left" && renderIcon()}

                {props.title}

                {Utilities.isValidObj(props.icon) && props.icon.position == "right" && renderIcon()}
            </Text>
        </TouchableOpacity>
    );
}

export default PrimaryButton;