import React from 'react';
import { Text, View } from 'react-native';
import { SafeAreaView, ScrollView } from 'react-native';
import Header from '../controls/header';
import Badge from '../controls/badges/badge';
import RequiredPartFilters from './components/requiredPartFilters';

import styles from '../styles/screens/landing';

 
const PartNumberLanding = ({ navigation, route }) => {

    return (
        <SafeAreaView style={styles.safeArea}>
            <Header />
            <Badge />
            <ScrollView style={styles.scrollContainer}>
                <View style={styles.landingContainer}>
                    <Text style={styles.landingHeader}> 
                        {"LIQUID CONTROLS PART AND ACCESSORY SELECTOR"}
                    </Text>
                    <View style={styles.textRow}>
                        <Text style={styles.textLeft}>
                            {"Look for a part number using the control below"}
                        </Text>
                        <Text style={styles.textRight}>
                            {"The Liquid Controls Parts and Accessory Selector lets you easily find the part and accessories for your product."}
                        </Text>
                    </View>
                    <RequiredPartFilters navigation={navigation} />
                    <View style={styles.landingBottom}>
                        <Text style={styles.landingBottom1}>
                            {"For more information on products and applications visit: LCmeter.com"}
                        </Text>
                        <Text style={styles.landingBottom}>
                            {"The use of this tool is for authorized customers, distributors, and channel partners of Liquid Controls only. The content produced herein is confidential, and for authorized use only."}
                        </Text>
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>
    );
}

export default PartNumberLanding;