import React, { createContext, useContext, useEffect, useState } from 'react';

import Globals from '../utilities/globals';
import Utilities from '../utilities/utilities';
import { useLoadingConsumer } from '../contexts/loadingContext';

const PartNumberPaginationContext = createContext();

const usePartNumberPaginationConsumer = function() {
    const context = useContext(PartNumberPaginationContext);
    if (!context)
        throw new Error('usePartNumberPaginationConsumer must be used within a PartNumberPaginationProvider');

    return context;
}

const PartNumberPaginationProvider = ({children}) => {

    const { isLoadingApp } = useLoadingConsumer();

    const [numOfPages, setNumOfPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalNumOfItems, setTotalNumOfItems] = useState(0);

    //const defaultPageSize = DeviceInfo.isPhone() ? Globals.default.small.pageSize : Globals.default.large.pageSize;
    const defaultPageSize = Globals.default.large.pageSize;
    const [numOfItemsPerPage, setNumOfItemsPerPage] = useState(defaultPageSize);
    const [numOfItemsReturned, setNumOfItemsReturned] = useState(defaultPageSize);
  

    const resetPaging = () => {
        setCurrentPage(1);
        setTotalNumOfItems(0);
        setNumOfItemsReturned(0);
    };


    const calcNumOfPages = () =>
    {
        return Utilities.calcNumOfPages(totalNumOfItems, numOfItemsPerPage);
    };


    useEffect(() => {

        if (isLoadingApp)
            return;

        setNumOfPages(calcNumOfPages());
    }, [
        numOfItemsPerPage,
        totalNumOfItems
    ]);


    return (
        <PartNumberPaginationContext.Provider 
            value={
                {   // corresponds to our own properties and functions within this object
                    resetPaging,
                    numOfPages,
                    currentPage,
                    totalNumOfItems,
                    numOfItemsPerPage,
                    setCurrentPage,
                    setTotalNumOfItems,
                    setNumOfItemsPerPage
                }
            }
        >
            {children}
        </PartNumberPaginationContext.Provider>
    );
};

export { PartNumberPaginationContext, PartNumberPaginationProvider, usePartNumberPaginationConsumer };
