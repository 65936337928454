import React, { useState, useEffect } from 'react';
import { SafeAreaView, ScrollView, View, Dimensions, Animated, TouchableOpacity } from 'react-native';
import Header from '../controls/header';
import Badge from '../controls/badges/badge';
import HamburgerIcon from '../controls/icons/hamburgerIcon';

import Facets from './components/facets';
import SearchResults from './components/searchResults';

import styles from '../styles/screens/search';

const filtersAnimation = new Animated.Value(0);

  const ProductSelectorSearch = ({ navigation, route }) => {
    const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);
    const [showFilters, setShowFilters] = useState(false);

    const isMobileScreen = (screenWidth <= 575);

  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(Dimensions.get('window').width);
    };

    Dimensions.addEventListener('change', updateScreenWidth);

    return () => {
      Dimensions.removeEventListener('change', updateScreenWidth);
    };
  }, []);

    const facetSearchContainerStyles = isMobileScreen 
        ? styles.facetSearchContainerMobile 
        : styles.facetSearchContainerRow;

    const toggleFilters = () => {
      if (showFilters) {
        Animated.timing(filtersAnimation, {
          toValue: 0,
          duration: 300,
          useNativeDriver: false,
        }).start(() => {
          setShowFilters(!showFilters); // Toggle the state after the animation completes
        });
      } else {
        setShowFilters(!showFilters); // Toggle the state immediately to show the filters
        Animated.timing(filtersAnimation, {
          toValue: 1,
          duration: 300,
          useNativeDriver: false,
        }).start();
      }
    };

    return (
      <SafeAreaView style={styles.safeArea}>
        <Header />
        <Badge />
        { isMobileScreen ? (
          <ScrollView style={[styles.scrollContainer, styles.paddingContainer]}>
            <TouchableOpacity
                onPress={toggleFilters}
            >
                <HamburgerIcon />
            </TouchableOpacity>
            <Animated.View style={[
              facetSearchContainerStyles,
              {
                transform: [
                  {
                    translateX: filtersAnimation.interpolate({
                      inputRange: [0, 1],
                      outputRange: [-500, 0],
                    }),
                  },
                ],
              },
            ]}>
              {showFilters && <Facets />}
            </Animated.View>
            <SearchResults
              style={styles.searchContainer}
              navigation={navigation}
            />
          </ScrollView>
        ) : (
          <ScrollView style={[styles.scrollContainer, styles.paddingContainer]}>
            <View style={facetSearchContainerStyles}>
              <Facets />
              <SearchResults
                style={styles.searchContainer}
                navigation={navigation}
              />
            </View>
          </ScrollView>
        )}
      </SafeAreaView>
    );
  }

export default ProductSelectorSearch;