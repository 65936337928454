import Constants from 'expo-constants';
import Globals from './globals';

const Logger = {
    transform: function(err) {
        if (typeof err === "object")
            return JSON.stringify(err);
        else
            return err;
    },
    error: function(error) {
        if (Globals.debug)
            console.error(error);
    },
    warn: function(msg) {
        if (Globals.debug)
            console.warn(msg);
    },
    log: function(msg) {
        if (Globals.debug)
            console.log(msg);
    },
    logObj: function(obj) {
        if (Globals.debug)
            console.dir(obj);
    },
    startFunc: function(nameOfFunc) {
        if (Globals.debug) {
            console.log("--> " + nameOfFunc);
            console.group(nameOfFunc);
        }
    },
    endFunc: function(nameOfFunc) {
        if (Globals.debug) {
            console.groupEnd("<-- " + nameOfFunc);
            console.log("<-- " + nameOfFunc);
        }
    },
    logEnvVars: function() {
        this.startFunc("  Logger.logEnvVars");

        this.log("    Constants.manifest.version: " + Constants.manifest.version);
        this.log("    Constants.manifest.extra.debug: " + Constants.manifest.extra.debug);
        this.log("    Constants.manifest.extra.apiUrl: " + Constants.manifest.extra.apiUrl);
        this.log("    Constants.manifest.extra.test: " + Constants.manifest.extra.test);

        this.log("    Globals = ");
        console.dir(Globals);

        this.endFunc("  Logger.logEnvVars");
    },
    handledException: function(err) {
        if (typeof err === "string")
            this.log("  Handled exception = " + Logger.transform(err));
        else
            this.log("  Handled exception = " + err);
    },
    minorException: function(err) {
        this.warn("  Minor exception = " + Logger.transform(err));
    },
    majorException: function(err) {
        this.error("  Major exception = " + Logger.transform(err));
    },
};

export default Logger;