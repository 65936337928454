import Globals from '../utilities/globals';

const UrlService = {
    getBaseUrl: function() {
        return Globals.baseUrlForModule;
    },
    // getImageUrl: function(filename) {
    //     return `${this.getBaseUrl()}/DesktopModules/ForemostMedia/Itwccna_ProductCalculator/Images/${filename}`; 
    // },
    getApiUrl: function(relPath) {

        return `${this.getBaseUrl()}/api/${relPath}`; 
    },
    loginUrl: function() {
        var relPath = "auth/login";
        return this.getApiUrl(relPath);
    },
    logoutUrl: function() {
        var relPath = "auth/logout";
        return this.getApiUrl(relPath);
    },
    registerUrl: function() {
        var relPath = "auth/register";
        return this.getApiUrl(relPath);
    },
    resetPasswordUrl: function() {
        var relPath = "auth/resetpassword";
        return this.getApiUrl(relPath);
    },
    // refreshTokenUrl: function() {
    //     var relPath = "auth/refreshToken";
    //     return this.getApiUrl(relPath);
    // },
    emailConfirmedUrl: function() {
        var relPath = "auth/emailConfirmed";
        return this.getApiUrl(relPath);
    },
    searchUrl: function() {
        var relPath = "search";

        //if (typeof searchDto.keywords !== "undefined" && searchDto.keywords !== null && searchDto.keywords !== "")
        //    relPath += "&Keywords=" + searchDto.keywords;

        return this.getApiUrl(relPath);
    },
    searchPartUrl: function() {
        var relPath = "partsearch";
        return this.getApiUrl(relPath);
    },
};

export default UrlService;