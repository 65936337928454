class ResetPasswordDto {

    email = "";

    constructor(
        email
    )
    {
        this.email = email;
    }
};

export default ResetPasswordDto;