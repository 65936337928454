import React from 'react';
import GenericIcon from './genericIcon';
import IconEnum from '../../enums/iconEnum';

const PagePreviousIcon = (props) => {

    return (
        <GenericIcon style={props.style} iconName={IconEnum.PagePrevious.name} />
    );
}

export default PagePreviousIcon;