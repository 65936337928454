import React from 'react';
import Router from './routes/router';
import { LoadingProvider } from './contexts/loadingContext';
import { AuthProvider } from './contexts/authContext';

// PWA support (in conjuction with webpack-cli)
import * as serviceWorkerRegistration from "./webpack-cli-service-worker-registration.js";

// Font-Awesome Injections
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fab, far, fas);
//import { faSquare } from '@fortawesome/free-regular-svg-icons/faSquare'
//import { faSquareCheck } from '@fortawesome/free-regular-svg-icons/faSquareCheck'
//import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass'
//library.add(fab, faSquare, faSquareCheck, faMagnifyingGlass)
 
const App = () => {
    return (
        <LoadingProvider>
        <AuthProvider>
            <Router />
        </AuthProvider>
        </LoadingProvider>
    );
};
  
// PWA support (offline caching and add to homescreen)
serviceWorkerRegistration.register();

export default App;