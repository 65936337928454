import SelectedFilters from "./selectedFilters";
import Paging from "./paging";
import SearchOption from "./searchOption";
import Utilities from "../utilities/utilities";

class SearchDto {

    selectedFilters = new SelectedFilters();

    paging = new Paging();

    fluids = [];

    flowRates = [];

    accesssoryPackages = [];

    optionalAccesssories = [];

    registrations = [];

    flowDirections = [];

    unitOfMeasures = [];

    weightsAndMeasures = [];

    elastomers = [];

    airVaporEliminators = [];

    strainers = [];

    constructor(data) {
        
        if (!Utilities.isValidObj(data))
            return;

        this.selectedFilters.fluid = data.selectedFilters.fluid;
        this.selectedFilters.flowRate = data.selectedFilters.flowRate;
        this.selectedFilters.accessoryPackage = data.selectedFilters.accessoryPackage;
        this.selectedFilters.optionalAccessories = data.selectedFilters.optionalAccessories;
        this.selectedFilters.unitOfMeasures = data.selectedFilters.unitOfMeasures;
        this.selectedFilters.flowDirections = data.selectedFilters.flowDirections;
        this.selectedFilters.weightsAndMeasures = data.selectedFilters.weightsAndMeasures;
        this.selectedFilters.elastomers = data.selectedFilters.elastomers;
        this.selectedFilters.strainers = data.selectedFilters.strainers;
        this.selectedFilters.airVaporEliminators = data.selectedFilters.airVaporEliminators;

        this.paging.currentPage = data.paging.currentPage;
        this.paging.pageSize = data.paging.pageSize;
        this.paging.numberOfPages = data.paging.numberOfPages;

        this.fluids = this.convertToSearchOptions(data.fluids);
        this.flowRates = this.convertToSearchOptions(data.flowRates);
        this.accesssoryPackages = this.convertToSearchOptions(data.accesssoryPackages);
        this.optionalAccesssories = this.convertToSearchOptions(data.optionalAccesssories);
        this.registrations = this.convertToSearchOptions(data.registrations);
        this.flowDirections = this.convertToSearchOptions(data.flowDirections);
        this.unitOfMeasures = this.convertToSearchOptions(data.unitOfMeasures);
        this.weightsAndMeasures = this.convertToSearchOptions(data.weightsAndMeasures);
        this.elastomers = this.convertToSearchOptions(data.elastomers);
        this.airVaporEliminators = this.convertToSearchOptions(data.airVaporEliminators);
        this.strainers = this.convertToSearchOptions(data.strainers);
    }

    
    convertToSearchOptions = (array) => {
        
        let converted = [];
        
        for (let idx = 0; idx < array.length; idx++) {
            converted.push(new SearchOption(array[idx].label, array[idx].value));
        }

        return converted;
    }
};

export default SearchDto;