import React, { useEffect, useState } from 'react';
import { SafeAreaView, ScrollView, View, Dimensions,  } from 'react-native';
import Header from '../controls/header';
import Badge from '../controls/badges/badge';
import Facets from './components/partFacets';
import SearchPartResults from './components/searchPartResults';

import styles from '../styles/screens/search';

const PartNumberSearch = ({ navigation, route }) => {

    const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);
    const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(Dimensions.get('window').width);
    };

    Dimensions.addEventListener('change', updateScreenWidth);

    return () => {
      Dimensions.removeEventListener('change', updateScreenWidth);
    };
  }, []);

    const facetSearchContainerStyles = screenWidth <= 575 
        ? styles.facetSearchContainerMobile 
        : styles.facetSearchContainerRow;

    //console.log("Loading part search");
    return (
      <SafeAreaView style={styles.safeArea}>
          <Header />
          <Badge />
          <ScrollView style={[styles.scrollContainer, styles.paddingContainer]}>
              <View
                  style={facetSearchContainerStyles}
              >
                  <Facets
                  />
                  <SearchPartResults
                      style={styles.searchContainer}
                      navigation={navigation}
                  />
              </View>
          </ScrollView>
      </SafeAreaView>
  );
}

export default PartNumberSearch;