import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    itemsPerPageContainer: {
        alignItem: "flex-end",
        alignItems: "flex-start",
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    label: {
        marginHorizontal: 4,
    },
    itemsPerPagePicker: {
        marginTop: -2,
        //flexGrow: 0,
        width: "55px",
        minWidth: "55px",
    }
});