import React, { useEffect, useState }  from 'react';
import { SafeAreaView, ScrollView, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useBadgeConsumer } from '../contexts/badgeContext';
import { useAuth } from '../contexts/authContext';
import Header from '../controls/header';
import Badge from '../controls/badges/badge';
import FirstNameField from '../controls/textboxes/firstNameField';
import LastNameField from '../controls/textboxes/lastNameField';
import CompanyField from '../controls/textboxes/companyField';
import EmailField from '../controls/textboxes/emailField';
import PhoneField from '../controls/textboxes/phoneField';
import PasswordField from '../controls/textboxes/passwordField';
import PrimaryButton from '../controls/buttons/primaryButton';
import Logger from '../utilities/logger';
import RegisterDto from '../dtos/registerDto';

import styles from '../styles/screens/registration';

const Registration = () => {
    const navigation = useNavigation();

    const {
        clearMessage,
        setPrimaryMessage,
        setSuccessMessage,
        setInfoMessage,
        setWarningMessage,
        setErrorMessage
    } = useBadgeConsumer();

    const { register } = useAuth();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [company, setCompany] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");


    const onBtnRegister = async () => {
        clearMessage();

        let username = email.split("@")[0];

        let registerDto = new RegisterDto(
            firstName,
            lastName,
            username,
            password,
            email,
            mobileNumber,
            company
        );

        try {

            await register(registerDto);

            setInfoMessage("Thank you for registering.  We'll contact you soon regarding the next steps.");
        }
        catch (err) {
            Logger.minorException(err);
            setErrorMessage(err.message);
        }
        finally {
        }
    };


    const loadPage = () => {
        clearMessage();

        // if (Globals.debug) {
        //     setFirstName("Evan");
        //     setLastName("Reifsteck");
        //     setMobileNumber("608-758-4841");
        //     setCompany("Foremost Media");
        //     setEmail("ereifsteck@foremostmedia.com");
        //     setPassword("Qwerty1234!");
        //     setConfirmPassword("Qwerty1234!");
        // }
    };


    useEffect(() => {
        loadPage();
    }, []);


    return (
        <SafeAreaView style={styles.safeArea}>
            <Header />
            <Badge />
            <ScrollView style={styles.container}>
                <View>
                    <FirstNameField
                        value={firstName}
                        setValue={(value) => { setFirstName(value); }}
                    />
                    <LastNameField
                        value={lastName}
                        setValue={(value) => { setLastName(value); }}
                    />
                    <CompanyField
                        value={company}
                        setValue={(value) => { setCompany(value); }}
                    />
                    <EmailField
                        value={email}
                        setValue={(value) => { setEmail(value); }}
                    />
                    <PhoneField
                        value={mobileNumber}
                        setValue={(value) => { setMobileNumber(value); }}
                        placeholder="[mobile number]"
                    />
                    <PasswordField 
                        label="Password"
                        value={password}
                        setValue={(value) => { setPassword(value); }}
                        placeholder="[password]"
                    />
                    <PasswordField 
                        label="Confirm Password"
                        value={confirmPassword}
                        setValue={(value) => { setConfirmPassword(value); }}
                        placeholder="[confirm password]"
                    />
                    <PrimaryButton
                        onPress={onBtnRegister}
                        title="Register"
                        icon={{ name: "sign-in-alt", color: "white", position: "left" }}
                    />
                    {/* <SecondaryButton
                        onPress={onBtnLogin}
                        title="Login"
                        // icon={{ name: "sign-in-alt", color: "white", position: "left" }}
                    /> */}
                </View>
            </ScrollView>
        </SafeAreaView>
    );
}

export default Registration;