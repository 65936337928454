import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    genericButton: {
        backgroundColor: "#f37845",
        borderColor: "#f27643",
        borderRadius: 30,
        borderWidth: 1,
        color: "white",
        justifyContent: "center",
        marginBottom: 5,
        marginHorizontal: 5,
        marginTop: 5,
        minHeight: 40,
        paddingVertical: 6,
        paddingHorizontal: 12,
    },
    genericButtonText: {
        fontWeight: "500",
        fontSize: 14,
        textAlign: "center",
    },
    icon: {
        fontWeight: "500",
        fontSize: 14,
        paddingRight: 5,
    },
});