class FetchAbortedError extends Error {
    constructor(message) {
        super(message);
        this.name = 'FetchAbortedError';
    }
}

class FetchInProgressError extends Error {
    constructor(message) {
        super(message);
        this.name = 'FetchInProgressError';
    }
}



export { FetchAbortedError, FetchInProgressError };