import React, { useEffect, useState }  from 'react';
import { SafeAreaView, ScrollView, Text, View } from 'react-native';
import { useBadgeConsumer } from '../contexts/badgeContext';
import { useAuth } from '../contexts/authContext';
import Header from '../controls/header';
import Badge from '../controls/badges/badge';
import EmailField from '../controls/textboxes/emailField';
import PrimaryButton from '../controls/buttons/primaryButton';
import Globals from '../utilities/globals';
import Logger from '../utilities/logger';
import ResetPasswordDto from '../dtos/resetPasswordDto';

import styles from '../styles/screens/forgotpassword';

const ForgotPassword = () => {

    const { resetPassword } = useAuth();

    const {
        clearMessage,
        setInfoMessage,
        setErrorMessage
    } = useBadgeConsumer();


    const [email, setEmail] = useState("");


    const onBtnResetPassword = async () => {
        clearMessage();

        let resetPasswordDto = new ResetPasswordDto(email);

        try {

            await resetPassword(resetPasswordDto);

            setInfoMessage("Please check your inbox to continue with your password reset.");
        }
        catch (err) {
            Logger.minorException(err);
            setErrorMessage(err.message);
        }
        finally {
        }
    };


    const loadPage = () => {
        clearMessage();
    };


    useEffect(() => {
        loadPage();
    }, []);


    return (
        <SafeAreaView style={styles.safeArea}>
            <Header />
            <Badge />
            <ScrollView style={styles.container}>
                <View>
                    <Text style={[styles.textLeft, styles.marginBottom8]}>
                        {"Please enter your email and click submit. Then check your inbox for instructions on how to reset your password."}
                    </Text>
                    <EmailField
                        value={email}
                        setValue={(value) => { setEmail(value); }}
                    />
                    <PrimaryButton
                        onPress={onBtnResetPassword}
                        title="Submit"
                        icon={{ name: "sign-in-alt", color: "white", position: "left" }}
                    />
                </View>
            </ScrollView>
        </SafeAreaView>
    );
}

export default ForgotPassword;