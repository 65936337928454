import React, { useEffect, useState } from 'react';
import { SafeAreaView, ScrollView, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useLoadingConsumer } from '../contexts/loadingContext';
import { useAuth } from '../contexts/authContext';
import { useBadgeConsumer } from '../contexts/badgeContext';
import Header from '../controls/header';
import Badge from '../controls/badges/badge';
import UsernameField from '../controls/textboxes/usernameField';
import PasswordField from '../controls/textboxes/passwordField';
import CheckField from '../controls/checkboxes/checkField';
import PrimaryButton from '../controls/buttons/primaryButton';
import SecondaryButton from '../controls/buttons/secondaryButton';
import Utilities from '../utilities/utilities';
import Logger from '../utilities/logger';
import LoginDto from '../dtos/loginDto';
import ConfirmEmailDto from '../dtos/confirmEmailDto';
import ScreenEnum from '../enums/screenEnum';
import PwaInstallPrompt from './components/pwaInstallPrompt';

import styles from '../styles/screens/login';

const Login = () => {
    const navigation = useNavigation();

    const { isLoading, setIsLoading } = useLoadingConsumer();

    const { userCreds, accessToken, signIn, emailConfirmed } = useAuth();

    const {
        clearMessage,
        setPrimaryMessage,
        setSuccessMessage,
        setInfoMessage,
        setWarningMessage,
        setErrorMessage
    } = useBadgeConsumer();


    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);


    const onBtnLogin = async () => {

        clearMessage();
        setIsLoading(true);

        try {
            await signIn(new LoginDto(username, password, rememberMe));
        }
        catch (err) {
            Logger.minorException(err);

            setErrorMessage(err.message);
        }
        finally {
            setIsLoading(false);
        }
    };


    const onBtnRegister = () => {
        navigation.navigate(ScreenEnum.Registration.key, {name: ScreenEnum.Registration.key});
    };


    const onBtnForgotPassword = () => {
        navigation.navigate(ScreenEnum.ForgotPassword.key, {name: ScreenEnum.ForgotPassword.key});
    };


    const sendToLandingPage = (hideException) => {

        let showException = (hideException === true);

        try {
            Utilities.checkJwtToken(accessToken);

            navigation.navigate(ScreenEnum.PostLoginLanding.key, {name: ScreenEnum.PostLoginLanding.key});
        } catch (err) {
            if (showException)
                setErrorMessage(err);
            else
                Logger.handledException(err);
        }
    };


    const fillInForm = () => {
        if (Utilities.isValidObj(userCreds) && userCreds.rememberMe) {
            setUsername(userCreds.username);
            setPassword(userCreds.password);
            setRememberMe(true);
        } else {
            setUsername("");
            setPassword("");
            setRememberMe(false);
        }

        const queryParams = new URLSearchParams(location.search);
        var usernameInQuery = queryParams.get("ue")
        if (Utilities.isValidObj(usernameInQuery)) {
            setUsername(usernameInQuery);
            setPassword("");
            setRememberMe(false);
        }
    };

 
    useEffect(() => {
        //Logger.startFunc("login.accessToken");

        clearMessage();

        fillInForm();

        //Logger.endFunc("login.accessToken");

        sendToLandingPage();
    }, [accessToken]);


    useEffect(() => {
        //Logger.startFunc("login.userCreds");

        clearMessage();

        fillInForm();

        //Logger.endFunc("login.userCreds");
    }, [userCreds]);


    useEffect(() => {
        //Logger.startFunc("login.default");

        clearMessage();

        const queryParams = new URLSearchParams(location.search);
        var emailAddress = queryParams.get("ue")
        var emailToken = queryParams.get("ce")
        if (Utilities.isValidObj(emailAddress) && Utilities.isValidObj(emailToken)) {
            (async () => {
                await emailConfirmed(new ConfirmEmailDto(emailAddress, emailToken));
            })();
        }

        //Logger.endFunc("login.default");
    }, []);


    return (
        <SafeAreaView style={styles.safeArea}>
            <Header />
            <Badge />
            <ScrollView style={styles.container}>
                <View>
                    <UsernameField
                        label="Username"
                        value={username}
                        setValue={(value) => { setUsername(value); }}
                        placeholder="[enter a username]"
                    />
                    <PasswordField 
                        label="Password"
                        value={password}
                        setValue={(value) => { setPassword(value); }}
                        placeholder="[enter a password]"
                    />
                    {rememberMe &&
                        <CheckField
                            isDisabled={false}
                            isSelected={rememberMe}
                            label="Remember Me"
                            value={rememberMe}
                            onAddValue={() => { setRememberMe(true); }}
                            onRemoveValue={() => { setRememberMe(false); }}
                        />
                    }
                    {!rememberMe && 
                        <CheckField
                            isDisabled={false}
                            isSelected={rememberMe}
                            label="Remember Me"
                            value={rememberMe}
                            onAddValue={() => { setRememberMe(true); }}
                            onRemoveValue={() => { setRememberMe(false); }}
                        />
                    }
                    {/* <CheckField
                        isDisabled={false}
                        isSelected={rememberMe}
                        label="Remember Me"
                        value={rememberMe}
                        onAddValue={() => { setRememberMe(true); }}
                        onRemoveValue={() => { setRememberMe(false); }}
                    /> */}
                    <PrimaryButton
                        onPress={onBtnLogin}
                        title="Login"
                        icon={{ name: "sign-in-alt", color: "white", position: "left" }}
                    />
                    <SecondaryButton
                        onPress={onBtnRegister}
                        title="Register"
                        // icon={{ name: "sign-in-alt", color: "white", position: "left" }}
                    />
                    <SecondaryButton
                        onPress={onBtnForgotPassword}
                        title="Forgot Password?"
                        // icon={{ name: "sign-in-alt", color: "white", position: "left" }}
                    />
                </View>
            </ScrollView>
            <>
                <PwaInstallPrompt />
            </>
        </SafeAreaView>
    );
}

export default Login;