class BaseAuthenticationError extends Error {
    constructor(message) {
        super(message);
        this.name = 'BaseAuthenticationError';
    }
}

class AccessTokenHasExpiredError extends BaseAuthenticationError {
    constructor(message) {
        super(message);
        this.name = 'AccessTokenHasExpiredError';
    }
}

class AccessTokenIsNullOrEmptyError extends BaseAuthenticationError {
    constructor(message) {
        super(message);
        this.name = 'AccessTokenIsNullOrEmptyError';
    }
}


export { AccessTokenIsNullOrEmptyError, AccessTokenHasExpiredError };