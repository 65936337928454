import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import Utils from '../../utilities/utilities';
import SearchInput from '../../controls/textboxes/searchInput';
import PrimaryButton from '../../controls/buttons/primaryButton';
import SecondaryButton from '../../controls/buttons/secondaryButton';
import ScreenEnum from '../../enums/screenEnum';
import IconEnum from '../../enums/iconEnum';

import { usePartNumberSearchResultsConsumer } from '../../contexts/partNumberSearchResultsContext';

import styles from '../../styles/screens/components/requiredFilters';

const RequiredPartFilters = (props) => { 

    if (!Utils.isValidObj(props))
        return <></>;

    const { 
        resetFilters,
        setOnSearchTrigger,
        keyword,
        setKeyword,
        hints,
        onHintSelected
    } = usePartNumberSearchResultsConsumer();

    const navigation = props.navigation;

    const onSearch = () => {
        navigation.navigate(ScreenEnum.PartNumberSearch.key);
    };

    return (
        <View style={styles.requiredFiltersWrap}>
            <View style={[styles.requiredSearchWrap, styles.requiredFiltersFilter]}>
                <SearchInput
                    style={styles.requiredFiltersSearchInput}
                    keyword={keyword}
                    setKeyword={setKeyword}
                    hints={hints}
                    onHintSelected={onHintSelected}
                    onSearchBtn={() => { setOnSearchTrigger(true); }}
                />
            </View>
            <View style={styles.buttonGroup}>
                <PrimaryButton onPress={onSearch} title={"Search"} icon={{ name: IconEnum.Search.name, position: "left" }} />
                <SecondaryButton color={"white"} onPress={resetFilters} title={"Reset"} icon={{ name: IconEnum.RemoveFilters.name, position: "left" }} />
            </View>
        </View>
    );
}

export default RequiredPartFilters;