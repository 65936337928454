import React, { useState } from 'react';
import { Image, Text, TouchableOpacity, View, Dimensions } from 'react-native';
import ScreenEnum from '../../enums/screenEnum';
import Utils from '../../utilities/utilities';

import ItemsPerPagePicker from '../../controls/pagination/itemsPerPagePicker';
import StatusInfo from '../../controls/pagination/statusInfo';
import PaginationControls from '../../controls/pagination/paginationControls';
import SecondaryButton from '../../controls/buttons/secondaryButton';
import IconEnum from '../../enums/iconEnum';

import { useProductSelectorSearchResultsConsumer } from '../../contexts/productSelectorSearchResultsContext';

import styles from '../../styles/pages';


const SearchResults = (props) => {
    const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);
    const isMobileScreen = (screenWidth <= 575);

    const navigation = props.navigation;

    const {
        resetFilters,
        products,
        selectedProduct,
        setSelectedProduct,
        isLoading,
        setIsLoading,
        numOfPages,
        currentPage,
        totalNumOfItems,
        numOfItemsPerPage,
        setCurrentPage,
        setNumOfItemsPerPage,
    } = useProductSelectorSearchResultsConsumer();

    const renderTile = (item, index) => {
        //const backgroundColor = (index % 2 === 0) ? "#ffffff" : "#f5f5f5";
        const backgroundColor = "#ffffff";
        const color = 'black';

        return (
            <View
                key={index}
                style={[styles.thumbnailContainer, {backgroundColor}]}
            >
                <TouchableOpacity
                    onPress={() => {
                        navigation.navigate(ScreenEnum.ProductSelectorDetails.key, {item});
                    }}
                >
                    <Image
                        source={{ uri: item.imageUrl }}
                        style={styles.thumbnailImage}
                    />
                    {/* <Text style={[styles.tileLabel, {color}]}>{item.imageUrl}</Text> */}
                    {/* <Text style={[styles.tileLabel, {color}]}>{item.id}</Text> */}
                    <Text style={[styles.thumbnailLabel, {color}]}>{item.name}</Text>
                    <Text style={[styles.thumbnailLabel, {color}]}>{item.description}</Text>
                    <Text style={[styles.thumbnailLabel, {color}]}>{item.description2}</Text>
                </TouchableOpacity>
            </View>
        );
    };

    const thumbnailGridStyles = screenWidth <= 575 
        ? styles.thumbnailGridMobile
        : styles.thumbnailGrid;


    return (
        <>
        {
            (!Utils.isValidObj(products)
                || !Utils.isValidObj(products)
                || products.length < 1) && 
            <View
                style={styles.resultsBody}
            >
                <View style={styles.statusInfoContainer}>
                    <SecondaryButton
                        color={"white"}
                        onPress={resetFilters}
                        title={"Reset"}
                        icon={{ name: IconEnum.RemoveFilters.name, position: "left" }}
                    />
                    
                </View>
                <Text style={styles.noResults}>No results.  Update your filters.</Text>
            </View>
        }
        {
            (Utils.isValidObj(products)
                && Utils.isValidObj(products)
                && products.length > 0) && 
            <View style={styles.resultsBody}>
                        <View style={isMobileScreen ? styles.resetRowMobile : styles.resetRow}>
                    <SecondaryButton
                        onPress={resetFilters}
                        color={"white"}
                        title={"Reset"}
                        icon={{ name: IconEnum.RemoveFilters.name, position: "left" }}
                    />
                </View>
                <View style={[styles.statusInfoContainer, styles.flexAlignCenter]}>
                    <StatusInfo
                        style={styles.statusInfo}
                        currentPage={currentPage}
                        numOfItemsPerPage={numOfItemsPerPage}
                        totalNumOfItems={totalNumOfItems}
                    />
                    <ItemsPerPagePicker 
                        style={styles.itemsPerPageContainer}
                        numOfItemsPerPage={numOfItemsPerPage}
                        setNumOfItemsPerPage={setNumOfItemsPerPage}
                    />
                </View>
                <View
                    style={thumbnailGridStyles}
                >
                    {products.map((item, index) =>  {
                        return renderTile(item, index);
                    })}
                </View>
                <PaginationControls
                    style={styles.pagingToolbar}
                    currentPage={currentPage}
                    numOfPages={numOfPages}
                    onChangePage={setCurrentPage}
                />
            </View>
        }
        </>
    );
}

export default SearchResults;