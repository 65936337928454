import React, { useState } from 'react';
import { Image, SafeAreaView, ScrollView, Text, View } from 'react-native';
import SecondaryButton from '../controls/buttons/secondaryButton';
import ScreenEnum from '../enums/screenEnum';
import Header from '../controls/header';
import Badge from '../controls/badges/badge';
import Utils from '../utilities/utilities';

import styles from '../styles/screens/details';

const FlatListItem = (props) => (
    <View style={styles.cellRow} >
        <Text style={[styles.cellColumn, styles.cellLabel]}>{props.label}</Text>
        <Text style={[styles.cellColumn, styles.cellValue]}>{props.display}</Text>
    </View>
)

const ProductSelectorDetails = ({navigation, route}) => {

    const [selectedProduct, setSelectedProduct] = useState(route.params.item);

    var navigateToSearch = () => {
        navigation.navigate(ScreenEnum.ProductSelectorSearch.key);
    }

    const renderPrintMessage = (item, idx) => {
        return (
            <View
                style={styles.summaryRow}
                key={idx}
            >
                <Text style={styles.summaryLabel}>{item.header}</Text>
                <Text style={styles.summaryText}>{item.desc}</Text>
            </View>
        );
    };

    return (
        <SafeAreaView style={styles.safeArea}>
            <Header />
            <Badge />
            <ScrollView style={styles.container} resizeMode='stretch' contentContainerStyle={{ flexGrow: 1 }}>
                { Utils.isValidObj(selectedProduct) &&
                    <View style={styles.detailsWrap}>
                        <View style={styles.backWrap}>
                            <SecondaryButton 
                                title="Back"
                                onPress={navigateToSearch}
                            /> 
                        </View>
                        <View style={styles.twoColWrapper}>
                            <View style={styles.leftCol}>
                                <Image
                                    source={{ uri: selectedProduct.imageUrl }}
                                    resizeMode="contain"
                                    style={styles.largeImage}
                                />
                            </View>
                            <View style={styles.rightCol}>
                                <FlatListItem label={"Item:"} display={selectedProduct.name} />
                                <FlatListItem label={"Description:"} display={selectedProduct.description} />
                                <FlatListItem label={"Description 2:"} display={selectedProduct.description2} />
                                <FlatListItem label={"Price:"} display={selectedProduct.formattedPrice} />
                            </View>
                        </View>
                        <View style={styles.summaryWrapper}>
                            {selectedProduct.summaries.map((item, index) => {
                                return renderPrintMessage(item, index);
                            })}
                        </View>
                    </View>
                }
            </ScrollView>
        </SafeAreaView>
    );
}

export default ProductSelectorDetails;