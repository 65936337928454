import { AutoFocus } from 'expo-camera';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    facetsSidebar: {
        borderRightWidth: 1,
        borderRightColor: '#c1c1c1',
        paddingRight: 8,
    },
    facetsSidebarMobile: {
        marginBottom: 30,
    },
    facetsSearchInputWrap: {
        marginBottom: 10,
    },
    safeArea: {
        height: "100%",
    },
    splashscreen: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    scrollContainer: {
        backgroundColor: '#f0f0f0',
    },
    container: {
        backgroundColor: '#f0f0f0',
        padding: 20,
    },
    logoContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#000',
    },
    logo: {
        //flex: 1,
        width: 300,
        height: 60,
    },
    filterGroup: {
        width: "100%",
        paddingTop: 5,
        paddingBottom: 5,
    },
    filterSingleSelect: {
        fontSize: "16",
        //minHeight: 50,
        //height: 50,
        borderRadius: 3,
        marginBottom: 10,
    },
    flexParent: {
        flex: 1,
        alignContent: 'center',
        alignItems: 'stretch',
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: 'flex-start',
        //minHeight: 50,
        //width: "100%",
    },
    flexChild: {
        width: "100%",
    },
    filterContainer: {
        width: "100%",
        paddingTop: 5,
        paddingBottom: 5
    }, 
    filterLabel: {
        fontSize: 18,
        fontWeight: "500",
        height: "auto",
        width: "100%",
    }, 
    filterDropdown: {
        minHeight: 50,
        width: "100%",
    }, 
    message: {

    },
    picker: {
        //minHeight: 50,
        //minWidth: 50,
        //width: "auto",
        //height: "auto",
    },
    resultRow: {
        //flex: 1,
        //alignSelf: 'stretch',
        //flexDirection: 'row'
    },
    resultModal: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 25,
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        //width: "80%",
        //height: "80%",
    },
    modalTitle: {
        fontSize: 18,
        fontWeight: "600",
        marginBottom: 10,
        textAlign: "center",
        width: "100%",
    },
    thumbnailGrid: {
        flexBasis: "auto",
        flexShrink: 0,
        flexGrow: 0,
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        // justifyContent: "space-evenly",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingTop: 8,
    },
    thumbnailContainer: {
        alignItems: "center",
        borderColor: "#bfbfbf",
        borderWidth: 3,
        borderRadius: 5,
        justifyContent: "center",
        margin: 5,
        paddingBottom: 10,
        paddingHorizontal: 10,
        paddingTop: 5,
    },
    thumbnailImage: {
        margin: 5,
        width: 175,
        height: 175,
        resizeMode: "center",
    },
    thumbnailLabel: {
        //flexShrink: 1,
        fontSize: 12,
        fontWeight: "500",
        textAlign: "center",
        width: "100%",
    },
    filterDropdown: {
        alignItems: "center",
        backgroundColor: "white",
        borderColor: "#bfbfbf",
        borderWidth: 3,
        borderRadius: 5,
        padding: 4,
    },
    inputs: {
        borderWidth: 1,
        borderColor: 'gray',
        backgroundColor: 'white',
        height: 40,
        paddingRight: 10,
        paddingLeft: 10,
    },
    headerRow: {
        alignItems: 'center',
        backgroundColor: 'lightgray',
        borderColor: "lightgray",
        borderWidth: 3,
        borderRadius: 5,
        flex: 1,
        flexDirection: 'row',
        //flexWrap: 'wrap',
        justifyContent: "space-evenly",
        padding: 20,
    },
    headerColumn: {
        fontSize: 18,
        fontWeight: '600',
    },
    cellRow: {
        alignContent: 'stretch',
        alignItems: 'center',
        //backgroundColor: "white",
        flex: 1,
        flexBasis: 'auto',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: "space-between",
        padding: 20,
        marginTop: 5,
        backgroundColor: '#fff',
        borderWidth: 1,
        borderColor: '#bfbfbf',
    },
    cellColumn: {
        width: "auto",
    },
    cellLabel: {
        fontSize: 16
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22
    },
    modalView: {
        margin: 20,
        backgroundColor: "white",
        // "#0000004f"
        borderRadius: 20,
        padding: 35,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        // width: "80%",
        // height: "70%"
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2
    },
    buttonOpen: {
        backgroundColor: "#F194FF",
    },
    buttonClose: {
        backgroundColor: "#2196F3",
    },
    textStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center"
    },
    marginContainer: {
        margin: 10,     
    },
    paddingContainer: {
        padding: 10,
    },
    largeImage: {
        height: 400,
        maxWidth: '100%',
        width: '100%',
        paddingTop: '60%',
        height: '0',
        borderWidth: 1,
        borderColor: '#bfbfbf',
    },
    statusInfoContainer: {
        flexBasis: "auto",
        flexShrink: 0,
        flexGrow: 0,
        flex: 1,
        flexDirection: "row",
        // justifyContent: "space-between",
        // width: "auto",
        // height: "auto",
    },
    statusInfo: {
        // alignItem: "flex-start",
    },
    itemsPerPageContainer: {
        // alignItem: "flex-end",
    },
    resultsBody: {
        flex: 1,
        flexDirection: "column",
        // justifyContent: "flex-start",
        // alignItems: "flex-start",
    },
    pagingToolbar: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignContent: "center",
    },
});