import Constants from 'expo-constants';

const Globals = {
    debug: Constants.manifest.extra.debug,
    baseUrlForModule: Constants.manifest.extra.apiUrl,
    test: Constants.manifest.extra.test,
    placeholderColor: "lightgray",
    default: {
        large: {
            pageSize: 20,
        },
        small: {
            pageSize: 10
        }
    }
};

export default Globals;