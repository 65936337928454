import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    primaryButton: {
        backgroundColor: "#fff",
        borderColor: "#c1c1c1",
    },
    primaryButtonText: {
        color: "black",
    },
});