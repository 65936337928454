class ConfirmEmailDto {

    emailAddress = "";
    token = "";

    constructor(
        emailAddress,
        token
    )
    {
        this.emailAddress = emailAddress;
        this.token = token;
    }
};

export default ConfirmEmailDto;