import React from 'react';
import { Text, TextInput, View } from 'react-native';
import Globals from '../../utilities/globals';
import Utilities from '../../utilities/utilities';

import styles from '../../styles/inputs/inputField';

const PasswordField = (props) => {
    
    return (
        <View style={styles.textInputContainer}>
            <Text
                style={styles.label}
            >
                {Utilities.isValidObj(props.label) ? props.label : "Password"}
            </Text>
            <TextInput 
                style={styles.input}
                value={Utilities.isValidObj(props.value) ? props.value : ""}
                onChangeText={props.setValue}
                placeholderTextColor={Globals.placeholderColor}
                placeholder={Utilities.isValidObj(props.placeholder) ? props.placeholder : "[enter a password]"}
                secureTextEntry={true}
                textContentType="password"
            />
        </View>
    );
}

export default PasswordField;