import React, { createContext, useEffect, useState, useContext } from 'react';
import Logger from '../utilities/logger';
import Utils from '../utilities/utilities';

const partNumberRequiredFiltersContext = createContext();

const usePartNumberRequiredFiltersConsumer = function() {
    const context = useContext(partNumberRequiredFiltersContext);
    if (!context)
        throw new Error('usePartNumberRequiredFiltersConsumer must be used within a PartNumberRequiredFiltersProvider');

    return context;
}

const PartNumberRequiredFiltersProvider = ({children}) => {

    const [keywordTimeoutId, setKeywordTimeoutId] = useState(null);

    const [onSearchTrigger, setOnSearchTrigger] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [hints, setHints] = useState([]);
    

    const resetFilters = () => {
        setHints([]);
        setOnSearchTrigger(false);
        setKeyword("");
    };


    var onSearchKeyword = () => {
        //Logger.startFunc('  onSearchKeyword');

        setOnSearchTrigger(true);

        //Logger.endFunc('  onSearchKeyword');
    };


    const stopKeywordTimer = () => {
        //Logger.startFunc('  stopKeywordTimer');

        //Logger.log('      <<< keywordTimeoutId: ' + keywordTimeoutId);
        if (keywordTimeoutId !== null)
            clearTimeout(keywordTimeoutId);
        setKeywordTimeoutId(null);

        //Logger.endFunc('  stopKeywordTimer');
    };


    const startKeywordTimer = (forceIt) => {

        //Logger.startFunc('  startKeywordTimer');

        stopKeywordTimer();

        if (forceIt === true || (Utils.isValidObj(keyword) && keyword.length > 2)) {

            var timeoutId = setTimeout(onSearchKeyword, 3000);
            setKeywordTimeoutId(timeoutId);
            //Logger.log('      >>> keywordTimeoutId: ' + timeoutId);

            //Logger.endFunc('  startKeywordTimer (started)');
        }
        else {
            //Logger.endFunc('  startKeywordTimer (nothing to do)');
        }
    }


    const unmountedCleanup = () => {
        //Logger.startFunc("unmountedCleanup.requiredFiltersContext");
        //Logger.log("  *** abort any async calls ***");
        
        //Logger.log("  *** stopping keyword timer ***");
        stopKeywordTimer();

        //Logger.endFunc("unmountedCleanup.requiredFiltersContext");
    };


    const onHintSelected = (selectedKeyword) => {

        setKeyword(selectedKeyword);
        setHints([]);
    };


    useEffect(() => {

        return () => {
            unmountedCleanup();
        };
    }, []);


    useEffect(() => {

        //Logger.log("useEffect.requiredFilters.keyword was hit!");

        startKeywordTimer();
    }, [keyword]);


    useEffect(() => {

        //Logger.startFunc("partNumberRequiredFiltersContext.onSearchTrigger");

        stopKeywordTimer();

        //Logger.endFunc("partNumberRequiredFiltersContext.onSearchTrigger");
    }, [onSearchTrigger]);


    return (
        <partNumberRequiredFiltersContext.Provider 
            value={
                {   // corresponds to our own properties and functions within this object   
                    resetFilters,
                    onSearchTrigger,
                    setOnSearchTrigger,
                    keyword,
                    setKeyword,
                    hints,
                    setHints,
                    onHintSelected,
                }
            }
        >
            {children}
        </partNumberRequiredFiltersContext.Provider>
    );
};

export { partNumberRequiredFiltersContext, PartNumberRequiredFiltersProvider, usePartNumberRequiredFiltersConsumer };
