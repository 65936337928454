import { ActivityIndicator, Image, StyleSheet, View } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import AppLoading from 'expo-app-loading';

// TODO:  download and cache json data for pages
//import ResourceDownloaderComponent from './ResourceDownloaderComponent.js';

Promise.allSettled = Promise.allSettled || ((promises) => Promise.all(
    promises.map(p => p
        .then(value => ({
            status: "fulfilled",
            value
        }))
        .catch(reason => ({
            status: "rejected",
            reason
        }))
    )
));

const SplashScreen = (props) => {

    const startAsync = () => {

        return Promise.resolve();
    };

    const onFinish = () => {

        if (typeof props.setIsReady === "function")
            props.setIsReady(true);
    };

    return (
        <View style={styles.fillView}>
            <LinearGradient
                // Background Linear Gradient
                colors={['#ea824f', '#862e04']}
                style={styles.fillView}
            >
                <Image
                    style={styles.splashscreen}
                    source={require("../assets/splash.png")}
                />
                <ActivityIndicator style={styles.busy} />
                <AppLoading
                    startAsync={startAsync}
                    onFinish={onFinish}
                    onError={console.warn}
                />
            </LinearGradient>
        </View>
    );
}

const styles = StyleSheet.create({
    busy: {
        left: "50%",
        position: "absolute",
        right: "50%",
    },
    fillView: {
        width: "100%",
        height: "100%"
    },
    splashscreen: {
        width: "100%",
        height: "100%",
        resizeMode: "contain",
    },
});

export default SplashScreen;