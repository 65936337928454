import React, { createContext, useEffect, useState, useContext } from 'react';
import Logger from '../utilities/logger';

const LoadingContext = createContext();

const useLoadingConsumer = function() {
    const context = useContext(LoadingContext);
    if (!context)
        throw new Error('useLoadingConsumer must be used within a LoadingProvider');

    return context;
}

const LoadingProvider = ({children}) => {

    const [isLoadingApp, setIsLoadingApp] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        //Logger.startFunc("loadingContext.useEffect.default");

        // IMPORTANT - Since this loading is all async, we need some identifier so we don't inadvertantly fire our other useEffects that are listening
        //                  for specific variable state changes.
        (async () => {

            setIsLoadingApp(false);
        })();

        //Logger.endFunc("loadingContext.useEffect.default");
    }, []);

    return (
        <LoadingContext.Provider 
            value={
                {   // corresponds to our own properties and functions within this object
                    isLoadingApp,
                    isLoading,
                    setIsLoading
                }
            }
        >
            {children}
        </LoadingContext.Provider>
    );
};

export { LoadingContext, LoadingProvider, useLoadingConsumer };
