import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    searchInputContainer: {
        alignContent: "flex-start",
        backgroundColor: "white", 
        borderColor: "gray",
        borderRadius: 58,
        borderWidth: 1,
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        height: 39,
        justifyContent: "flex-start",
        marginBottom: 0,
    },
    searchInput: {
        flexGrow: 1,
        flexShrink: 1,
        height: "100%",
        outlineWidth: 0,
        paddingRight: 10,
        paddingLeft: 10,
        width: "100%",
    },
    searchFieldBtn: {
        alignContent: "center",
        backgroundColor: "black",
        borderLeftColor: "black",
        borderTopRightRadius: 15,
        borderBottomRightRadius: 15,
        borderLeftWidth: 1,
        flexGrow: 0,
        flexShrink: 0,
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
        width: 40,
    },
    searchFieldBtnIcon: {
        color: "white",
        width: "auto",
    },
    searchComponent: {
    },
    searchHintContainer: {
        borderWidth: 1,
        borderColor: "black",
        backgroundColor: "white",
        color: "black",
        padding: 5,
        minHeight: 60,
        height: "auto",
        width: "auto",
    },
});