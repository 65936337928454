import React, { useEffect, useState } from 'react';
import { Text, TouchableOpacity, View, LayoutAnimation, Platform, UIManager } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';

import CheckField from '../checkboxes/checkField';
import RadioField from '../checkboxes/radioField';

import Utils from '../../utilities/utilities';

import styles from '../../styles/collapsibles/fieldGroup';
import Logger from '../../utilities/logger';


if (Platform.OS === 'android') {
    if (UIManager.setLayoutAnimationEnabledExperimental) {
        UIManager.setLayoutAnimationEnabledExperimental(true);
    }
}

const FieldGroup = (props) => {

    if (!Utils.isValidObj(props))
        return <></>;

    const title = props.title;
    const isRadio = props.isRadio;

    const allItems = props.allItems;
    const availableItems = props.availableItems;
    const selectedItems = props.selectedItems;
    const onSetValue = props.onSetValue;
    const onAddValue = props.onAddValue;
    const onRemoveValue = props.onRemoveValue;
    const addResetDoneEventListener = props.addResetDoneEventListener;
    const removeResetDoneEventListener = props.removeResetDoneEventListener;



    const [open, setopen] = useState(false);


    const onToggle = () => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        setopen(!open);
    };


    const isAvailable = (value) => {

        let foundIt = Utils.findItemByValue(parseInt(value), availableItems) !== null;
        return foundIt;
    };

    const isSelected = (value) => {

        let foundIt = false;

        if (Utils.isValidObj(selectedItems)) {

            if (Array.isArray(selectedItems))
                foundIt = Utils.findItemByValue(parseInt(value), selectedItems) !== null;
            else
                foundIt = parseInt(value) === parseInt(selectedItems);
        }

        return foundIt;
    };


    const renderCheck = (item, index) => {
        return (
            <CheckField
                key={parseInt(item.value)}
                isDisabled={!isAvailable(item.value)}
                isSelected={isSelected(item.value)}
                label={item.name}
                value={item.value}
                onAddValue={onAddValue}
                onRemoveValue={onRemoveValue}
            />
        );
    }

    const renderRadio = (item, index) => {
        return (
            <RadioField
                key={parseInt(item.value)}
                isDisabled={!isAvailable(item.value)}
                isSelected={isSelected(item.value)}
                label={item.name}
                value={item.value}
                onSetValue={onSetValue}
            />
        );
    }

    const renderIconOpened = () => {
        return (
            <FontAwesomeIcon 
                style={[styles.pagingBtnIcon]}
                icon="fa-solid fa-caret-down"
            />
        );
    };

    const renderIconClosed = () => {
        return (
            <FontAwesomeIcon 
                style={[styles.pagingBtnIcon]}
                icon="fa-solid fa-caret-right"
            />
        );
    };


    const onResetDoneEvent = () => {

        setopen(false);
    };


    useEffect(() => {

        addResetDoneEventListener(onResetDoneEvent);

        return () => {
            removeResetDoneEventListener(onResetDoneEvent);
        };
    }, []);


    return (
        <View style={styles.fieldGroupContainer}>
            <TouchableOpacity
                style={[styles.fieldGroupRow, !open && { height: 27 }]} 
                onPress={onToggle} 
                activeOpacity={1}
            >
                <View style={styles.fieldGroupHeader}>
                    <Text style={styles.fieldGroupLabel}>
                        {title}
                        { Utils.isValidObj(selectedItems) && selectedItems.length > 0 &&
                            <Text> ({selectedItems.length})</Text>
                        }
                    </Text>
                    {!open && renderIconClosed() }
                    {open && renderIconOpened() }
                </View>
                {open && (
                    <View style={styles.fieldGroupRows}>
                        { Utils.isValidObj(allItems) && isRadio &&
                            allItems.map((item, index) => {
                                return renderRadio(item, index);
                            })
                        }
                        { Utils.isValidObj(allItems) && !isRadio &&
                            allItems.map((item, index) => {
                                return renderCheck(item, index);
                            })
                        }
                    </View>
                )}
            </TouchableOpacity>
        </View>
    );
}

export default FieldGroup;