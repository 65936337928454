import { AutoFocus } from 'expo-camera';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    container: {
        backgroundColor: "#000000",
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'nowrap',
        minHeight: 80,
        maxHeight: 80,
    },
    leftSection: {
        flexGrow: 1,
        flexShrink: 1,
    },
    midSection: {
        flexGrow: 2,
        flexShrink: 0,
    },
    rightSection: {
        flexGrow: 1,
        flexShrink: 1,
    },
    indicator: {
        
    }
    // busyIndicator: {
    //     left: "50%",
    //     position: "absolute",
    //     right: "50%",
    // },
});