class SelectedFilters {
    /// required

    fluid = -1;
    flowRate = -1;
    accessoryPackage = -1;

    /// faceted

    optionalAccessories = [];
    registrations = [];
    unitOfMeasures = [];
    weightsAndMeasures = [];
    flowDirections = [];
    elastomers = [];
    airVaporEliminators = [];
    strainers = [];
    

    constructor(
        fluid, 
        flowRate, 
        accessoryPackage, 
        optionalAccessories, 
        registrations,
        flowDirections, 
        unitOfMeasures, 
        weightsAndMeasures, 
        elastomers, 
        airVaporEliminators,
        strainers
    ) {

        this.fluid = fluid;
        this.flowRate = flowRate;
        this.accessoryPackage = accessoryPackage;

        this.optionalAccessories = optionalAccessories;
        this.registrations = registrations;
        this.unitOfMeasures = unitOfMeasures;
        this.flowDirections = flowDirections;
        this.weightsAndMeasures = weightsAndMeasures;
        this.elastomers = elastomers;
        this.airVaporEliminators = airVaporEliminators;
        this.strainers = strainers;
    }
};

export default SelectedFilters;