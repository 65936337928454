import React, {createContext, useState, useContext, useEffect} from 'react';
import AuthService from '../services/authService';
import Logger from '../utilities/logger';
import Utilities from '../utilities/utilities';

const AuthContext = createContext();

const useAuth = function() {
    const context = useContext(AuthContext);

    if (!context)
        throw new Error('useAuth must be used within an AuthProvider');

    return context;
}

///<remarks>
///  Goal(s):
///     Encapsulate authentication objects and routines within this Context.
///     Force UI to rerender when the authentication state has changed.
///         Therefore, consumers should only be concerned about whether or not a "useState" has changed.
///</remarks>
const AuthProvider = ({children}) => {

    const [isInitialized, setInitialized] = useState(false);

    const [userCreds, setUserCreds] = useState();

    const [accessToken, setAccessToken] = useState();


    var isFetchingCachedLoginCreds = false;
    var _abortControllerForCachedLoginCreds = null;
    const setupAbortControllerForCachedLoginCreds = () => {
        if (_abortControllerForCachedLoginCreds === null || typeof _abortControllerForCachedLoginCreds === "undefined" || _abortControllerForCachedLoginCreds.signal.aborted) {
            _abortControllerForCachedLoginCreds = new AbortController();
        }

        return _abortControllerForCachedLoginCreds;
    }
    const abortForCachedLoginCreds = () => {
        if (isFetchingCachedLoginCreds && _abortControllerForCachedLoginCreds !== null && typeof _abortControllerForCachedLoginCreds !== "undefined" && !_abortControllerForCachedLoginCreds.signal.aborted) {
            //Logger.log("  authContext.abortForCachedCachedLoginCreds");
            _abortControllerForCachedLoginCreds.abort();
        }
        isFetchingCachedLoginCreds = false;
    };


    var isFetchingCachedLoginResult = false;
    var _abortControllerForCachedLoginResult = null;
    const setupAbortControllerForCachedLoginResult = () => {
        if (_abortControllerForCachedLoginResult === null || typeof _abortControllerForCachedLoginResult === "undefined" || _abortControllerForCachedLoginResult.signal.aborted) {
            _abortControllerForCachedLoginResult = new AbortController();
        }

        return _abortControllerForCachedLoginResult;
    }
    const abortForCachedLoginResult = () => {
        if (isFetchingCachedLoginResult && _abortControllerForCachedLoginResult !== null && typeof _abortControllerForCachedLoginResult !== "undefined" && !_abortControllerForCachedLoginResult.signal.aborted) {
            //Logger.log("  authContext.abortForCachedLoginResult");
            _abortControllerForCachedLoginResult.abort();
        }
        isFetchingCachedLoginResult = false;
    };


    var isFetchingData = false;
    var _abortControllerForFetchData = null;
    const setupAbortControllerForFetchData = () => {
        if (_abortControllerForFetchData === null || typeof _abortControllerForFetchData === "undefined" || _abortControllerForFetchData.signal.aborted) {
            _abortControllerForFetchData = new AbortController();
        }

        return _abortControllerForFetchData;
    }
    const abortForFetchData = () => {
        if (isFetchingData && _abortControllerForFetchData !== null && typeof _abortControllerForFetchData !== "undefined" && !_abortControllerForFetchData.signal.aborted) {
            //Logger.log("  authContext.abortForFetchData");
            _abortControllerForFetchData.abort();
        }
        isFetchingData = false;
    };


    const signIn = async (loginDto) => {

        let loginResult = await AuthService.signIn(loginDto, setupAbortControllerForFetchData());

        const areEqual = loginDto.equals(userCreds);
        if (!areEqual)
            setUserCreds(loginDto);

        if (loginResult.accessToken !== accessToken)
            setAccessToken(loginResult.accessToken);

        return accessToken;
    };


    const signOut = async () => {

        let result = false;

        try {
            result = await AuthService.signOut(setupAbortControllerForFetchData());
        }
        catch (err) {
            throw err;
        }
        finally {
            setAccessToken(null);
        }

        return result;
    };


    const resetPassword = async (dto) => {

        let result = false;

        try {
            result = await AuthService.resetPassword(dto, setupAbortControllerForFetchData());
        }
        catch (err) {
            throw err;
        }

        return result;
    };


    ///<remarks>
    ///  After registration, end-users must acknowlege their email address and get authorization to use the app.
    ///</remarks>
    const register = async (registerDto) => {

        setAccessToken(null);
        setUserCreds(null);

        try {
            let result = await AuthService.register(registerDto, setupAbortControllerForFetchData());
            return result;
        }
        catch (err) {
            throw err;
        }
    };

    const emailConfirmed = async (confirmEmailDto) => {
        try {
            let result = await AuthService.emailConfirmed(confirmEmailDto, setupAbortControllerForFetchData());
            return result;
        }
        catch (err) {
            throw err;
        }
    };

    const loadCachedUserCreds = async () => {
        //Logger.startFunc("  authContext.loadCachedUserCreds");

        let cachedLoginCreds = null;

        try {
            cachedLoginCreds = await AuthService.getCachedLoginCreds(setupAbortControllerForCachedLoginCreds());
        }
        catch (err) {
            // NOTE:  the cache might not be present yet; so capture this exception.
            Logger.handledException(err);
        }
        finally {

            if (Utilities.isValidObj(cachedLoginCreds))
                setUserCreds(cachedLoginCreds);

            //Logger.endFunc("  authContext.loadCachedUserCreds");
        }
    };


    const loadCachedAccessToken = async () => {
        //Logger.startFunc("  authContext.loadCachedAccessToken");

        let cachedLoginResult = null;

        try {
            cachedLoginResult = await AuthService.getCachedLoginResult(setupAbortControllerForCachedLoginResult());
        }
        catch (err) {

            if (Utilities.isValidObj(userCreds) && userCreds.rememberMe) {
                try {
                    cachedLoginResult = await signIn(userCreds);
                } catch (signInError) {
                    // NOTE:  silently handle this as this is a startup operation that is automated
                    //Logger.log("vvv signIn error vvv");
                    Logger.handledException(signInError);
                }
            } else {
                // NOTE:  the access token might not be present yet or its expired; so capture this exception.
                //Logger.log("vvv cachedLoginResult error vvv");
                Logger.handledException(err);
            }   
        }
        finally {

            if (Utilities.isValidObj(cachedLoginResult))
                setAccessToken(cachedLoginResult.accessToken);

            //Logger.endFunc("  authContext.loadCachedAccessToken");
        }
    };


    const onLoad = async () => {
        //Logger.startFunc("authContext.useEffect.onLoad");

        await loadCachedUserCreds();

        await loadCachedAccessToken();

        //Logger.endFunc("authContext.useEffect.onLoad");
    };


    const unmountCleanup = () => {
        //Logger.startFunc("authContext.useEffect.unmountCleanup");

        abortForCachedLoginCreds();

        abortForCachedLoginResult();

        abortForFetchData();

        //Logger.endFunc("authContext.useEffect.unmountCleanup");
    };


    useEffect(() => {
        Logger.logEnvVars();

        (async () => {
            //Logger.startFunc("authContext.useEffect.default");

            try {
                await onLoad();
            }
            catch (err) {
                // ignore, we already handled it
            }
            finally {
                setInitialized(true);
    
                //Logger.endFunc("authContext.useEffect.default");
            }
        })();

        return () => {
            unmountCleanup();
        };
    }, []);

    return (
        <AuthContext.Provider 
            value={
                {   // corresponds to our own properties and functions within this object
                    isInitialized,
                    userCreds,
                    accessToken,
                    signIn,
                    signOut,
                    register,
                    emailConfirmed,
                    resetPassword,
                }
            }
        >
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider, useAuth };
