import React, { createContext, useState, useContext } from 'react';
import Utils from '../utilities/utilities';

const ProductSelectorOptionalFiltersContext = createContext();

const useProductSelectorOptionalFiltersConsumer = function() {
    const context = useContext(ProductSelectorOptionalFiltersContext);
    if (!context)
        throw new Error('useProductSelectorOptionalFiltersConsumer must be used within a ProductSelectorOptionalFiltersProvider');

    return context;
}

const ProductSelectorOptionalFiltersProvider = ({children}) => {

    const [allOptionalAccessories, setAllOptionalAccessories] = useState([]);
    const [allFlowDirections, setAllFlowDirections] = useState([]);
    const [allUnitOfMeasures, setAllUnitOfMeasures] = useState([]);
    const [allWeightsAndMeasures, setAllWeightsAndMeasures] = useState([]);
    const [allElastomers, setAllElastomers] = useState([]);
    const [allAirVaporEliminators, setAllAirVaporEliminators] = useState([]);
    const [allStrainers, setAllStrainers] = useState([]);

    const [availableOptionalAccessories, setAvailableOptionalAccessories] = useState([]);
    const [availableFlowDirections, setAvailableFlowDirections] = useState([]);
    const [availableUnitOfMeasures, setAvailableUnitOfMeasures] = useState([]);
    const [availableWeightsAndMeasures, setAvailableWeightsAndMeasures] = useState([]);
    const [availableElastomers, setAvailableElastomers] = useState([]);
    const [availableAirVaporEliminators, setAvailableAirVaporEliminators] = useState([]);
    const [availableStrainers, setAvailableStrainers] = useState([]);

    const [selectedOptionalAccessories, setSelectedOptionalAccessories] = useState([]);
    const [selectedFlowDirection, setSelectedFlowDirection] = useState(-1);
    const [selectedUnitOfMeasures, setSelectedUnitOfMeasures] = useState([]);
    const [selectedWeightsAndMeasures, setSelectedWeightsAndMeasures] = useState([]);
    const [selectedElastomers, setSelectedElastomers] = useState([]);
    const [selectedAirVaporEliminators, setSelectedAirVaporEliminators] = useState([]);
    const [selectedStrainers, setSelectedStrainers] = useState([]);

    const resetOptionalFilters = () => {

        setAvailableOptionalAccessories(Utils.convertKeysToIntArray(allOptionalAccessories), true);
        setAvailableFlowDirections(Utils.convertKeysToIntArray(allFlowDirections), true);
        setAvailableUnitOfMeasures(Utils.convertKeysToIntArray(allUnitOfMeasures), true);
        setAvailableWeightsAndMeasures(Utils.convertKeysToIntArray(allWeightsAndMeasures), true);
        setAvailableElastomers(Utils.convertKeysToIntArray(allElastomers), true);
        setAvailableAirVaporEliminators(Utils.convertKeysToIntArray(allAirVaporEliminators), true);
        setAvailableStrainers(Utils.convertKeysToIntArray(allStrainers), true);

        setSelectedOptionalAccessories([]);
        setSelectedFlowDirection(-1);
        setSelectedUnitOfMeasures([]);
        setSelectedWeightsAndMeasures([]);
        setSelectedElastomers([]);
        setSelectedAirVaporEliminators([]);
        setSelectedStrainers([]);
    };

    const onAddOptionalAccessory = (value) => {

        let updatedArray = Utils.onAddValue(value, selectedOptionalAccessories);
        setSelectedOptionalAccessories(updatedArray);
    }
    const onRemoveOptionalAccessory = (value) => {

        let updatedArray = Utils.onRemoveValue(value, selectedOptionalAccessories);
        setSelectedOptionalAccessories(updatedArray);
    }

    
    const onFlowDirectionChanged = (item) => {
        Utils.setSelectedItem(setSelectedFlowDirection, item);
    }


    const onAddUnitOfMeasure = (value) => {

        let updatedArray = Utils.onAddValue(value, selectedUnitOfMeasures);
        setSelectedUnitOfMeasures(updatedArray);
    }
    const onRemoveUnitOfMeasure = (value) => {

        let updatedArray = Utils.onRemoveValue(value, selectedUnitOfMeasures);
        setSelectedUnitOfMeasures(updatedArray);
    }


    const onAddWeightsAndMeasure = (value) => {

        let updatedArray = Utils.onAddValue(value, selectedWeightsAndMeasures);
        setSelectedWeightsAndMeasures(updatedArray);
    }
    const onRemoveWeightsAndMeasure = (value) => {

        let updatedArray = Utils.onRemoveValue(value, selectedWeightsAndMeasures);
        setSelectedWeightsAndMeasures(updatedArray);
    }


    const onAddElastomer = (value) => {

        let updatedArray = Utils.onAddValue(value, selectedElastomers);
        setSelectedElastomers(updatedArray);
    }
    const onRemoveElastomer = (value) => {

        let updatedArray = Utils.onRemoveValue(value, selectedElastomers);
        setSelectedElastomers(updatedArray);
    }


    const onAddAirVaporEliminator = (value) => {

        let updatedArray = Utils.onAddValue(value, selectedAirVaporEliminators);
        setSelectedAirVaporEliminators(updatedArray);
    }
    const onRemoveAirVaporEliminator = (value) => {

        let updatedArray = Utils.onRemoveValue(value, selectedAirVaporEliminators);
        setSelectedAirVaporEliminators(updatedArray);
    }


    const onAddStrainer = (value) => {

        let updatedArray = Utils.onAddValue(value, selectedStrainers);
        setSelectedStrainers(updatedArray);
    }
    const onRemoveStrainer = (value) => {

        let updatedArray = Utils.onRemoveValue(value, selectedStrainers);
        setSelectedStrainers(updatedArray);
    }

    return (
        <ProductSelectorOptionalFiltersContext.Provider 
            value={
                {   // corresponds to our own properties and functions within this object
                    resetOptionalFilters,
                    allOptionalAccessories,
                    allFlowDirections,
                    allUnitOfMeasures,
                    allWeightsAndMeasures,
                    allElastomers,
                    allAirVaporEliminators,
                    allStrainers,
                    setAllOptionalAccessories,
                    setAllFlowDirections,
                    setAllUnitOfMeasures,
                    setAllWeightsAndMeasures,
                    setAllElastomers,
                    setAllAirVaporEliminators,
                    setAllStrainers,
                    availableOptionalAccessories,
                    availableFlowDirections,
                    availableUnitOfMeasures,
                    availableWeightsAndMeasures,
                    availableElastomers,
                    availableAirVaporEliminators,
                    availableStrainers,
                    setAvailableOptionalAccessories,
                    setAvailableFlowDirections,
                    setAvailableUnitOfMeasures,
                    setAvailableWeightsAndMeasures,
                    setAvailableElastomers,
                    setAvailableAirVaporEliminators,
                    setAvailableStrainers,
                    selectedOptionalAccessories,
                    selectedFlowDirection,
                    selectedUnitOfMeasures,
                    selectedWeightsAndMeasures,
                    selectedElastomers,
                    selectedAirVaporEliminators,
                    selectedStrainers,
                    setSelectedOptionalAccessories,
                    setSelectedFlowDirection,
                    setSelectedUnitOfMeasures,
                    setSelectedWeightsAndMeasures,
                    setSelectedElastomers,
                    setSelectedAirVaporEliminators,
                    setSelectedStrainers,
                    onAddOptionalAccessory,
                    onRemoveOptionalAccessory,
                    onFlowDirectionChanged,
                    onAddUnitOfMeasure,
                    onRemoveUnitOfMeasure,
                    onAddWeightsAndMeasure,
                    onRemoveWeightsAndMeasure,
                    onAddElastomer,
                    onRemoveElastomer,
                    onAddAirVaporEliminator,
                    onRemoveAirVaporEliminator,
                    onAddStrainer,
                    onRemoveStrainer,
                }
            }
        >
            {children}
        </ProductSelectorOptionalFiltersContext.Provider>
    );
};

export { ProductSelectorOptionalFiltersContext, ProductSelectorOptionalFiltersProvider, useProductSelectorOptionalFiltersConsumer };
