import React, { createContext, useEffect, useState, useContext } from 'react';
import Logger from '../utilities/logger';
import Utilities from '../utilities/utilities';
import ApiService from '../services/apiService';

import { FetchAbortedError, FetchInProgressError } from '../exceptions/fetchExceptions';

import { useLoadingConsumer } from './loadingContext';
import { useAuth } from './authContext';
import { useBadgeConsumer } from './badgeContext';
import { usePartNumberPaginationConsumer } from './partNumberPaginationContext';
import { usePartNumberRequiredFiltersConsumer } from './partNumberRequiredFiltersContext';

import SearchDto from '../dtos/searchDto';

const PartNumberSearchResultsContext = createContext();

const usePartNumberSearchResultsConsumer = function() {
    const context = useContext(PartNumberSearchResultsContext);
    if (!context)
        throw new Error('useSearchPartsResultsConsumer must be used within a PartNumberSearchResultsProvider');

    return context;
}
 
const PartNumberSearchResultsProvider = ({children}) => {

    const {
        isLoading,
        setIsLoading
    } = useLoadingConsumer();

    const {
        isInitialized,
        accessToken
    } = useAuth();

    const {
        setPrimaryMessage,
        setSuccessMessage,
        setInfoMessage,
        setWarningMessage,
        setErrorMessage,
        clearMessage
    } = useBadgeConsumer();

    const {
        resetPaging,
        numOfPages,
        currentPage,
        totalNumOfItems,
        numOfItemsPerPage,
        setCurrentPage,
        setTotalNumOfItems,
        setNumOfItemsPerPage,
    } = usePartNumberPaginationConsumer();

    const {
        onSearchTrigger,
        setOnSearchTrigger,
        keyword,
        setKeyword,
        hints,
        setHints,
        onHintSelected,
    } = usePartNumberRequiredFiltersConsumer();

    var abortController = null;
    var isFetching = false;

    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState();

    const setupAbortController = () => {
        if (abortController === null || typeof abortController === "undefined" || abortController.signal.aborted) {
            abortController = new AbortController();
        }
    }

    const [resetDoneEventListeners, setResetDoneEventListeners] = useState([]);
    const addResetDoneEventListener = (fnCallback) => {

        let temp = resetDoneEventListeners;
        temp.push(fnCallback);
        setResetDoneEventListeners(temp);
    }

    const removeResetDoneEventListener = (fnCallback) => {
        let temp = resetDoneEventListeners;
        let idx = temp.indexOf(fnCallback);
        if (idx > -1)
            temp.splice(idx, 1);

        setResetDoneEventListeners(temp);
    }

    const removeAllResetDoneEventListeners = () => {
        setResetDoneEventListeners([]);
    }

    const dispatchResetDoneEvent = () => {
        for (let idx = 0; idx < resetDoneEventListeners.length; idx++) {
            let nextCallback = resetDoneEventListeners[idx];
            if (typeof nextCallback === "function") {
                nextCallback();
            }
        }
    }

    const resetFilters = () => {

        resetPaging();
        setProducts([]);
        dispatchResetDoneEvent();
    };

    const updatePaging = (data) => {

        //console.log("Updating paging on PARTS", data);
        if (!Utilities.isValidObj(data)) {
            //Logger.log("Invalid data passed into updatePaging");
            return;
        }

        setCurrentPage(data.currentPage);
        setTotalNumOfItems(data.totalNumOfItems);
    };

    const updateProducts = (data) => {

        if (!Utilities.isValidObj(data)) {
            //Logger.log("Invalid data passed into updateProducts");
            return;
        }
        setProducts(data);
    };

    const updateHints = (data) => {

        if (!Utilities.isValidObj(data)) {
            //Logger.log("Invalid data passed into updateHints");
            return;
        }

        if (Array.isArray(data) && data.length > 1)
            setHints(data);
        else
            setHints([]);
    };


    const fetchData = async () => {

        if (isFetching)
            throw new FetchInProgressError();

        Utilities.checkJwtToken(accessToken);

        //Logger.startFunc("  partNumberSearchResultsContext.fetchData");

        isFetching = true;
        setIsLoading(true);

        try {

            var requestDto = new SearchDto();

            requestDto.paging.currentPage = currentPage;
            requestDto.paging.pageSize = numOfItemsPerPage;

            requestDto.keyword = keyword;

            setupAbortController();

            let responseDto = await ApiService.partSearch(accessToken, requestDto, abortController);
            //Logger.logObj(responseDto);

            if (abortController.signal.aborted)
                throw new FetchAbortedError();

            if (responseDto.status !== 0)
                setErrorMessage(responseDto.message);
            else {

                setKeyword(responseDto.keyword);
                updateHints(responseDto.hints);

                updatePaging(responseDto.paging);

                updateProducts(responseDto.parts);
            }
            
            //Logger.log("  partNumberSearchResultsContext.fetchData.resolved");
        }
        catch (err) {
            
            //if (err.name === "AbortError") {
                //Logger.log("    partNumberSearchResultsContext.fetchData.rejected - ABORTED");
            //}

            //Logger.handledException(err);
            //Logger.log("    partNumberSearchResultsContext.fetchData.rejected");
            throw err;
        }
        finally {
            setOnSearchTrigger(false);

            isFetching = false;
            setIsLoading(false);
            //Logger.endFunc("  partNumberSearchResultsContext.fetchData.finally");
        }
    };


    const fetchDataSync = () => {
        (async () => {
            //Logger.startFunc("partNumberSearchResultsContext.fetchDataSync");

            try {
                await fetchData();

                //Logger.log("  partNumberSearchResultsContext.fetchDataSync.resolved");
            }
            catch (err) {

                //if (err.name === "AbortError") {
                    //Logger.log("  partNumberSearchResultsContext.fetchDataSync.rejected - ABORTED");
                //} else {
                    //Logger.handledException(err);
                //}
            }
            finally {
                //Logger.endFunc("partNumberSearchResultsContext.fetchDataSync.finally");
            }
        })();
    };


    const abortFetch = () => {
        if (isFetching && abortController !== null && typeof abortController !== "undefined" && !abortController.signal.aborted) {
            //Logger.log("  *** aborting the fetching of data from the api ***");
            abortController.abort();
        }
        isFetching = false;
    }

    const unmountedCleanup = () => {
        //Logger.startFunc("partNumberSearchResultsContext.unmountedCleanup");

        abortFetch();

        removeAllResetDoneEventListeners();
        //Logger.endFunc("partNumberSearchResultsContext.unmountedCleanup");
    };


    // IMPORTANT:  The ordering of useEffect is the order it will be called!
    useEffect(() => {

        return () => {
            unmountedCleanup();
        };
    }, []);


    useEffect(() => {

        //Logger.startFunc("partNumberSearchResultsContext.useEffect.filters");

        if (isInitialized && !isLoading && !isFetching){
            //console.log("  something triggered this effect for PARTS: ", {
            //    currentPage,
            //    numOfItemsPerPage,
            //    onSearchTrigger
            //});

            fetchDataSync();
        }

        //Logger.endFunc("partNumberSearchResultsContext.useEffect.filters");

        return () => {
            abortFetch();
        }
    }, [
        currentPage,
        numOfItemsPerPage,
        onSearchTrigger             // SPECIAL CASE        
    ]);


    return (
        <PartNumberSearchResultsContext.Provider 
            value={
                {   // corresponds to our own properties and functions within this object
                    addResetDoneEventListener,
                    removeResetDoneEventListener,
                    resetFilters,
                    products,
                    setProducts,
                    selectedProduct,
                    setSelectedProduct,
                    isLoading,
                    setIsLoading,
                    setPrimaryMessage,
                    setSuccessMessage,
                    setInfoMessage,
                    setWarningMessage,
                    setErrorMessage,
                    clearMessage,
                    numOfPages,
                    currentPage,
                    totalNumOfItems,
                    numOfItemsPerPage,
                    setCurrentPage,
                    setTotalNumOfItems,
                    setNumOfItemsPerPage,
                    onSearchTrigger,
                    setOnSearchTrigger,
                    keyword,
                    setKeyword,
                    hints,
                    onHintSelected
                }
            }
        >
            {children}
        </PartNumberSearchResultsContext.Provider>
    );
};

export { PartNumberSearchResultsContext, PartNumberSearchResultsProvider, usePartNumberSearchResultsConsumer };
