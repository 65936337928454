import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import Utilities from '../../utilities/utilities'

import styles from '../../styles/icons/genericIcon';

const GenericIcon = (props) => {

    return (
        <FontAwesomeIcon style={[props.style, styles.icon]} icon={props.iconName} color={Utilities.isValidObj(props.color) ? props.color : "black"}/>
    );
}

export default GenericIcon;