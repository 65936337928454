import React, { useState, useEffect } from 'react';
import { Image, Text, TouchableOpacity, View, Dimensions, SafeAreaView, ScrollView } from 'react-native';
import Header from '../controls/header';
import Badge from '../controls/badges/badge';
import ScreenEnum from '../enums/screenEnum';

import styles from '../styles/screens/landing';
 
const PostLoginLanding = ({ navigation, route }) => {
    const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);

    useEffect(() => {
      const updateScreenWidth = () => {
        setScreenWidth(Dimensions.get('window').width);
      };
  
      Dimensions.addEventListener('change', updateScreenWidth);
  
      return () => {
        Dimensions.removeEventListener('change', updateScreenWidth);
      };
    }, []);

    const sendToProductLandingPage = () => {
        navigation.navigate(ScreenEnum.ProductSelectorLanding.key, {name: ScreenEnum.ProductSelectorLanding.key});
    };

    
    const sendToPartsLandingPage = () => {
        navigation.navigate(ScreenEnum.PartNumberLanding.key, {name: ScreenEnum.PartNumberLanding.key});
    };


    const onProductSelector = () => {
        sendToProductLandingPage();
    };


    const onPartNumbers = () => {
        sendToPartsLandingPage();
    };


    // TODO:  why isn't the main header showing?
    return (
        <SafeAreaView style={styles.safeArea}>
            <Header />
            <Badge />
            <ScrollView style={styles.scrollContainer}>
                <View style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    maxWidth: "100%",
                    width: 1000
                }}>
                    <Text style={{
                            fontSize: 22,
                            fontWeight: "500",
                            textAlign: "center",
                            width: "100%",
                        }}
                    >
                        {"Welcome to Liquid Controls Pricing Catalog"}
                    </Text>
                    <Text style={{
                        fontSize: 18,
                        fontWeight: "500",
                        textAlign: "center",
                        width: "100%",
                    }}
                    >
                        {"Prices Effective 1/1/2024 - 12/31/2024"}
                    </Text>
                    <Text style={{
                        fontSize: 12,
                        fontWeight: "500",
                        textAlign: "center",
                        width: "100%",
                    }}
                    >
                        {"*Pricing subject to change without notice"}
                    </Text>
                    <View style={{
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Image
                            source={require('../assets/meters.png')}
                            style={{
                                width: '100%',
                                aspectRatio: 3.85,  // 4000x1038
                            }}
                        />
                    </View>
                    <View style={{
                        flex: 1,
                        flexDirection: screenWidth <= 575 ? 'column' : 'row',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        maxHeight: 725,
                    }}>
                        <View style={ styles.productBox }>
                            <TouchableOpacity
                                onPress={onProductSelector}
                                style={{
                                    flex: 1,
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Image
                                    source={require('../assets/productSelector.png')}
                                    style={{
                                        width: '100%',
                                        aspectRatio: 1024 / 723,
                                    }}
                                />
                                <Text style={{
                                    fontSize: 22,
                                    fontWeight: "500",
                                    textAlign: "center",
                                    width: "100%",
                                }}>
                                    {"Meter Selector"}
                                </Text>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.productBox}>
                            <TouchableOpacity
                                onPress={onPartNumbers}
                                style={{
                                    flex: 1,
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Image
                                    source={require('../assets/partNumber.png')}
                                    style={{
                                        width: '100%',
                                        aspectRatio: 1024 / 723,
                                    }}
                                />
                                <Text style={{
                                    fontSize: 22,
                                    fontWeight: "500",
                                    marginBottom: 15,
                                    textAlign: "center",
                                    width: "100%",
                                }}>
                                    {"Parts and Accessories"}
                                </Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>
    );
}

export default PostLoginLanding;