import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    paginationControlWrapper: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    currentPageButton: {
        marginTop: -5,
    },
});