import AsyncStorage from '@react-native-async-storage/async-storage';
import Utilities from './utilities';
import { JsonConvertDeserializationError } from '../exceptions/jsonConvertExceptions';
import { InvalidArgumentError } from '../exceptions/generalExceptions';

const PrivateLocalStorage = {
    checkKey: (key) => {
        if (!Utilities.isValidObj(key))
            throw new InvalidArgumentError("key is not defined.")
    },
    clear: async (key) => {
        PrivateLocalStorage.checkKey(key);

        return await AsyncStorage.removeItem(key);
    },
    get: async (key) => {
        PrivateLocalStorage.checkKey(key);

        return await AsyncStorage.getItem(key);
    },
    set: async (key, value) => {
        PrivateLocalStorage.checkKey(key);

        return await AsyncStorage.setItem(key, value);
    }
};

const LocalStorage = {
    clear: async (key) => {
        return await PrivateLocalStorage.clear(key);
    },
    getJson: async (key) => {

        let obj = null;

        try {
            let json = await PrivateLocalStorage.get(key);
            obj = JSON.parse(json)
        }
        catch (err) {
        }

        if (!Utilities.isValidObj(obj))
            throw new JsonConvertDeserializationError("Failed to convert string to JSON. Key: " + key);

        return obj;
    },
    setJson: async (key, object) => {
        return await PrivateLocalStorage.set(key, JSON.stringify(object));
    },
 };

export default LocalStorage;