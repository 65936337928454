import React, { useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import Utils from '../../utilities/utilities';
import SearchInput from '../../controls/textboxes/searchInput';
import RNPickerSelect from 'react-native-picker-select';  // https://github.com/lawnstarter/react-native-picker-select
import RNPickerSelectItem from '../../controls/pickers/rnPickerSelectItem';
import PrimaryButton from '../../controls/buttons/primaryButton';
import SecondaryButton from '../../controls/buttons/secondaryButton';
import ScreenEnum from '../../enums/screenEnum';
import IconEnum from '../../enums/iconEnum';

import { useProductSelectorSearchResultsConsumer } from '../../contexts/productSelectorSearchResultsContext';

import styles from '../../styles/screens/components/requiredFilters';
import pickerStyles from '../../styles/pickers/selectSingle';
import Logger from '../../utilities/logger';

const RequiredFilters = (props) => {

    if (!Utils.isValidObj(props))
        return <></>;

    const { 
        resetFilters,
        allFluids,
        allFlowRates,
        allAccessoryPackages,
        allRegistrations,
        availableFluids,
        availableFlowRates,
        availableAccessoryPackages,
        availableRegistrations,
        onFluidChanged,
        onFlowRateChanged,
        onAccessoryPackageChanged,
        onRegistrationChanged,
        setOnSearchTrigger,
        keyword,
        setKeyword,
        hints,
        onHintSelected,
    } = useProductSelectorSearchResultsConsumer();

    const navigation = props.navigation;

    const [allFluidsForPicker, setAllFluidsForPicker] = useState([]);
    const [allFlowRatesForPicker, setAllFlowRatesForPicker] = useState([]);
    const [allAccessoryPackagesForPicker, setAllAccessoryPackagesForPicker] = useState([]);
    const [allRegistrationsForPicker, setAllRegistrationsForPicker] = useState([]);

    const [availableFluidsForPicker, setAvailableFluidsForPicker] = useState([]);
    const [availableFlowRatesForPicker, setAvailableFlowRatesForPicker] = useState([]);
    const [availableAccessoryPackagesForPicker, setAvailableAccessoryPackagesForPicker] = useState([]);
    const [availableRegistrationsForPicker, setAvailableRegistrationsForPicker] = useState([]);

    const [selectedFluidForPicker, setSelectedFluidForPicker] = useState(-1);
    const [selectedFlowRateForPicker, setSelectedFlowRateForPicker] = useState(-1);
    const [selectedAccessoryPackageForPicker, setSelectedAccessoryPackageForPicker] = useState(-1);
    const [selectedRegistrationForPicker, setSelectedRegistrationForPicker] = useState(-1);

    const convertToSelectItems = (fromArray) => {

        let toArray = [];

        for (let i = 0; i < fromArray.length; i++) {
            var item = fromArray[i];
            if (Utils.isValidObj(item))
                toArray.push(new RNPickerSelectItem(item.value, item.name));
        }

        return toArray;
    };

    const getAvailableSelectItems = (allItems, availableKeys) => {

        var isNotFound = (id, arr) => {
            for (let z = 0; z < arr.length; z++) {
                if (arr[z].id === id)
                    return false;
            }

            return true;
        };

        let items = [];

        if (Utils.isValidObj(availableKeys) && Array.isArray(availableKeys)) {

            for (let n = 0; n < availableKeys.length; n++) {
                let val = parseInt(availableKeys[n]);
                
                for (let i = 0; i < allItems.length; i++) {
                    var item = allItems[i];
                    if (Utils.isValidObj(item) && parseInt(item.value) === val && isNotFound(item.value, items)) {
                        items.push(item);
                        break;
                    }
                }
            }
        }

        let sorted = items.sort(Utils.naturalSortRNPickerItem);        

        //sorted.unshift(new RNPickerSelectItem(-1, 'Please choose...'));

        return sorted;
    };


    useEffect(() => {

        setAllFluidsForPicker(convertToSelectItems(allFluids));

    }, [allFluids]);

    useEffect(() => {

        setAllFlowRatesForPicker(convertToSelectItems(allFlowRates));

    }, [allFlowRates]);

    useEffect(() => {

        setAllAccessoryPackagesForPicker(convertToSelectItems(allAccessoryPackages));

    }, [allAccessoryPackages]);

    useEffect(() => {

        setAllRegistrationsForPicker(convertToSelectItems(allRegistrations));
    }, [allRegistrations]);


    useEffect(() => {

        setAvailableFluidsForPicker(getAvailableSelectItems(allFluidsForPicker, availableFluids));

        if (allFluidsForPicker.length === availableFluids.length)
            setSelectedFluidForPicker(-1);
    }, [availableFluids]);

    useEffect(() => {

        setAvailableFlowRatesForPicker(getAvailableSelectItems(allFlowRatesForPicker, availableFlowRates));

        if (allFlowRatesForPicker.length === availableFlowRates.length)
            setSelectedFlowRateForPicker(-1);
    }, [availableFlowRates]);


    useEffect(() => {

        setAvailableAccessoryPackagesForPicker(getAvailableSelectItems(allAccessoryPackagesForPicker, availableAccessoryPackages));

        if (allAccessoryPackagesForPicker.length === availableAccessoryPackages.length)
            setSelectedAccessoryPackageForPicker(-1);
    }, [availableAccessoryPackages]);


    useEffect(() => {

        setAvailableRegistrationsForPicker(getAvailableSelectItems(allRegistrationsForPicker, availableRegistrations));

        if (allRegistrationsForPicker.length === availableRegistrations.length)
            setSelectedRegistrationForPicker(-1);
    }, [availableRegistrations]);


    const onFluidChangedForPicker = ((item) => {

        onFluidChanged(item);

        (async () => {
            setSelectedFluidForPicker(item);
        })();
    });

    const onFlowRateChangedForPicker = ((item) => { 

        onFlowRateChanged(item);

        (async () => {
            setSelectedFlowRateForPicker(item);
        })();
    });

    const onAccessoryPackageChangedForPicker = ((item) => {

        onAccessoryPackageChanged(item);

        (async () => {
            setSelectedAccessoryPackageForPicker(item);
        })();
    });

    const onRegistrationChangedForPicker = ((item) => {

        onRegistrationChanged(item);

        (async () => {
            setSelectedRegistrationForPicker(item);
        })();
    });


    const onSearch = () => {
        navigation.navigate(ScreenEnum.ProductSelectorSearch.key);
    };


    return (
        <View style={styles.requiredFiltersWrap}>
            <View style={[styles.requiredSearchWrap, styles.requiredFiltersFilter]}>
                <SearchInput
                    style={styles.requiredFiltersSearchInput}
                    keyword={keyword}
                    setKeyword={setKeyword}
                    hints={hints}
                    onHintSelected={onHintSelected}
                    onSearchBtn={() => { setOnSearchTrigger(true); }}
                />
            </View>
            { Utils.isValidObj(availableFluidsForPicker) && 
                <View style={styles.requiredFiltersFilter}>
                <Text style={styles.requiredFiltersLabel}>
                        {"Fluid"}
                    </Text>
                    <RNPickerSelect
                        style={pickerStyles}
                        items={availableFluidsForPicker}
                        placeholder={new RNPickerSelectItem(-1, 'Please choose...')}
                        value={selectedFluidForPicker}
                        onValueChange={onFluidChangedForPicker}
                    />
                </View>
            }
            { Utils.isValidObj(availableFlowRatesForPicker) && 
                <View style={styles.requiredFiltersFilter}>
                    <Text style={styles.requiredFiltersLabel}>
                        {"Flow Rate"}
                    </Text>
                    <RNPickerSelect
                        style={pickerStyles}
                        items={availableFlowRatesForPicker}
                        placeholder={new RNPickerSelectItem(-1, 'Please choose...')}
                        value={selectedFlowRateForPicker}
                        onValueChange={onFlowRateChangedForPicker}
                    />
                </View>
            }
            { Utils.isValidObj(availableAccessoryPackagesForPicker) && 
                <View style={styles.requiredFiltersFilter}>
                    <Text style={styles.requiredFiltersLabel}>
                        {"Accessory Package"}
                    </Text>
                    <RNPickerSelect
                        style={pickerStyles}
                        items={availableAccessoryPackagesForPicker}
                        placeholder={new RNPickerSelectItem(-1, 'Please choose...')}
                        value={selectedAccessoryPackageForPicker}
                        onValueChange={onAccessoryPackageChangedForPicker}
                    />
                </View>
            }
            { Utils.isValidObj(availableRegistrationsForPicker) && 
                <View style={styles.requiredFiltersFilter}>
                    <Text style={styles.requiredFiltersLabel}>
                        {"Registration"}
                    </Text>
                    <RNPickerSelect
                        style={pickerStyles}
                        items={availableRegistrationsForPicker}
                        placeholder={new RNPickerSelectItem(-1, 'Please choose...')}
                        value={selectedRegistrationForPicker}
                        onValueChange={onRegistrationChangedForPicker}
                    />
                </View>
            }
            <View style={styles.buttonGroup}>
                <PrimaryButton onPress={onSearch} title={"Search"} icon={{ name: IconEnum.Search.name, position: "left" }} />
                <SecondaryButton color={"white"} onPress={resetFilters} title={"Reset"} icon={{ name: IconEnum.RemoveFilters.name, position: "left" }} />
            </View>
        </View>
    );
}

export default RequiredFilters;