import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    field: {
        //alignContext: "flex-start",  // default
        flex: 1,
        flexDirection: "row",  // horizontal
        flexWrap: "nowrap",
        minHeight: 30,
        //justifyContent: "flex-start",  // default
    },
    disabled: {
        opacity: "0.5",
    },
    enabled: {
        color: "#000000",
    },
    icon: {
        flexBasis: "auto",
        marginRight: "3",
    },
    label: {
        flexGrow: 1,
        width: "auto",
        marginLeft: 3,
        textDecorationLine: "none",
    },
});