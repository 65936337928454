import React from 'react';
import { Text } from 'react-native';
import { useBadgeConsumer } from '../../contexts/badgeContext';
import BadgeType from '../../enums/badgeTypeEnum';
import Utilities from '../../utilities/utilities';

import styles from '../../styles/badges/badge';

const Badge = () => {

    const {
        message,
        badgeType,
    } = useBadgeConsumer();

    return (
        <>
            {Utilities.isValidObj(message) && message !== "" &&
                <Text
                    style={[styles.badge, badgeType.style]}
                >
                    {message}
                </Text>
            }
        </>
    );
}

export default Badge;