import Globals from '../utilities/globals';

class Paging {

    currentPage = 1;
    pageSize = Globals.pageSize;

    constructor(currentPage, pageSize) {

        this.currentPage = currentPage;
        this.pageSize = pageSize;
    }
};

export default Paging;