import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    input: {
        backgroundColor: "white", 
        borderColor: "gray",
        borderRadius: 5,
        borderWidth: 1,
        color: "black",
        height: 40,
        marginBottom: 10,
        paddingRight: 10,
        paddingLeft: 10,
    },
    label: {
        marginBottom: 3
    },
    placeholder: {
        color: "lightgray",
    },
});