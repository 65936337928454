import { AutoFocus } from 'expo-camera';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    safeArea: {
        height: "100%",
    },
    scrollContainer: {
        backgroundColor: '#f0f0f0',
    },
    container: {
        backgroundColor: '#f0f0f0',
        padding: 20,
    },
    inputs: {
        borderWidth: 1,
        borderColor: 'gray',
        backgroundColor: 'white',
        height: 40,
        paddingRight: 10,
        paddingLeft: 10,
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2
    },
    buttonOpen: {
        backgroundColor: "#F194FF",
    },
    buttonClose: {
        backgroundColor: "#2196F3",
    },
    textStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center"
    },
    marginContainer: {
        margin: 10,     
    },
    paddingContainer: {
        padding: 10,
    },
    marginBottom8: {
        marginBottom: 8,
    }
});