class SearchOption {

    label = "";

    value = "";

    constructor(
        label, 
        value
    ) {

        this.label = label;
        this.value = value;
    }
};

export default SearchOption;