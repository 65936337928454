import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    bannerContainer: {
        //backgroundColor: '#000',
        //flex: 1,
        //flexDirection: "row",
        //justifyContent: 'space-between',
        //maxHeight: 80,
        //paddingHorizontal: 10,
        //width: "100%",
        //height: "100%",
    },
    banner: {
        resizeMode: "contain",
        //aspectRatio: 3.85,  // 4000x1038
        //width: "231",
        //width: "100%",
        //height: "100%",
        //height: "60",
        //width: "auto",
        //objectFit: "fill",
        //display: "inline-block",
        //width: "100%",
        //height: "100%",
        //objectFit: "fill",
        //flex: 1,
        //width: 188,
        //height: 60,
        //position: "relative",
        //top: 0,
        //left: 0,
        //height: 60,
        //minHeight: 60,
        //maxHeight: 60,
    },
});