class LoginDto {
    username = "";
    password = "";
    rememberMe = false;

    constructor(u, p, rememberMe) {
        this.username = u;
        this.password = p;
        this.rememberMe = rememberMe;
    }

    equals = function (dto) {
        return (
            dto !== null && typeof dto !== "undefined" &&
            this.username === dto.username &&
            this.password === dto.password &&
            this.rememberMe === dto.rememberMe
        );
    }
};

export default LoginDto;